import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Task.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {data} from '../../../../evn/evn'
import { Button, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Task = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [cases, setCases] = useState([]);
  const [selectedCaseTitle, setSelectedCaseTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const ip = data.evn
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${ip}/dashboard/alert/teammembers`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setTeamMembers(response.data.map((member) => member.name));
      } catch (error) {
        toast.error('Error fetching team members');
      }
    };

    const fetchCases = async () => {
      try {
        const response = await axios.get(`${ip}/caseform`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setCases(response.data);
      } catch (error) {
        toast.error('Error fetching cases');
      }
    };

    fetchTeamMembers();
    fetchCases();
  }, []);

  const initialValues = {
    title: '',
    caseTitle: '',
    caseType: '', // Remove the initial value here
    startDate: '',
    completionDate: '',
    assignFrom: '',
    assignTo: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    caseTitle: Yup.string().required('Case Title is required'),
    caseType: Yup.string(),
    startDate: Yup.date(),
  completionDate: Yup.date()
    .min(
      Yup.ref('startDate'),
      "Completion date can't be before the start date"
    ),
    assignFrom: Yup.string(),
    assignTo: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        const response = await axios.post(`${ip}/alerts`, values, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        toast.success('Tasks added successfully!');
        resetForm();
        setTitleError(''); // Clear any existing title error
      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.error) {
          // Set the title error if it exists
          if (error.response.data.error.includes('title')) {
            setTitleError(error.response.data.error);
          } else {
            toast.error('Error submitting form');
          }
        } 
      }
    },
  });

  // Modify the handleCaseTitleChange function
  const handleCaseTitleChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedCase = cases.find((caseItem) => caseItem.id === selectedId);
    if (selectedCase) {
      setSelectedCaseTitle(selectedCase.title); // Update selectedCaseTitle
      formik.setFieldValue('caseType', selectedCase.type_name);
    } else {
      setSelectedCaseTitle(''); // Clear the selectedCaseTitle if no case is selected
      formik.setFieldValue('caseType', ''); // Clear the field if no case is selected
    }
    // Update the formik field value for caseTitle
    formik.setFieldValue('caseTitle', event.target.value);
  };

  const navigate = useNavigate();
  const HandleCancel=()=>{
    navigate(-1)
  }

  return (
    <>
    <div className={styles.formContainer}>
    <h2 className={styles.heading}>ADD TASK</h2>
      <form onSubmit={formik.handleSubmit}>
      <ToastContainer />
        <div className={styles.formField}>
          <label className={styles.label} htmlFor="title">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className={styles.inputField}
            {...formik.getFieldProps('title')}
          />
          {formik.touched.title && formik.errors.title && (
            <div className={styles.error}>{formik.errors.title}</div>
          )}
          {titleError && <div className={styles.error}>{titleError}</div>} {/* Display the title error */}
        </div>
          <div className={styles.horizontalFields}>
            <div className={styles.statusPriorityFields}>
            <div className={styles.formField}>
                <label className={styles.label} htmlFor="caseTitle">Case Title</label>
                <select
                  id="caseTitle"
                  name="caseTitle"
                  className={styles.selectField}
                  onChange={handleCaseTitleChange}
                  value={formik.values.caseTitle}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select a case Title</option>
                  {cases.map((caseItem) => (
                    <option key={caseItem.id} value={caseItem.id}>
                      {caseItem.title}
                    </option>
                  ))}
                </select>
                {formik.touched.caseTitle && formik.errors.caseTitle && (
                  <div className={styles.error}>{formik.errors.caseTitle}</div>
                )}
              </div>
              <div className={styles.formField}>
                <label className={styles.label} htmlFor="caseType">Case Type</label>
                <input
                  id="caseType"
                  name="caseType"
                  className={styles.inputField}
                  value={formik.values.caseType} // Use formik's field value here
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.caseType && formik.errors.caseType && (
                  <div className={styles.error}>{formik.errors.caseType}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.horizontalFields}>
            <div className={styles.statusPriorityFields}>
              <div className={styles.formField}>
                <label className={styles.label} htmlFor="startDate">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className={styles.date}
                  {...formik.getFieldProps('startDate')}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className={styles.error}>{formik.errors.startDate}</div>
                )}
              </div>
              <div className={styles.formField}>
                <label className={styles.label} htmlFor="completionDate">
                  Completion Date
                </label>
                <input
                  type="date"
                  id="completionDate"
                  name="completionDate"
                  className={styles.cdate}
                  {...formik.getFieldProps('completionDate')}
                />
                {formik.touched.completionDate && formik.errors.completionDate && (
                  <div className={styles.error}>{formik.errors.completionDate}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.horizontalFields}>
            <div className={styles.statusPriorityFields}>
              <div className={styles.formField}>
                <label className={styles.label} htmlFor="assignFrom">
                  Assign From
                </label>
                <select
                  id="assignFrom"
                  name="assignFrom"
                  className={styles.selectField}
                  {...formik.getFieldProps('assignFrom')}
                >
                  <option value="">Select an option</option>
                  {teamMembers.map((fullName) => (
                    <option key={fullName} value={fullName}>
                      {fullName}
                    </option>
                  ))}
                </select>
                {formik.touched.assignFrom && formik.errors.assignFrom && (
                  <div className={styles.error}>{formik.errors.assignFrom}</div>
                )}
              </div>
              <div className={styles.formField}>
                <label className={styles.label} htmlFor="assignTo">
                  Assign To
                </label>
                <select
                  id="assignTo"
                  name="assignTo"
                  className={styles.selectField}
                  {...formik.getFieldProps('assignTo')}
                >
                  <option value="">Select an option</option>
                  {teamMembers.map((fullName) => (
                    <option key={fullName} value={fullName}>
                      {fullName}
                    </option>
                  ))}
                </select>
                {formik.touched.assignTo && formik.errors.assignTo && (
                  <div className={styles.error}>{formik.errors.assignTo}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.BtnContainer}>
          <Tooltip title="Submit Case" disableInteractive>
            <button type="submit" className={styles.submitButton}>Submit</button>
            {/* <ToastContainer /> */}

          </Tooltip>

          <Tooltip title="Cancel Case" disableInteractive>
            <button className={styles.buttonCancel} onClick={HandleCancel} >cancel</button>
          </Tooltip>
          </div>
          <ToastContainer/>
        </form>
      </div>
    </>
  );
};

export default Task;
