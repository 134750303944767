import { FaCrown } from "react-icons/fa";

export const benfit = [
  {
    id: 1,
    icon: <FaCrown />,
    title: "Benefit 1",
    info: "Rapid Legal Document Generation: Our AI capabilities enable us to produce legal documents faster than ever, without sacrificing quality.",
    path: "/benfits/111",
  },
  {
    id: 2,
    icon: <FaCrown />,
    title: "Benefit 2",
    info: "Unmatched Precision and Compliance: We ensure that every document is accurate, compliant, and customized to the specific legal requirements.",
    path: "/benfits/222",
  },
  {
    id: 3,
    icon: <FaCrown />,
    title: "Benefit 3",
    info: "Tailored Legal Solutions: Our AI algorithms are adept at crafting solutions uniquely suited to each client's situation.",
    path: "/benfits/333",
  },
  {
    id: 4,
    icon: <FaCrown />,
    title: "Benefit 4",
    info: "Significant Cost Reductions: Efficiency gains from AI translate into direct cost savings for our clients.",
    path: "/benfits/444",
  },
  {
    id: 5,
    icon: <FaCrown />,
    title: "Benefit 5",
    info: "Convenient Access to Services: Our online tools and platforms ensure that clients can access our services anytime, anywhere.",
    path: "/benfits/555",
  },
  {
    id: 6,
    icon: <FaCrown />,
    title: "Benefit 6",
    info: "Forward-Looking Legal Insights: We provide proactive insights to help clients navigate potential legal challenges.",
    path: "/benfits/666",
  },
  {
    id: 7,
    icon: <FaCrown />,
    title: "Benefit 7",
    info: "An Enhanced Experience: Our streamlined processes and quick service delivery ensure a superior client experience.",
    path: "/benfits/777",
  },
  {
    id: 8,
    icon: <FaCrown />,
    title: "Benefit 8",
    info: "Easily Scalable Services: As our clients' needs grow, our services can scale accordingly, thanks to our adaptable AI technology.",
    path: "/benfits/888",
  },
  {
    id: 9,
    icon: <FaCrown />,
    title: "Benefit 9",
    info: "Informed Decision-Making: We empower clients with data-driven insights for smarter legal decisions.",
    path: "/benfits/999",
  },
  {
    id: 10,
    icon: <FaCrown />,
    title: "Benefit 10",
    info: "Worldwide Legal Support: Our global reach means we can support clients across different jurisdictions.",
    path: "/benfits/101010",
  },
  {
    id: 11,
    icon: <FaCrown />,
    title: "Benefit 11",
    info: "Assured Data Protection: We maintain the highest standards of data security, keeping client information safe.",
    path: "/benfits/111111",
  },
  {
    id: 12,
    icon: <FaCrown />,
    title: "Benefit 12",
    info: "Ongoing Improvement and Innovation: Our commitment to continuous learning with AI means our services only get better.",
    path: "/benfits/121212",
  },
];