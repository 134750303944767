import React, { useState, useEffect } from "react";
import style from './subscriptionoptions.module.css';
import {data} from '../../../evn/evn'
import { NavLink } from "react-router-dom";

const SubscriptionOptions = ({closeModal}) => {
    const prices = {
        monthly: 499,
        annual: 3999    
    };
    const [selectedOption, setSelectedOption] = useState('monthly');
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [couponApplied, setCouponApplied] = useState(false);
    const [showCouponInput, setShowCouponInput] = useState(false);
    const ip = data.evn
    const gstRate = 0.18;
    const gstTax = 18
    const discountRate = 0.25; // 25% discount


    const calculateTax = (price) => {
        return price * gstTax; // Calculate the tax based on the given price
    };

    // Function to calculate total price
    const calculateTotal = () => {
        let price = prices[selectedOption];
        if (couponApplied) {
            price -= price * discountRate;
        }
        const taxAmount = price * gstRate; // Calculating GST on the discounted price
        const totalPrice = price + taxAmount; // Total price including GST
        return Math.round(totalPrice * 100); 
     };

    // Load Razorpay script
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    // Handler for Razorpay payment
    const handleRazorpayPayment = async () => {
        const token = localStorage.getItem('token');
    
        if (!token) {
            alert("Please log in to complete your subscription payment.");
            return; // Exit the function if the user is not logged in
        }


        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
    
        const amountInPaise = calculateTotal(); // Calculate the total price based on the selected option and any applied discounts
    
        const options = {
            key: 'rzp_test_SJsbPFYVQOtlbi', // Your Razorpay Key
            currency: "INR",
            amount: amountInPaise.toString(), // Razorpay expects the amount in paise and as a string
            name: "LAWFAX",
            description: "Payment for Premium Subscription",
            handler: async function (response) {
                const paymentId = response.razorpay_payment_id;
                alert(`Payment Successful\nPayment ID: ${paymentId}`);
    
                // Assuming you store the JWT token in localStorage
                const token = localStorage.getItem('token');
                if (!token) {
                    alert("You must be logged in to subscribe");
                    return;
                }
    
                try {
                    // Update subscription status in your backend
                    const subscriptionResponse = await fetch(`${ip}/api/subscribe`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': localStorage.getItem('token'),
                        },
                        body: JSON.stringify({
                            subscriptionType: selectedOption, // 'monthly' or 'annual'
                        }),
                    });
    
                    if (!subscriptionResponse.ok) {
                        throw new Error('Subscription update failed');
                    }
    
                    const subscriptionData = await subscriptionResponse.json();
                    
                } catch (error) {
                    console.error('Error updating subscription:', error);
                    alert('Failed to update subscription. Please contact support.');
                }
            },
            prefill: {
                name: "Your Name", // These details can be dynamically filled if you have them
                email: "Your Email",
                contact: "mobile no.",
            },
            theme: {
                color: '#924a4a'
            }
        };
    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    

    const handleApplyCoupon = () => {
        if (coupon === "DISCOUNT25" && !couponApplied) {
            setDiscount(discountRate);
            setCouponApplied(true);
            setShowCouponInput(false);
        } else {
            alert("Invalid coupon code!");
        }
    };

    // Effect to recalculate total whenever the selected option or coupon application changes
    useEffect(() => {
        calculateTotal();
    }, [selectedOption, couponApplied, discount]);

    return (
        <div className={style.modalBackdrop}>
        <div className={style.subscriptionOptionsContainer}>
        <button className={style.closeButton} onClick={closeModal} aria-label="Close">
            ×
        </button>
            <h2 className={style.Heading}>Premium Subscription</h2>
            <p className={style.SubHeading}>Choose a billing period and finish the purchase process</p>
            <label className={style.Label}>
                <input
                    type="radio"
                    name="subscription"
                    value="monthly"
                    checked={selectedOption === 'monthly'}
                    onChange={() => setSelectedOption('monthly')}
                />
                1 Month
                <p className={style.Discount}> Save 20%</p>
                <span className={style.BeforeDiscount}> ₹599</span>
                 <div className={style.Price}> ₹{prices.monthly}</div>
            </label>

            <label className={style.Label} >
                <input
                    type="radio"
                    name="subscription"
                    value="annual"
                    checked={selectedOption === 'annual'}
                    onChange={() => setSelectedOption('annual')}
                />
                12 Months <p className={style.Discount}>Save 20%</p>
                <span className={style.BeforeDiscount}>₹4999</span>
                <div className={style.Price}> ₹{prices.annual} </div>
            </label>

            <div className={style.TotalContainer}>
                <div className={style.totalCost}>
                    <p>Tax  ₹{(calculateTax(prices[selectedOption] - (prices[selectedOption] * discount)) / 100).toFixed(2)}</p>       
                    <p>Total  ₹{(calculateTotal() / 100).toFixed(2)}</p>
                </div>
                <div className={style.couponSection}>
                    <div className={style.couponHeadingContainer}>
                        <h4>Add Coupon</h4>
                        {!couponApplied && (
                            <a onClick={() => setShowCouponInput(prev => !prev)} className={style.addCouponLink}>
                                {showCouponInput ? 'Cancel' : 'Add'}
                            </a>
                        )}
                    </div>

                {showCouponInput && (
                    <div className={style.couponInputContainer}>
                        <input
                            type="text"
                            placeholder="Enter coupon code"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                            className={style.couponInput}
                        />
                        <button onClick={handleApplyCoupon} className={style.applyButton}>Apply</button>
                    </div>
                )}

                {couponApplied && <p className={style.discountApplied}>Discount applied!</p>}
            </div>
            <p className={style.Tearms}>By checking out, you agree with our <NavLink className={style.TearmConditions} to='/termandcondition' > Terms of Service </NavLink> and confirm that you have read our Privacy Policy. You can cancel recurring payments at any time.</p>
        </div>

            <button onClick={handleRazorpayPayment} className={style.paymentButton}>
                Complete Payment
            </button>
        </div>
        </div>
    );
};

export default SubscriptionOptions;
