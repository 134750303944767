import React, { useEffect, useState } from "react";
import style from "./ShowUpdateCase.module.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar'
import Modal from "../Client/People/ModelPop/Modal";
import TaskForm from "../Client/People/ModelPop/TaskForm";
import EditCaseForm from "./EditUpdateCaseForm/EditUpdateCaseForm";
import CaseHistory from "../AddCase/CaseHistory/CaseHistory";
import {data} from '../../../evn/evn'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Pagination, Tooltip } from "@mui/material";
import SideNav from "../../utilities/SideNavBar/SideNav";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';





const ShowUpdateCase = () => {
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [casesData, setCasesData] = useState([]);
  const [editingCase, setEditingCase] = useState(null);
  const [downloadingCaseId, setDownloadingCaseId] = useState(null);
  const [totalCases, setTotalCases] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [currentPage, setCurrentPage] = useState(1);
  const [casesPerPage, setCasesPerPage] = useState(5);
  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = casesData.slice(indexOfFirstCase, indexOfLastCase);

// Pagination handlers
const handleNextPage = () => {
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage(currentPage - 1);
};

 

  useEffect(() => {
    fetchCasesData();
  }, []);

  // Pagination change handler
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchCasesData(value); // Fetch data for the new page
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCases / casesPerPage);



  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
  
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
  
    // Set up the event listener
    window.addEventListener("resize", handleResize);
  
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [editFormData, setEditFormData] = useState({
    title: "",
    caseCode: "",
    honorableJudge: "",
    client: "",
    opponentPartyName: "",
  });
  const ip = data.evn

  useEffect(() => {
    fetchCasesData();
  }, []);

  const fetchCasesDataCount = async (page = 1) => {
    try {
      const response = await axios.get(`${ip}/edit/updatecases?page=${page}`, {
        // ... your axios request setup ...
      });
      // Assuming your API returns the total number of cases in the response
      setTotalCases(response.data.total);
      setCasesData(response.data.cases);
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchCasesData = async () => {
    try {
      const response = await axios.get(`${ip}/edit/updatecases`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      const data = response.data;
      setCasesData(data);
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleDeleteClick = async (caseId) => {
    if (window.confirm('Are you sure you want to delete this client?')) {
      try {
        await axios.delete(
          `${ip}/dashboard/updatecases/${caseId}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        );
        fetchCasesData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDownloadClick = async (caseId) => {
    console.log(caseId)
    console.log(localStorage.getItem("token"))
    setDownloadingCaseId(caseId); // Start showing the CircularProgress
    try {
      const response = await axios.get(
        `${ip}/dashboard/updatecases/download-pdf/${caseId}`,
        {
          responseType: "blob",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Case_${caseId}.pdf`;
      document.body.appendChild(a); // This line is necessary for Firefox
      a.click();
      document.body.removeChild(a); // Clean up
      setDownloadingCaseId(null);
    } 
    catch (error) {
      console.error(error);
      setDownloadingCaseId(null); // Ensure we stop showing the CircularProgress even on error
    }
  };
  const handleEditClick = (caseItem) => {
    setEditingCase(caseItem.id);
    openModal();
  };

  
  const handleCancelClick = () => {
    setEditingCase(null);
    closeModal();
  };

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
    <div className={style.container}>
      <div className={style.TableContainer}>
      <table className={style.table}>
      <thead className={style.tableHead}>
          <tr>
            <th className={style.titleColumn}>Title</th>
            <th>CNR No.</th>
            <th className={style.titleColumn1}>Court Type</th>
            <th>State/HC</th>
            <th>District/Bench/HC</th>
            <th>Specific Jurisdiction</th>
            <th>Upcoming Hearing</th> 
            <th className={style.titleColumn1}>Actions</th>
          </tr>
        </thead>
        
        <tbody className={style.tableBody}>

        {currentCases.map((caseItem) => (
  <tr key={caseItem.id}>
    <td><NavLink to={`/case/cases/${caseItem.id}`} className={style.titleColumn}>{caseItem.title}</NavLink></td>
    <td className={style.td}>{caseItem.cino}</td>

    {caseItem.COURT_TYPEO ? (
      <>
        <td className={style.titleColumn1}>{caseItem.COURT_TYPEO}</td>
        <td className={style.td}>{caseItem.STATE}</td>
        <td className={style.td}>{caseItem.COURT_NAME}</td>
      </>
    ) : (
      <>
        <td className={style.titleColumn1}>{caseItem.court_type}</td>
        <td className={style.td}>{caseItem.state_name}</td>
        <td className={style.td}>{caseItem.district_name}</td>
      </>
    )}
              <td className={style.td}>{caseItem.court_no_desg_name}</td>
              <td className={style.td}>{caseItem.date_next_list}</td>
              <td className={style.titleColumn1}>
              
              <Tooltip title="Edit" disableInteractive>
              <button className={style.btn} onClick={() => handleEditClick(caseItem)}><BorderColorIcon /></button>
              </Tooltip>
              <Tooltip title="Delete" disableInteractive>
                <button className={style.btn}  onClick={() => handleDeleteClick(caseItem.id)}><DeleteIcon /></button>
              </Tooltip> 
              <Tooltip title="Download Pdf" disableInteractive>
                <button className={style.btn} onClick={() => handleDownloadClick(caseItem.id)}>
                <DownloadForOfflineIcon /> 
                {downloadingCaseId === caseItem.id && <CircularProgress size="1.1rem" color="inherit" />} 
              </button>
              </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
        {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          
      <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
        <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
        <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
      </Button>

      <span className={style.currentPageIndicator}>
        {currentPage}
      </span>

      <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(casesData.length / casesPerPage)} className={style.nextButton}>
        <span className={style.next}>Next {currentPage + 1}</span>
        <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
      </Button>
    </div>

      </table>
      <Pagination
        count={pageCount} // Total number of pages
        page={currentPage} // Current page
        onChange={handlePageChange} // Handler for page change
        color="primary"
      />   
      
         </div>

       <Modal isOpen={isModalOpen} onClose={handleCancelClick}>
      {editingCase && (
            <EditCaseForm
            caseId={editingCase}
              onCancel={handleCancelClick}
              onClose={handleCancelClick}
            />
          )}
          </Modal> 
    </div>
  </>
  );
};

export default ShowUpdateCase;
