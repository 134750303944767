import React, { useEffect, useState } from 'react';
import CaseTitleData from './CaseTitleData';
import MultiFormTabs from './MultiFormTabs';
import style from './CaseTitleform.module.css';
import DashboardNavbar from '../../../utilities/DashboardNavbar/DashboardNavbar';
import SideNav from '../../../utilities/SideNavBar/SideNav';


const CaseTitleForm = () => {
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setDeviceType('mobile');
      } else if (window.innerWidth <= 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };
  
    handleResize();
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {deviceType !== 'desktop' ? (<SideNav />) : (<DashboardNavbar />)}

      <div className={style.MainContainer}>
        <div className={style.ComponentContainer}>
          <CaseTitleData />
        </div>
        <div className={style.MultiFormContainer}>
          <MultiFormTabs />
        </div>
      </div>
    </>
  );
}

export default CaseTitleForm;
