import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import styles from './Bill.module.css';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {data} from '../../../evn/evn'
import SideNav from '../../utilities/SideNavBar/SideNav';
import { Button, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const generateBillNo = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const date = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${date}-${hours}${minutes}${seconds}`;
};

const ip = data.evn

const BillForm = () => {
  const [billingType, setBillingType] = useState(" "); // Default billing type
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const initialValues = {
    billNumber: generateBillNo(),
    title: '',
    currentDate: '',
    dateFrom: '',
    dateTo: '',
    fullAddress: '',
    billingType: '',
    totalHours: '',
    noOfHearings: '',
    totalAmount: '',
    amount: '',
    taxType: '',
    taxPercentage: '',
    totalAmountWithTax: '',
    description: '',
    addDoc: null,
  };

  let validationSchema;

try {
  validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    currentDate: Yup.date(),
    dateFrom: Yup.date(),
    dateTo: Yup.date()
       .min(
        Yup.ref('dateFrom'),
        "Date To can't be before the Date From"
         ),
    fullAddress: Yup.string(),
    billingType: Yup.string(),
    amount: Yup.string(),
    taxType: Yup.string(),
    taxPercentage: Yup.string(),
    totalAmountWithTax: Yup.string(),
    description: Yup.string(),
    addDoc: Yup.mixed().nullable(),
  });
} catch (error) {
  toast.error('An error occurred while creating the validation schema:', error);
}
const calculateTotalWithTax = (amount, taxPercentage) => {
  return amount + (amount * (taxPercentage / 100));
};

const handleFieldChange = (e, setFieldValue, values) => {
  const { name, value } = e.target;
  let amount = name === 'amount' ? parseFloat(value) || 0 : parseFloat(values.amount) || 0;
  let taxPercentage = name === 'taxPercentage' ? parseFloat(value) || 0 : parseFloat(values.taxPercentage) || 0;

  setFieldValue(name, value); // Update the changed field

  const totalWithTax = calculateTotalWithTax(amount, taxPercentage);
  setFieldValue('totalAmountWithTax', totalWithTax.toFixed(2)); // Update the total amount with tax
};

const handleSubmit = async (values, { setSubmitting, resetForm }) => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    if (key === 'addDoc' && values[key]) {
      formData.append('addDoc', values.addDoc);
    } else {
      formData.append(key, values[key]);
    }
  });

  try {
    let docPath;
    if (values.addDoc) {
      const uploadResponse = await axios.post(`${ip}/uploaddocbill`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      docPath = uploadResponse.data.filePath;
    }

    const billData = { ...values, addDoc: docPath };
    await axios.post(`${ip}/bill`, billData, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    });

    alert('Bill Added successfully!');
    resetForm();
  } catch (error) {
    console.error(error);
    if (error.response) {
      alert(error.response.data.error);
    }
  } finally {
    setSubmitting(false);
  }
};


const navigate = useNavigate();
const HandleCancel=()=>{
  navigate('/dashboard')
}


useEffect(() => {
  const fetchData = async () => {
  //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
  //   setDashboardData([...datas]); // Update local state to trigger re-render
  };

  fetchData();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1150);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };

}, []);

  return (
    <>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
    <div className={styles['bill-form-container']}>
    <h2 className={styles.heading}>Generate Bills</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
      
        {({ setFieldValue, values }) => ( // formikProps is defined here
        <Form>
        <div className={styles.billNo}><span style={{ color: 'var(--color-accent1)'}}>BILL-</span>
          {generateBillNo()}
          </div>

          <div>
            <label className={styles.label}>Title</label>
            <Field type="text" name="title" className={styles['input-field']} />
            <ErrorMessage name="title" component="div" className={styles['error-message']} />
          </div>

          <div className={styles['horizontal-fields']}>
            <div>
              <label className={styles.label}>Current Date</label>
              <Field type="date" name="currentDate" className={styles['input-field']} />
              <ErrorMessage name="currentDate" component="div" className={styles['error-message']} />
            </div>

            <div>
              <label className={styles.label}>Date From</label>
              <Field type="date" name="dateFrom" className={styles['input-field-date']} />
              <ErrorMessage name="dateFrom" component="div" className={styles['error-message']} />
            </div>

            <div>
              <label className={styles.label}>Date To</label>
              <Field type="date" name="dateTo" className={styles['input-field']} />
              <ErrorMessage name="dateTo" component="div" className={styles['error-message']} />
            </div>
          </div>
          
          <div>
            <label className={styles.label}>Billing Type</label>
            <Field
              as="select"
              name="billingType"
              className={styles['input-field']}
              onChange={(e) => setBillingType(e.target.value)}
              value={billingType} // Set the value to the current billingType state
              >
              <option value="" disabled={!billingType}>
                Select your Billing Type
              </option>
              <option value="perHour">Per Hour</option>
              <option value="perHearing">Per Hearing</option>
              <option value="flatFee">Flat Fee</option>
            </Field>

            <ErrorMessage name="billingType" component="div" className={styles['error-message']} />
          </div>

          {/* Conditional Fields */}
          {billingType === 'perHour' && (
            <div>
              <label className={styles.label}>Total Hours</label>
              <Field type="text" name="totalHours" className={styles['input-field']} />
              <ErrorMessage name="totalHours" component="div" className={styles['error-message']} />
            </div>
          )}
          {billingType === 'perHearing' && (
            <div>
              <label className={styles.label}>No. of Hearings</label>
              <Field type="text" name="noOfHearings" className={styles['input-field']} />
              <ErrorMessage name="noOfHearings" component="div" className={styles['error-message']} />
            </div>
          )}
          {billingType === 'flatFee' && (
            <div>
              <label className={styles.label}>Total Amount</label>
              <Field type="text" name="totalAmount" className={styles['input-field']} />
              <ErrorMessage name="totalAmount" component="div" className={styles['error-message']} />
            </div>
          )}
          <div className={styles['horizontal-fields']}>
            <div>
              <label className={styles.label}>Amount</label>
              <Field 
              type="text" 
              name="amount" 
              className={styles['input-field']} 
              onChange={(e) => handleFieldChange(e, setFieldValue, values)}
            />
              <ErrorMessage name="amount" component="div" className={styles['error-message']} />
            </div>
            <div>
              <label className={styles.label}>Tax Type</label>
              <Field as="select" name="taxType" className={styles['input-field']}>
              <option value="" disabled>
                Select tax type
              </option>
                <option value="CGST">CGST</option>
                <option value="SGST">SGST</option>
                <option value="IGST">IGST</option>
                <option value="ST">ST</option>
              </Field>
              <ErrorMessage name="taxType" component="div" className={styles['error-message']} />
            </div>
            <div>
              <label className={styles.label}>Tax Percentage</label>
              <Field 
              type="text" 
              name="taxPercentage" 
              className={styles['input-field']} 
              onChange={(e) => handleFieldChange(e, setFieldValue, values)}
            />
              <ErrorMessage name="taxPercentage" component="div" className={styles['error-message']} />
            </div>
          </div>
          <div className={styles['horizontal-fields']}>
            <div>
              <label className={styles.label}>Total Amount with Tax</label>
              <Field 
              type="text" 
              name="totalAmountWithTax" 
              className={styles['input-field']} 
              readOnly
            />
              <ErrorMessage name="totalAmountWithTax" component="div" className={styles['error-message']}  />
            </div>
            <div>
              <label className={styles.labelFile}>Add Doc</label>
              <input
                id="addDoc"
                name="addDoc"
                type="file"
                onChange={(event) => {
                  setFieldValue('addDoc', event.currentTarget.files[0]);
                }}
                className={styles['input-field']}
              />

            </div>
          </div>
          <div className={styles['horizontal-fields']}>
          <div>
            <label className={styles.label}>Full Address</label>
            <Field
              as="textarea"
              name="fullAddress"
              className={styles['textarea-field']}
            />
            <ErrorMessage name="fullAddress" component="div" className={styles['error-message']} />
          </div>
          <div>
            <label className={styles.label}>Description</label>
            <Field
              as="textarea"
              name="description"
              className={styles['textarea-field']}
            />
            <ErrorMessage name="description" component="div" className={styles['error-message']} />
            </div>
            </div>
            <div className={styles.BtnContainer}>
            <Tooltip title="Submit Form" disableInteractive>
              <button type="submit" className={styles.submitButton}>Submit</button>
            </Tooltip>
            <Tooltip title="Cancel Form" disableInteractive>
              <button  type="submit" className={styles.buttonCancel} onClick={HandleCancel}>Cancel</button>
            </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer/>
    </div>
    </>
  );
};

export default BillForm;