import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './WalletBox.module.css'; // Ensure this path matches your CSS file location
import { data } from '../../../evn/evn'; // Make sure this import path is correct

const WalletDisplayComponent = ({onClose }) => {
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [beneficiaryName, setBeneficiaryName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const ip = data.evn; // Ensure this is the correct way to access your environment variable
    const walletRef = useRef(null); // Reference to the wallet container
    const toggleWalletModal = () => setShowWalletModal(!showWalletModal);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const handleCloseWalletModal = () => setShowWalletModal(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (walletRef.current && !walletRef.current.contains(event.target)) {
            }
        };
        // Add when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        // Return function to be called when it unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        axios.get(`${ip}/wallet/balance`, {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        })
        .then((response) => setBalance(response.data.balance))
        .catch((error) => console.error('Error fetching balance:', error));
    }, []);

    const handleTransferToBank = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        const payload = { 
            amount, 
            accountNumber, 
            accountName, 
            ifsc, 
            name: beneficiaryName, 
            email, 
            contactNumber 
        };

        axios.post(`${ip}/wallet/transfer-to-bank`, payload, {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        })
        .then(() => {
            alert('Transfer successful');
            setBalance((prevBalance) => prevBalance - parseFloat(amount));
            // Reset form fields
            setAmount('');
            setAccountNumber('');
            setAccountName('');
            setIfsc('');
            setBeneficiaryName('');
            setEmail('');
            setContactNumber('');
        })
        .catch((error) => console.error('Error transferring funds:', error));
    };
    const handleClickInside = (e) => e.stopPropagation();

    return (
        <div className={styles.blurContainer} onClick={onClose}>
            <div className={styles.walletContainer} ref={walletRef} onClick={handleClickInside}>            <h3 className={styles.walletBalance}>Wallet Balance: ₹{balance.toFixed(2)}</h3>
            <form className={styles.formContainer} onSubmit={handleTransferToBank}>
                <input 
                    type="text"
                    placeholder="Beneficiary Name"
                    value={beneficiaryName}
                    onChange={(e) => setBeneficiaryName(e.target.value)}
                    className={styles.input}
                />
                <input 
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={styles.input}
                />
                <input 
                    type="text"
                    placeholder="Contact Number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    className={styles.input}
                />
                <input 
                    type="number" 
                    placeholder="Amount" 
                    value={amount} 
                    onChange={(e) => setAmount(e.target.value)} 
                    className={styles.input}
                />
                <input 
                    type="text" 
                    placeholder="Account Number" 
                    value={accountNumber} 
                    onChange={(e) => setAccountNumber(e.target.value)} 
                    className={styles.input}
                />
                <input 
                    type="text" 
                    placeholder="Account Holder's Name" 
                    value={accountName} 
                    onChange={(e) => setAccountName(e.target.value)} 
                    className={styles.input}
                />
                <input 
                    type="text" 
                    placeholder="IFSC Code" 
                    value={ifsc} 
                    onChange={(e) => setIfsc(e.target.value)} 
                    className={styles.input}
                />
                <button type="submit" className={styles.transferButton}>Transfer to Bank</button>
            </form>
        </div>
        </div>
    );
};

export default WalletDisplayComponent;
