import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import style from "./forgot.module.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { data } from '../../evn/evn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

// Define the password validation schema
const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least one digit')
    .matches(/(?=.*[&%$#@^_~])/, 'Password must contain at least one symbol: & % $ # @ ^ _ ~')
    .required('Password is required'),
});

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const ip = data.evn;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password before proceeding
    try {
      await passwordSchema.validate({ password });
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match.");
      }

      // If validation passes, proceed with password reset
      const response = await axios.post(`${ip}/reset-password/${token}`, { password });
      toast.success(response.data.message);
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 3000);
    } catch (error) {
      toast.error(error.message); // Show validation error message
    }
  };

  return (
    <div className={style.midContainer}>
      <ToastContainer />
      <div className={style.midLeftContainer}>
        <img src="https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg?cs=srgb&dl=pexels-sora-shimazaki-5669602.jpg&fm=jpg" alt="logo2" />
      </div>
      <div className={style.midRightContainer}>
        <div className="container-head">
          <h2><span style={{ color: 'var(--color-accent2)' }}>Reset password</span></h2>
        </div>

        <form className={style.formContainer} onSubmit={handleSubmit}>
          <div className={style.inputContainer}>
            <input
              className={`${style.input} ${style.passwordInput}`}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
              required
            />
            <button
              type="button"
              className={style.visibilityButton}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          <div className={style.inputContainer}>
            <input
              className={`${style.input} ${style.passwordInput}`}
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              required
            />
          </div>
          <input className={style.submit} type="submit" value="Reset Password" />

        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
