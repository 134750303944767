import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import success from "../../assets/success.png"; // Make sure the path is correct
import styles from "./VerifyEmail.module.css"; // Make sure the path is correct
import {data} from '../../evn/evn'

const VerifyEmail = () => {
  const [validUrl, setValidUrl] = useState(false);
  const { token } = useParams();  
  const ip = data.evn
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const url = `${ip}/verifyemail/${token}`; // Adjust the URL as per your backend API
        const { data } = await axios.get(url);
        console.log(data);
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token]);

  return (
    <div className={styles.container}>
      {validUrl ? (
        <>
          <img src={success} alt="success" className={styles.success_img} />
          <h1 style={{textAlign:"center"}}>Email verified successfully</h1>
          <Link to="/login">
            <button className={styles.green_btn}>Login</button>
          </Link>
        </>
      ) : (
        <h1>404 Not Found</h1>
      )}
    </div>
  );
};

export default VerifyEmail;
