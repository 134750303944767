import React, { useState, useEffect, forwardRef } from 'react';
import { Box, Text, Image, Link , Icon} from '@chakra-ui/react';
import {data} from '../../evn/evn'
import axios from 'axios';
import { FaFileDownload } from 'react-icons/fa'; 
import styles from './Style/ScrollableChat.module.css';
import { Button, Tooltip } from '@mui/material';


  const ScrollableChat = forwardRef(({ messages }, ref) => {
  const [currentUserId, setCurrentUserId] = useState(null);
  const ip = data.evn

  // Decode JWT to get current user's ID
  useEffect(() => {
    const fetchCurrentUserId = async () => {
      try {
        // Replace '/api/currentUser' with your actual endpoint that returns the current user's data
        const response = await axios.get(`${ip}/api/currentUser`, {
          headers:  { 'x-auth-token': localStorage.getItem('token') },
        });
        // Assuming the response includes a userId field with the current user's ID
        setCurrentUserId(response.data.userId);
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };
    fetchCurrentUserId();
  }, []);
  const isImage = (filePath) => /\.(jpg|jpeg|png|gif|bmp)$/i.test(filePath);
  const isVideo = (filePath) => /\.(mp4|webm)$/i.test(filePath);

  return (
    <Box ref={ref} className={styles.chatContainer}>
      {messages.map((message, index) => {
        const isSender = message.sender_id === currentUserId;
        return (
          <Box key={message._id || index} className={`${styles.messageBox} ${isSender ? styles.sender : styles.receiver}`}>
            {!isSender && message.sender && <Text className={styles.senderName}>{message.sender.name}</Text>}
            <Text className={styles.messageContent}>{message.content}</Text>
            {message.file_path && (
              isImage(message.file_path) ? (
                <Tooltip title="Right click on image to download image" placement="right">
                  <Image src={`${ip}/uploads/${message.file_path}`} alt="Uploaded File" maxH="200px" maxW="250px" />
                </Tooltip>
              ) : isVideo(message.file_path) ? (
                <video controls width="250">
                  <source src={`${ip}/uploads/${message.file_path}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                // For other file types like PDFs, consider using an <iframe> or <object> element here
                <Link href={`${ip}/uploads/${message.file_path}`} isExternal>
                  <Button variant="contained" startIcon={<FaFileDownload />}>
                    View File
                  </Button>
                </Link>
              )
            )}
            <Text className={styles.messageTimestamp}>
              {message.created_at && new Date(message.created_at + 'Z').toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
});

export default ScrollableChat;