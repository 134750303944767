// UserManagement.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { data } from '../../evn/evn'; // Make sure the path is correct
import Sidebar from '../AdminComponents/SideBar';
import styles from './UserManagement.module.css'; // Assume similar CSS styles as CaseManagement

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const ip = data.evn; // Assuming this is where your API base URL is stored
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/.admin');
      return false;
  }
    try {
      const response = await axios.get(`${ip}/admin/new-users`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setUsers(response.data.users);
      setSearchResults(response.data.users); // Initially, search results include all users
    } catch (error) {
      console.error("There was an error fetching the users:", error);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filteredUsers = users.filter(user => user.id.toString().includes(value));
    setSearchResults(filteredUsers);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const exactMatchUsers = users.filter(user => user.id.toString() === searchTerm);
      setSearchResults(exactMatchUsers);
    }
  };
  const calculateTrialEndDate = (startDate) => {
    const start = new Date(startDate);
    start.setDate(start.getDate() + 15); // Adds 15 days to the start date
    return start.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
  };

  const checkSubscriptionStatus = (endDate) => {
    return endDate ? 'Yes' : 'No';
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <h2 className={styles.heading}>All User Details</h2>
        <input
        type="text"
        placeholder="Search users by User ID..."
        value={searchTerm}
        onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
        className={styles.searchInput}
      />
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Username</th>
              <th className={styles.tableHeader}>Name</th>
              <th className={styles.tableHeader}>Mobile</th>
              <th className={styles.tableHeader}>Lawyer Type</th>
              <th className={styles.tableHeader}>Experience</th>
              <th className={styles.tableHeader}>Enrollment No.</th>
              <th className={styles.tableHeader}>Is Verified</th>
              <th className={styles.tableHeader}>Registration Date</th>
              <th className={styles.tableHeader}>Trial start Date</th>
              <th className={styles.tableHeader}>Trial End Date</th>
              <th className={styles.tableHeader}>Is User Subscribed?</th>
              <th className={styles.tableHeader}>Subscription End Date</th>
              
              
            </tr>
          </thead>
          <tbody>
          {searchResults.map(user => ( // Make sure to use searchResults here
    <tr key={user.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{user.id}</td>
                <td className={styles.tableCell}>{user.username}</td>
                <td className={styles.tableCell}>{user.name}</td>
                <td className={styles.tableCell}>{user.mobile}</td>
                <td className={styles.tableCell}>{user.lawyerType}</td>
                <td className={styles.tableCell}>{user.experience}</td>
                <td className={styles.tableCell}>{user.age}</td>
                <td className={styles.tableCell}>{user.is_verified ? 'Yes' : 'No'}</td>
                <td className={styles.tableCell}>{user.registration_date}</td>
                <td className={styles.tableCell}>{user.trial_start_date}</td>
                <td className={styles.tableCell}>{calculateTrialEndDate(user.trial_start_date)}</td>
                <td className={styles.tableCell}>{checkSubscriptionStatus(user.subscription_end_date)}</td>
                <td className={styles.tableCell}>{user.subscription_end_date || 'N/A'}</td>
              
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserManagement;
