import React, { useEffect, useState } from 'react'
import style from './ImportCase.module.css'
import { NavLink } from 'react-router-dom'
import DashboardNavbar from '../../../utilities/DashboardNavbar/DashboardNavbar'
import SideNav from '../../../utilities/SideNavBar/SideNav'


const ImportCase = () => {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
    const [hoverState, setHoverState] = useState({ left: false, right: false });

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
  
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
  
    // Set up the event listener
    window.addEventListener("resize", handleResize);
  
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    return (
        <div className={style.Container}>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
        <div className={style.MainContainer}>
            <div className={style.ContainerLeft} 
             onMouseEnter={() => setHoverState({ ...hoverState, left: true })}
             onMouseLeave={() => setHoverState({ ...hoverState, left: false })}
           >
                <h1 className={`${style.heading} ${hoverState.left || hoverState.right ? style.hovered : ''}`}>Fill Manually</h1>

                <ul className={style.Caselinks}>
                    <li><NavLink to={'/dashboard/caseform'}>Add Case Manully</NavLink></li>

                </ul>
            </div>

            <div className={style.ContainerRight}
            onMouseEnter={() => setHoverState({ ...hoverState, left: true })}
            onMouseLeave={() => setHoverState({ ...hoverState, left: false })}
            >
                <h1 className={`${style.heading} ${hoverState.left || hoverState.right ? style.hovered : ''}`}>Import Case</h1>

                <ul className={style.Caselinks}>
                    <li><NavLink to={"/cnrform"}>CaseNo. Search</NavLink></li>
                    <li><NavLink to={"/partynameform"}>Party Search</NavLink></li>
                    <li><NavLink to={"/advocateform"}>Advocate Search</NavLink></li>
                </ul>
            </div>
        </div>
        </div>
    )
}

export default ImportCase