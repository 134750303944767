import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn';
import Sidebar from '../AdminComponents/SideBar.jsx';
import { useNavigate } from 'react-router-dom';

import styles from './style/NewCustomerLead.module.css';
import { FaDownload, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';


const NewCustomerLead = () => {
  const [users, setUsers] = useState({});
  const navigate = useNavigate();
  const [downloadingDates, setDownloadingDates] = useState({});
  const [dateSearchTerm, setDateSearchTerm] = useState('');
  const ip = data.evn;
  const [adminStatuses, setAdminStatuses] = useState({});

  useEffect(() => {
    const fetchNewUsers = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }
      try {
        const response = await axios.get(`${ip}/admin/new-users`, {
          headers: { 'x-auth-token': token },
        });
        
        const groupedUsers = {};
        const dateStatusMap = {}; // Tracks the admin status for each date
  
        // Group users by registration_date and determine the admin status for each date
        response.data.users.forEach(user => {
          const { registration_date, admin_status } = user;
          groupedUsers[registration_date] = groupedUsers[registration_date] || [];
          groupedUsers[registration_date].push(user);
          
          // If any user on a date has admin_status as 0, mark the date as false
          if(admin_status === 0) {
            dateStatusMap[registration_date] = false;
          } else if(dateStatusMap[registration_date] !== false) {
            dateStatusMap[registration_date] = true; // Only set to true if not already set to false
          }
        });
  
        setUsers(groupedUsers);
        setAdminStatuses(dateStatusMap);
      } catch (error) {
        console.error("There was an error fetching the new users:", error);
      }
    };
  
    fetchNewUsers();
  }, []);
  const handleDateSearchChange = (event) => {
    setDateSearchTerm(event.target.value);
  };

  const filteredUsersByDate = dateSearchTerm
    ? Object.entries(users).reduce((acc, [date, usersForDate]) => {
        if (date.includes(dateSearchTerm)) {
          acc[date] = usersForDate;
        }
        return acc;
      }, {})
    : users;
  const handleDownloadPdf = async (date) => {
    setDownloadingDates(prev => ({ ...prev, [date]: true })); // Start downloading
    try {
      const response = await axios.get(`${ip}/dashboard/new-users/download-pdf/${date}`, {
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
        responseType: 'blob',
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.setAttribute('download', `users-${date}.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (error) {
      console.error("There was an error downloading the PDF:", error);
    } finally {
      setDownloadingDates(prev => ({ ...prev, [date]: false })); // End downloading
    }
  };
  
  const toggleAdminStatus = async (date) => {
    const newStatus = !adminStatuses[date];
    try {
      await axios.post(`${ip}/admin/update-status/${date}`, { newStatus }, {
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
      });
      setAdminStatuses(prev => ({ ...prev, [date]: newStatus }));
    } catch (error) {
      console.error("There was an error updating the admin status:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <h2 className={styles.heading}>New Customer Leads</h2>
        <input
          type="date"
          value={dateSearchTerm}
          onChange={handleDateSearchChange}
          className={styles.searchInput}
        />
        {Object.entries(filteredUsersByDate).map(([date, usersForDate]) => (
          <React.Fragment key={date}>
            <div className={styles.dateHeaderContainer}>
  <h3 className={styles.dateHeader}>{date}</h3>
  {downloadingDates[date] ? (
        <FaSpinner className={`${styles.downloadIcon} ${styles.spinner}`} />
      ) : (
        <FaDownload className={styles.downloadIcon} onClick={() => handleDownloadPdf(date)} />
      )}
  {adminStatuses[date] ? (
                <FaCheckCircle className={styles.statusIcon} style={{ color: 'green' }} onClick={() => toggleAdminStatus(date)} />
              ) : (
                <FaTimesCircle className={styles.statusIcon} style={{ color: 'red' }} onClick={() => toggleAdminStatus(date)} />
              )}
</div>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHeader}>ID</th>
                  <th className={styles.tableHeader}>Username</th>
                  <th className={styles.tableHeader}>Name</th>
                  <th className={styles.tableHeader}>Mobile</th>
                </tr>
              </thead>
              <tbody>
                {usersForDate.map((user) => (
                  <tr key={user.id}>
                    <td className={styles.tableCell}>{user.id}</td>
                    <td className={styles.tableCell}>{user.username}</td>
                    <td className={styles.tableCell}>{user.name}</td>
                    <td className={styles.tableCell}>{user.mobile}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default NewCustomerLead;
