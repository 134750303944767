import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardNavbar from "../DashboardNavbar/DashboardNavbar";
import style from './Notifications.module.css';

import { IoSettings, IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "../../form/Client/People/ModelPop/Modal";
import NotificationSetting from "./NotificationSetting/NotificationSetting";
import {data} from '../../../evn/evn'
import SideNav from "../SideNavBar/SideNav";

const Notifications = () => {
  const [tasks, setTasks] = useState([]);
  const [proxies, setProxies] = useState([]);
  const [acceptedProxies, setAcceptedProxies] = useState([]);
  const [acceptedProxiesLoading, setAcceptedProxiesLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [proxiesLoading, setProxiesLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState({});
  const navigate = useNavigate();
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [notificationMethod, setNotificationMethod] = useState('email');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isAlertsToggled, setIsAlertsToggled] = useState(() => {
    const savedToggleState = localStorage.getItem('isAlertsToggled');
    return savedToggleState ? JSON.parse(savedToggleState) : false;
  });
  const ip = data.evn
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    checkSubscriptionStatus();
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const toggleSettingsDropdown = () => {
    
    // console.log("Toggle Dropdown");
    setShowSettingsDropdown(!showSettingsDropdown);
  };
  
  const handleOptionChange = (e) => {
    setNotificationMethod(e.target.value);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSettingsDropdown && !event.target.closest(`.${style.settingIconContainer}`)) {
        setShowSettingsDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showSettingsDropdown]);
  useEffect(() => {
    localStorage.setItem('isAlertsToggled', JSON.stringify(isAlertsToggled));
  }, [isAlertsToggled]);

  useEffect(() => {
    axios.get(`${ip}/dashboard/user/notifications`, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    })
    .then((response) => {
      setTasks(response.data);
    })
    .catch((error) => {
      console.error(error);
      setError(error);
    })
    .finally(() => setTasksLoading(false));
  }, []);

  useEffect(() => {
    axios.get(`${ip}/dashboard/user/proxy-notifications`, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    })
    .then((response) => {
      setProxies(response.data);
    })
    .catch((error) => {
      console.error(error);
      setError(error);
    })
    .finally(() => setProxiesLoading(false));
  }, []);

  useEffect(() => {
    axios.get(`${ip}/dashboard/user/proxy-notifications-accepted`, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    })
    .then((response) => {
      setAcceptedProxies(response.data);
    })
    .catch((error) => {
      console.error(error);
      setError(error);
    })
    .finally(() => setAcceptedProxiesLoading(false));
  }, []);

  const handleDelete = (id, type) => {
    axios.delete(`${ip}/dashboard/user/notifications/${id}`, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    })
    .then(() => {
      console.log(`${type} deleted successfully`);
      if (type === 'alert') {
        setTasks(prev => prev.filter(task => task.id !== id));
      } else if (type === 'proxy') {
        setProxies(prev => prev.filter(proxy => proxy.id !== id));
      } else if (type === 'acceptedProxy') {
        setAcceptedProxies(prev => prev.filter(proxy => proxy.notificationId !== id));
      }
    })
    .catch((error) => {
      console.error(`Error deleting ${type}:`, error);
    });
  };

  const handleAccept = async (proxyId) => {
    try {
      await axios.post(`${ip}/dashboard/user/accept-proxy/${proxyId}`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      setProxies(prev => prev.filter(proxy => proxy.proxyId !== proxyId));
      
    } catch (error) {
      console.error(`Error accepting proxy:`, error.response.data);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    });
  };
  const handleRazorpayPayment = async (proxyId, acceptorId) => {

    const proxy = acceptedProxies.find(p => p.proxyId === proxyId);

  if (!proxy || !proxy.amount) {
    alert("Error: Proxy payment details not found.");
    return;
  }

  // Convert amount to the smallest currency unit if necessary
  // Here we assume `amount` is already in the correct format (e.g., paise for INR)
  const amount = proxy.amount;
    // Load the Razorpay script and configure the payment request
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
        return;
    }

    const options = {
        key: 'rzp_test_SJsbPFYVQOtlbi',
        amount: amount * 100, // Amount in smallest currency unit
        currency: "INR",
        name: "Proxy Acceptance Fee",
        description: "Fee for accepting proxy",
        handler: function (response) {
            alert(`Payment Successful\nPayment ID: ${response.razorpay_payment_id}`);
            handleChooseAcceptor(proxyId, acceptorId, response.razorpay_payment_id);
            navigate(0);
        },
        prefill: {
            // Prefill the contact information if available
        },
        theme: {
            color: "#3399cc",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};

const handleChooseAcceptor = async (proxyId, acceptorId, paymentId) => {
  try {
      await axios.post(`${ip}/dashboard/user/choose-acceptor/${proxyId}/${acceptorId}`, { paymentId }, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      // Update the UI accordingly after successful operation
  } catch (error) {
      console.error('Error choosing acceptor:', error.response ? error.response.data : error);
  }
};

  const toggleDropdown = (index, type) => {
    const key = `${type}-${index}`;
    setShowDropdown(prevState => ({
      ...prevState,
      // [key]: !prevState[key]
      [`${type}-${index}`]: !prevState[`${type}-${index}`],
    }));
  };
  const handleToggleAlerts = async () => {
    const newToggleState = !isAlertsToggled;
    setIsAlertsToggled(newToggleState);

    try {
      const response = await axios.post(`${ip}/updateEmailNoti`, 
        { emailNoti: newToggleState ? 1 : 0 },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      console.log(response.data.message);
      // Handle success
    } catch (error) {
      console.error('Error updating email notifications setting:', error);
      // Optionally revert the toggle if the backend update fails
      // setIsAlertsToggled(!newToggleState);
    }
  };

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
        <div className={style.alertsToggleContainer} onClick={handleToggleAlerts}>
        <div className={style.alertsrow} >
        <label className={style.alertsLabel}>Alerts</label>
        <Tooltip title="Turn on alerts to get these notifications on your email" disableInteractive>
        <span>
        <IoInformationCircleOutline className={style.infoIcon} />
        </span>
        </Tooltip>
        </div>
  <div className={`${style.alertsToggle} ${isAlertsToggled ? style.alertsToggleOn : style.alertsToggleOff}`}></div>
 
</div>
<div className={style.sectionsContainer}>
      <div className={style.notificationsContainer}>
      
      
      <div className={style.section}>
  <h2 className={style.Header}>Notification Centre</h2>
  {tasksLoading ? (
    <p>Loading Tasks...</p>
  ) : (
    <ul>
      {tasks.map((task, index) => (
        <li
          key={task.id}
          className={style.notificationItem}
          // Apply conditional inline style for border-left
          style={{
            borderLeft: /<[a-z][\s\S]*>/i.test(task.message) ? '4px solid var(--color-danger)' : `4px solid var(--color-accent2)`,
          }}
        >
          {/* Check if the message contains HTML tags */}
          {/<[a-z][\s\S]*>/i.test(task.message) ? (
            <div
              className={`${style.notificationTextUnread} ${style.bannerAnimation}`}
              dangerouslySetInnerHTML={{ __html: task.message }}
            ></div>
          ) : (
            <span className={style.notificationTextUnread}>{task.message}</span>
          )}
          <button onClick={() => toggleDropdown(index, 'alert')} className={style.dropdownToggle}>
            ⋮
          </button>
          {showDropdown[`alert-${index}`] && (
            <div
              className={`${style.dropdownMenu} ${
                showDropdown[`alert-${index}`] ? style.dropdownMenuVisible : ''
              }`}
            >
              <div className={style.MenuItemDelete} onClick={() => handleDelete(task.id, 'alert')}>
                Delete
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  )}
</div>


        {/* Proxies Section */}
{/* section start here */}
        <div className={style.section}>
          {proxiesLoading ? <p>Loading proxies...</p> : (
            <ul>
              {proxies.map((proxyItem, index) => (
                <li key={proxyItem.id} className={style.notificationItem}>
                  {/* Using dangerouslySetInnerHTML to render the message as HTML */}
                  <span
                    className={style.notificationTextUnread}
                    dangerouslySetInnerHTML={{ __html: proxyItem.message }}
                  />
          <button onClick={() => toggleDropdown(index, 'proxy')} className={style.dropdownToggle}>⋮</button>
          {showDropdown[`proxy-${index}`] && (
            <div className={`${style.dropdownMenu} ${showDropdown[`proxy-${index}`] ? style.dropdownMenuVisible : ''}`}>
              <div className={style.MenuItemDelete} onClick={() => handleDelete(proxyItem.id, 'proxy')}>Delete</div>
              <div className={style.MenuItem} onClick={() => handleAccept(proxyItem.proxyId)}>Accept</div>
            </div>
          )}
        </li>
      ))}
    </ul>
  )}
</div>
{/* section end here */}

</div>
        {/* Accepted Proxies Section */}
        <div>
          <h2 className={style.Header}>PROXY COUNCIL</h2>

          {acceptedProxiesLoading ? <p>Loading accepted proxies...</p> : (
            <ul>
              {acceptedProxies.map((proxy, index) => (
                <li key={proxy.id} className={style.notificationItem}>
                  <span className={style.notificationTextUnread}>{proxy.message}</span>
                  <button onClick={() => toggleDropdown(index, 'acceptedProxy')} className={style.dropdownToggle}>⋮</button>
                  {showDropdown[`acceptedProxy-${index}`] && (
                    <div className={`${style.dropdownMenu} ${showDropdown[`acceptedProxy-${index}`] ? style.dropdownMenuVisible : ''}`}>
                      <div className={style.MenuItemDelete} onClick={() => handleDelete(proxy.notificationId, 'acceptedProxy')}>Delete</div>
                      <div className={style.MenuItem} onClick={() => handleRazorpayPayment(proxy.proxyId, proxy.acceptorId)}>Choose Acceptor</div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* <NavLink className={style.IconContainer} onClick={openModal} to={"#"}> <IoSettings className={style.SettingIcon} />  </NavLink> */}

        <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <NotificationSetting onClose={closeModal}/>
        </Modal>
      </div>
    </>
  );
};

export default Notifications;
