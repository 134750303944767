import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn';
import Sidebar from '../AdminComponents/SideBar';
import { useNavigate } from 'react-router-dom';
import styles from './GroupManagement.module.css'; // Make sure this path matches your file structure

const GroupManagement = () => {
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchGroups = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/groups`, {
          headers: { 'x-auth-token': token },
        });
        setGroups(response.data.groups);
        setSearchResults(response.data.groups);
      } catch (error) {
        console.error("There was an error fetching the groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(groups);
    } else {
      const filteredGroups = groups.filter(group =>
        searchType === 'id' ? group.id.toString().includes(event.target.value)
                            : group.user_id.toString().includes(event.target.value));
      setSearchResults(filteredGroups);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(groups);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchGroups = groups.filter(group => {
        if (searchType === 'id') return group.id.toString() === searchTerm;
        return group.user_id.toString() === searchTerm;
      });
      setSearchResults(exactMatchGroups);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Group ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Group Name</th>
              <th className={styles.tableHeader}>Priority</th>
              
              <th className={styles.tableHeader}>Company</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map(group => (
              <tr key={group.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{group.id}</td>
                <td className={styles.tableCell}>{group.user_id}</td>
                <td className={styles.tableCell}>{group.groupName}</td>
                <td className={styles.tableCell}>{group.priority}</td>
                
                <td className={styles.tableCell}>{group.company}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GroupManagement;
