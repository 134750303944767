import React, { useState } from 'react';
import axios from 'axios';
import style from "./forgot.module.css";
import {data} from '../../evn/evn'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const ip = data.evn
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${ip}/forgot-password`, { email });
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error:', error.response?.data?.error || error.message);
      toast.error(error.response?.data?.error || 'An error occurred');
    }
  };

  return (
    <div className={style.midContainer}>
    
       <ToastContainer/>
      <div className={style.midLeftContainer}>
        <img src="https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg?cs=srgb&dl=pexels-sora-shimazaki-5669602.jpg&fm=jpg" alt="logo2" />
      </div>
      <div className={style.midRightContainer}>
        <div className="container-head">
          <h1><span style={{ color: 'var(--color-accent2)' }}>LAW</span>FAX <span style={{ color: 'var(--color-accent2)' }}>AI</span></h1>
          <h2><span style={{ color: 'var(--color-accent2)' }}>Reset password</span></h2>
        </div>

        <form className={style.formContainer} onSubmit={handleSubmit}>
          <input 
            className={style.input} 
            type="email" 
            name="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Enter your email" 
            required 
          />
          <input className={style.submit} type="submit" value="Submit" />
          {message && <p className={style.message}>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default Forgot;
