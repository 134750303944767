import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './InvoicesForm.module.css';
import axios from 'axios';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import SideNav from '../../utilities/SideNavBar/SideNav';
import { Button, Tooltip } from '@mui/material';
import {data} from '../../../evn/evn';

const generateInvoiceNo = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const date = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${date}-${hours}${minutes}${seconds}`;
};

const initialValues = {
  client: '',
  caseType: '',
  date: '',
  amount: '',
  taxType: '',
  taxPercentage: '',
  CumulativeAmount: '',
  fullAddress: '',
  hearingDate: '',
  title: '',
  dateFrom: '',
  dateTo: '',
  expensesAmount: '',
  expensesTaxType: '',
  expensesTaxPercentage: '',
  expensesCumulativeAmount: '',
  addDoc: null,
  totalAmount: '',
  invoiceNumber: generateInvoiceNo(),
};

const validationSchema = Yup.object().shape({
  client: Yup.string(),
  caseType: Yup.string(),
  date: Yup.date(),
  amount: Yup.number().min(0, 'Amount must be greater than or equal to 0'),
  taxType: Yup.string(),
  taxPercentage: Yup.number().min(0, 'Tax Percentage must be greater than or equal to 0'),
  CumulativeAmount: Yup.number().min(0),
  fullAddress: Yup.string(),
  hearingDate: Yup.date(),
  title: Yup.string(),
  dateFrom: Yup.date(),
  dateTo: Yup.date()
  .min(
    Yup.ref('dateFrom'),
    "Date To can't be before the Date From"
  ),
  expensesAmount: Yup.number().min(0, 'Amount must be greater than or equal to 0'),
  expensesTaxType: Yup.string(),
  expensesTaxPercentage: Yup.number().min(0, 'Tax Percentage must be greater than or equal to 0'),
  expensesCumulativeAmount: Yup.number().min(0, 'Cumulative Amount must be greater than or equal to 0'),
  totalAmount: Yup.number().min(0),
  addDoc: Yup.mixed().nullable(),
});

const InvoicesForm = () => {
  const [clientNames, setClientNames] = useState([]); // State to store client names
  const [caseTitles, setCaseTitles] = useState([]); // State to store case titles
  const ip = data.evn
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    const fetchData = async () => {
    //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
    //   setDashboardData([...datas]); // Update local state to trigger re-render
    };

    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);


  useEffect(() => {
    // Fetch client names and populate the select options
    const fetchClientNames = async () => {
      try {
        const clientResponse = await axios.get(`${ip}/clientform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });

        // Extract the client names from the response data
        const clientNameArray = clientResponse.data.map((client) => client.firstName);
        setClientNames(clientNameArray);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch case titles and populate the select options
    const fetchCaseTitles = async () => {
      try {
        const caseResponse = await axios.get(`${ip}/caseform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });

        // Extract the case titles from the response data
        const caseTitleArray = caseResponse.data.map((caseItem) => caseItem.title);
        setCaseTitles(caseTitleArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientNames(); // Call the fetchClientNames function when the component mounts
    fetchCaseTitles(); // Call the fetchCaseTitles function when the component mounts
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (key === 'addDoc' && values[key]) {
        formData.append('addDoc', values.addDoc);
      } else {
        formData.append(key, values[key]);
      }
    });
  
    try {
      let docPath;
      if (values.addDoc) {
        const uploadResponse = await axios.post(`${ip}/uploaddoc`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        docPath = uploadResponse.data.filePath;
      }
  
      const invoiceData = { ...values, addDoc: docPath };
      await axios.post(`${ip}/invoiceform`, invoiceData, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
  
      alert('Invoice Added successfully!');
      resetForm();
    } catch (error) {
      console.error(error);
      if (error.response) {
        alert(error.response.data.error);
      }
    } finally {
      setSubmitting(false);
    }
  };
  

  const navigate = useNavigate();
  const HandleCancel=()=>{
    navigate('/dashboard')
  }


  const calculateNormalTotalWithTax = (amount, taxPercentage) => {
    return amount + (amount * (taxPercentage / 100));
};

const calculateExpensesTotalWithTax = (expensesAmount, expensesTaxPercentage) => {
    return expensesAmount + (expensesAmount * (expensesTaxPercentage / 100));
};

const calculateTotalAmount = (cumulativeAmount, expensesCumulativeAmount) => {
    return (parseFloat(cumulativeAmount) || 0) + (parseFloat(expensesCumulativeAmount) || 0);
};

const handleNormalChange = (e, setFieldValue, values) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    const amount = name === 'amount' ? parseFloat(value) || 0 : parseFloat(values.amount) || 0;
    const taxPercentage = name === 'taxPercentage' ? parseFloat(value) || 0 : parseFloat(values.taxPercentage) || 0;
    const cumulativeAmount = calculateNormalTotalWithTax(amount, taxPercentage);

    setFieldValue('CumulativeAmount', cumulativeAmount.toFixed(2));
    const totalAmount = calculateTotalAmount(cumulativeAmount, values.expensesCumulativeAmount);
    setFieldValue('totalAmount', totalAmount.toFixed(2));
};

const handleExpensesChange = (e, setFieldValue, values) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    const expensesAmount = name === 'expensesAmount' ? parseFloat(value) || 0 : parseFloat(values.expensesAmount) || 0;
    const expensesTaxPercentage = name === 'expensesTaxPercentage' ? parseFloat(value) || 0 : parseFloat(values.expensesTaxPercentage) || 0;
    const expensesCumulativeAmount = calculateExpensesTotalWithTax(expensesAmount, expensesTaxPercentage);

    setFieldValue('expensesCumulativeAmount', expensesCumulativeAmount.toFixed(2));
    const totalAmount = calculateTotalAmount(values.CumulativeAmount, expensesCumulativeAmount);
    setFieldValue('totalAmount', totalAmount.toFixed(2));
};

  return (
    <div>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
    <div className={styles.formContainer}>
    <h2 className={styles.heading}>Generate Invoice's</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className={styles.invoiceNo}><span style={{ color: 'var(--color-accent1)'}}>INV-</span>
          {generateInvoiceNo()}</div>
  
           <label className={styles.label} htmlFor="title">Title</label>
            <Field type="text" name="title" className={styles.selectFieldType} />
            <ErrorMessage name="title" component="div" className={styles.errorMessage} />

            <div className={styles.clientContainer}>
            <Field as="select" name="client" className={styles.selectFieldType}>
                  <option value="">Select People</option>
                  {clientNames.map((clientName) => (
                    <option key={clientName} value={clientName}>
                      {clientName}
                    </option>
                  ))}
                </Field>
              <NavLink to="/dashboard/peopleform" className={styles.link}>
                Add new People
              </NavLink>
            </div>
            <ErrorMessage name="client" component="div" className={styles.errorMessage} />

            <div className={styles.fieldContainer}>
            <Field as="select" id="caseType" name="caseType" className={styles.selectFieldTypeDate}>
                  <option value="">Select a Case</option>
                  {caseTitles.map((caseTitle) => (
                    <option key={caseTitle} value={caseTitle}>
                      {caseTitle}
                    </option>
                  ))}
                </Field>
            </div>
            <label className={styles.label} htmlFor="dateTo">
                  Date:
                </label>
              <Field type="date" name="date" className={styles.selectFieldTypeDate} />
            <ErrorMessage name="caseType" component="div" className={styles.errorMessage} />
            <ErrorMessage name="date" component="div" className={styles.errorMessage} />

            <div className={styles.fieldContainer}>
              <Field type="number" name="amount" className={styles.inputField} placeholder="Amount"  onChange={(e) => handleNormalChange(e, setFieldValue, values)}/>
              <Field as="select" name="taxType" className={styles.selectFieldType}>
                <option value="">Select Tax Type</option>
                <option value="SGST">SGST</option>
                <option value="CGST">CGST</option>
                <option value="IGST">IGST</option>
                <option value="ST">ST</option>
              </Field>
              <Field type="number" name="taxPercentage" className={styles.inputField} placeholder="Tax Percentage" onChange={(e) => handleNormalChange(e, setFieldValue, values)}/>
            </div>

            <ErrorMessage name="amount" component="div" className={styles.errorMessage} />
            <ErrorMessage name="taxType" component="div" className={styles.errorMessage} />
            <ErrorMessage name="taxPercentage" component="div" className={styles.errorMessage} />

            <Field 
                type="number" 
                name="CumulativeAmount" 
                className={styles.inputField} 
                placeholder="Cumulative Amount"
                readOnly
              />

            <ErrorMessage name="CumulativeAmount" component="div" className={styles.errorMessage} />
            <Field as="textarea" name="fullAddress" className={styles.textareaField} placeholder="Full Address" />
            <ErrorMessage name="fullAddress" component="div" className={styles.errorMessage} />
        
            <div className={styles.heading}>EXPENSES</div>
            

            <div className={styles.fieldContainer}>
              <div className={styles.fieldGroup}>
                <label className={styles.label} htmlFor="dateFrom">
                  Date From:
                </label>
                <input type="date" name="dateFrom" className={styles.selectFieldTypeDate} />
               
                <label className={styles.label} htmlFor="expensesAmount">
                  Amount:
                </label>
                <Field 
                  type="number" 
                  name="expensesAmount" 
                  className={styles.inputField} 
                  placeholder="Amount"
                  onChange={(e) => handleExpensesChange(e, setFieldValue, values)}
                />
                 <label className={styles.label} htmlFor="dateTo">
                  Date To:
                </label>
                <Field type="date" name="dateTo" className={styles.selectFieldTypeDate} />
              </div>
              <div className={styles.fieldGroup}>

              </div>
            </div>
            <ErrorMessage name="dateFrom" component="div" className={styles.errorMessage} />
            <ErrorMessage name="dateTo" component="div" className={styles.errorMessage} />
            <ErrorMessage name="expensesAmount" component="div" className={styles.errorMessage} />

            <div className={styles.fieldContainer}>
              <Field as="select" name="expensesTaxType" className={styles.selectFieldType}>
                <option value="">Select Tax Type</option>
                <option value="SGST">SGST</option>
                <option value="CGST">CGST</option>
                <option value="IGST">IGST</option>
                <option value="ST">ST</option>
              </Field>
              <Field 
                  type="number" 
                  name="expensesTaxPercentage" 
                  className={styles.inputField} 
                  placeholder="Tax Percentage"
                  onChange={(e) => handleExpensesChange(e, setFieldValue, values)}
                />

                <Field 
                  type="number" 
                  name="expensesCumulativeAmount" 
                  className={styles.inputField} 
                  placeholder="Cumulative Amount"
                  readOnly
                />

            </div>
            <ErrorMessage name="expensesTaxType" component="div" className={styles.errorMessage} />
            <ErrorMessage name="expensesTaxPercentage" component="div" className={styles.errorMessage} />
            <ErrorMessage name="expensesCumulativeAmount" component="div" className={styles.errorMessage} />

            <Field 
              type="number" 
              name="totalAmount" 
              className={styles.inputField} 
              placeholder="Total Amt. Including Exp."
              readOnly
            />
            <ErrorMessage name="totalAmount" component="div" className={styles.errorMessage} />
            <input
              type="file"
              id="addDoc"
              name="addDoc"
              accept=".pdf"
              className={styles.fileField}
              onChange={(event) => {
                setFieldValue('addDoc', event.currentTarget.files[0]);
              }}
            />
            <ErrorMessage name="addDoc" component="div" className={styles.errorMessage} />
         
          <div className={styles.BtnContainer}>
          <Tooltip title="Submit Form" disableInteractive>
            <button type="submit" className={styles.submitButton} disabled={isSubmitting}>Submit</button>
          </Tooltip>
          <Tooltip title="Cancel Form" disableInteractive>
            <button type="submit" onClick={HandleCancel} className={styles.submitButton} disabled={isSubmitting}>Cancel</button>
          </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </div>
  );
};

export default InvoicesForm;
