import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import style from "./register.module.css";
import { data } from '../../evn/evn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Validation schema for form fields
const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  mobile: Yup.string().required('Mobile is required')
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
  .required('Password is required')
  .test(
    'password-requirements',
    'Password must be at least 8 characters, contain at least one uppercase letter, one digit, and one symbol: & % $ # @ ^ _ ~',
    value => {
      const isValidLength = value && value.length >= 8;
      const hasUppercase = /(?=.*[A-Z])/.test(value);
      const hasDigit = /(?=.*\d)/.test(value);
      const hasSpecialChar = /(?=.*[&%$#@^_~])/.test(value);
      return isValidLength && hasUppercase && hasDigit && hasSpecialChar;
    }
  ),

  lawyerType: Yup.string().required('Lawyer Type is required'),
  experience: Yup.number()
    .min(0, 'Experience cannot be negative')
    .required('Experience is required'),
  age: Yup.string().required('Enrollment No is required'),
});

const lawyerTypeOptions = [
  'Criminal lawyer',
  'Environmental lawyer',
  'Family lawyer',
  'Corporate lawyer',
  'Civil lawyer',
  'Intellectual property lawyer',
  'Tax lawyer',
  'Cyber lawyer',
  'Estate planning lawyer',
  "Worker's compensation lawyer",
  'Public interest lawyer',
  'Medical malpractice lawyer',
  'Merger and acquisition lawyer',
  'Labour lawyer',
  'Bankruptcy lawyer',
  'Securities lawyer',
  'Military lawyer',
  'Contract lawyer',
  'Government lawyer',
  'Immigration lawyer',
  "Other",
];

const LogIn = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [customLawyerType, setCustomLawyerType] = useState(""); // State for custom lawyer type

  const ip = data.evn;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Use the customLawyerType state if the selected lawyerType is "Other"
    let submissionValues = {
      ...values,
      lawyerType: values.lawyerType === "Other" ? customLawyerType : values.lawyerType,
    };
  
    try {
      // Post the adjusted data to the '/register' endpoint
      const response = await axios.post(`${ip}/register`, {
        name: submissionValues.name,
        mobile: submissionValues.mobile,
        username: submissionValues.email,
        password: submissionValues.password,
        lawyerType: submissionValues.lawyerType, // This now correctly references the custom type if "Other" was selected
        experience: submissionValues.experience,
        age: submissionValues.age,
      });
      toast.success(response.data.message);
      resetForm();
    } catch (error) {
      if (!error.response) { // Assuming network error or server not reachable
        navigate('/maintenance');
      } else {
        console.error("Registration error:", error.response?.data.error || error.message);
        toast.error("Registration failed: Username or Mobile No. is already registered");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={style.midContainer}>
      <ToastContainer autoClose={5000} />
      <div className={style.midLeftContainer}>
        <img src="https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg?cs=srgb&dl=pexels-sora-shimazaki-5669602.jpg&fm=jpg" alt="logo2" />
      </div>
      <div className={style.midRightContainer}>
        <div className={style.containerhead}>
          <h1><span className={style.logospan}>LAW</span>FAX <span style={{ color: 'var(--color-accent2)' }}>AI</span>-Let's Get Started </h1>
          <p className={style.Para}>Create your account</p>
        </div>
        <Formik
          initialValues={{ name: '', mobile: '', email: '', password: '', lawyerType: '', experience: '', age: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className={style.formContainer}>
              <Field className={style.input} type="text" name="name" placeholder="Name" />
              <ErrorMessage name="name" component="div" className={style.error} />
              
              <Field as="select" className={style.input} name="lawyerType">
                <option value="" disabled>Select Lawyer Type</option>
                {lawyerTypeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Field>
              <ErrorMessage name="lawyerType" component="div" className={style.error} />

              {/* Conditional rendering for custom lawyer type input */}
              {values.lawyerType === "Other" && (
                <Field
                  className={style.input}
                  type="text"
                  name="customLawyerType"
                  placeholder="Specify Lawyer Type"
                  onChange={e => setCustomLawyerType(e.target.value)}
                />
              )}

            <Field className={style.input} type="number" name="experience" placeholder="Experience" />
            <ErrorMessage name="experience" component="div" className={style.error} />

            <Field className={style.input} type="text" name="age" placeholder="Enrollment No." />
            <ErrorMessage name="age" component="div" className={style.error} />

            <Field className={style.input} type="text" name="mobile" placeholder="Mobile No." />
            <ErrorMessage name="mobile" component="div" className={style.error} />

            <Field className={style.input} type="email" name="email" placeholder="Your email" />
            <ErrorMessage name="email" component="div" className={style.error} />

            <div className={style.inputContainer}>
            <Field
              className={`${style.input} ${style.passwordInput}`}
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
            />
            <button
              type="button"
              className={style.visibilityButton}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
            </div>
       
              <ErrorMessage name="password" component="div" className={style.error} />

              <button type="submit" className={style.submit} disabled={isSubmitting}>
                Continue
              </button>
            </Form>
          )}
        </Formik>

        {/* <small>Already have an account? <NavLink className={style.signUp} to={"/login"}>LOG IN</NavLink></small> */}
      </div>
    </div>
  );
};

export default LogIn;
