import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { data } from '../../../../evn/evn';
import styles from './NotesSection.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotesSection = () => {
  const { caseId } = useParams();
  const ip = data.evn;
  const [note, setNote] = useState('');
  const [savedNotes, setSavedNotes] = useState([]);
  // Define fetchNotes outside of useEffect to make it accessible throughout the component
  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${ip}/notes/${caseId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setSavedNotes(response.data || []);
    } catch (error) {
      toast.error('Failed to fetch notes:', error);
    }
  };

  // Call fetchNotes on component mount and when caseId changes
  useEffect(() => {
    fetchNotes();
  }, [caseId, ip]); // Make sure to include fetchNotes in the dependency array if it uses external variables/states

  const deleteNote = async (noteId) => {
    try {
      await axios.delete(`${ip}/note/${noteId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      toast.success('Note deleted successfully!');
      setSavedNotes(savedNotes.filter(note => note.id !== noteId));
    } catch (error) {
      toast.error('Failed to delete note:', error);
    }
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const saveNote = async () => {
    if (note.trim()) {
      try {
        await axios.post(`${ip}/note`, { caseId, note }, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        toast.success('Note saved successfully!');
        fetchNotes(); // Refresh the list of notes after saving
        setNote(''); // Clear the textarea
      } catch (error) {
        toast.error('Failed to save note.');
      }
    } else {
      toast.warn('Please write something before saving.');
    }
  };

  return (
    <div className={styles.notesContainer}>
      <textarea
        className={styles.notesInput}
        value={note}
        onChange={handleNoteChange}
        placeholder="Write or edit your note here..."
      />
      <button type="button" onClick={saveNote} className={styles.saveButton}>
        Save Note
      </button>
      <div className={styles.savedNotes}>
        {savedNotes.map((savedNote, index) => (
          <div key={savedNote.id} className={styles.savedNoteItem}>
            <span style={{fontWeight: "bolder", marginRight: '1rem'}}>{index + 1}. </span>
            {savedNote?.note}
            <button
              onClick={() => deleteNote(savedNote.id)}
              className={styles.deleteButton}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default NotesSection;
