import React, { useState } from 'react';
import { BsCart3, BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsMenuButtonWideFill, BsFillGearFill } from 'react-icons/bs';
import Style from './style/SideBar.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function Sidebar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <>
      {!isOpen && (
        <button className={Style.openSidebarBtn} onClick={toggleSidebar}>
          <ArrowForwardIosIcon  />
        </button>
      )}

      {isOpen && (
        <div className={Style.Sidebar}>
        <aside className={`${Style.sidebar} ${isOpen ? Style.sidebarResponsive : ""}`}>
                    <div className={Style.SidebarTitle}>
              <span className={`${Style.Icon} ${Style.CloseIcon}`} onClick={toggleSidebar}>X</span>
            </div>

            <ul className={Style.SidebarList}>
              {/* Your NavLink components */}
          <li className={Style.SidebarListItem}>
          <NavLink to="/.admin/dashboard">
            <BsGrid1X2Fill className={Style.Icon}/> Dashboard
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/newcustomerlead">
            <BsPeopleFill className={Style.icon}/>New Customers
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/contactformsub">
            <BsPeopleFill className={Style.icon}/>Contact leads
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/demoformsub">
            <BsPeopleFill className={Style.icon}/>Request Demo leads
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/generatenotifications">
            <BsListCheck className={Style.icon}/>Generate Notis
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/proxypayment">
            <BsFillArchiveFill onClick={()=>handleNavigation('')} className={Style.icon}/> Proxy Payment
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/casemanagement">
            <BsFillGrid3X3GapFill className={Style.icon}/> Case Management
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/particularcasedocuments">
            <BsListCheck className={Style.icon}/> Case Documents
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/particularcasenotes">
            <BsListCheck className={Style.icon}/> Case Notes
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/particularcasepersons">
            <BsListCheck className={Style.icon}/> Case Concern Persons
          </NavLink>
        </li>
        <li className={Style.SidebarListItem}>
          <NavLink to="/particularcaseopponents">
            <BsListCheck className={Style.icon}/> Case Opponents
          </NavLink>
        </li>
            </ul>
          </aside>
        </div>
      )}

      {isOpen && (
        <button className={Style.closeSidebarBtn} onClick={toggleSidebar}>
          <ArrowBackIosIcon />
        </button>
      )}
    </>
  );
}

export default Sidebar;
