import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './BillFormData.module.css';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import EditBillForm from './EditBillForm/EditBillForm';
import { NavLink } from 'react-router-dom';
import Modal from '../Client/People/ModelPop/Modal';
import {data} from '../../../evn/evn'
import SideNav from '../../utilities/SideNavBar/SideNav';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Tooltip } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ToastContainer, toast } from 'react-toastify';


const BillFormData = () => {
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [billData, setBillData] = useState([]);
  const [editingBill, setEditingBill] = useState(null);
  const ip = data.evn
  const [downloadingCaseId, setDownloadingCaseId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [currentPage, setCurrentPage] = useState(1);
  const [casesPerPage, setCasesPerPage] = useState(5);
  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = billData.slice(indexOfFirstCase, indexOfLastCase);
  
  // Pagination handlers
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
    //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
    //   setDashboardData([...datas]); // Update local state to trigger re-render
    };

    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);

  const fetchBillData = async () => {
    try {
      const response = await axios.get(`${ip}/bill/edit`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      setBillData(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    // Fetch bill data from the backend when the component mounts
    fetchBillData();
  }, []);

  const handleDeleteClick = async (billId) => {
    if (window.confirm('Are you sure you want to delete this bill?')) {
      try {
        await axios.delete(`${ip}/billdata/${billId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        fetchBillData(); // Refetch the bill data to update the UI
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleEditClick = (bill) => {
    setEditingBill(bill.id);
    openModal();
  };

  const handleCancelClick = () => {
    setEditingBill(null);
    closeModal();
  };

  const handleDownloadClick = async (billId) => {
    setDownloadingCaseId(billId)
    try {
      const response = await axios.get(`${ip}/billdata/download-pdf/${billId}`, {
        responseType: 'blob',
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Bill_${billId}.pdf`;
      a.click();
      setDownloadingCaseId(null)
    } catch (error) {
      setDownloadingCaseId(null)
      toast.error(error);
    }
  };

  return (
    <>
    <div>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
      <div className={style.container}>
        <h2 className={style.heading}>BILL STACK</h2>
        <div className={style.scrolable}>
        <table className={style.table}>
          <thead className={style.tableHead}>
            <tr>
            <th>Title</th>
              <th className={style.titleColumn}>Bill Number</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Amount</th>
              <th>Gross Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className={style.tableBody}>
            {currentCases.map((bill) => (
              <tr key={bill.id}>
                <td className={style.titleColumn1}>{bill.title}</td>
                <td className={style.titleColumn}>BIL-{bill.billNumber}</td>
                <td className={style.td}>{bill.dateFrom}</td>
                <td className={style.td}>{bill.dateTo}</td>
                <td className={style.td}>{bill.amount}</td>
                <td className={style.td}>{bill.totalAmountWithTax}</td>
                <td>
                <Tooltip title="Edit" disableInteractive>
                <button className={style.btn} onClick={() => handleEditClick(bill)}>
                <BorderColorIcon />
              </button>
              </Tooltip>
              
               <Tooltip title="Delete" disableInteractive>
                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDeleteClick(bill.id)}
                  >
                    <DeleteIcon />
                  </button>
                  </Tooltip>

                  <Tooltip title="Edit" disableInteractive>

                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDownloadClick(bill.id)}
                  >
                   <DownloadForOfflineIcon />
                   {downloadingCaseId === bill.id && <CircularProgress size="1.5rem" color="inherit" />} 

                  </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
          <ToastContainer/>
          {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(billData.length / casesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>



          {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
             <EditBillForm onClose={closeModal}/>
          </Modal> */}
          <Modal isOpen={isModalOpen} onClose={closeModal}>
          {/* Pass the selected case data to EditCaseForm */}
          {editingBill && (
            <EditBillForm
            onClose={closeModal}
              billData={billData.find((bill) => bill.id === editingBill)}
              onCancel={handleCancelClick}
            />
          )}
        </Modal>


        </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default BillFormData;
