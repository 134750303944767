import React from "react";
import { Link } from "react-router-dom";
import style from "./footer.module.css";
import { AiFillLinkedin, AiFillFacebook, AiFillInstagram, AiFillYoutube, AiOutlinePhone, AiOutlineWhatsApp } from "react-icons/ai";
import logo from '../../../assets/MainLogo.png' 

const footer = () => {
  const phoneNumber = "9056869267";
  return (
    <footer className={style.MainContainer}>

    <div className={style.LogoContainer}>
    <div className="logo-and-tagline-container">
    <Link to={"/"} className={style.logo}>
      {/* <img className={style.logo1} src={logo} alt="Logo" /> */}
      <h2><span className={style.LogoSpan}>LAW</span>FAX<span className={style.LogoSpan}> AI</span></h2>
    </Link>
    <span className={style.taglines}>CRAFTING THE FUTURE OF LAW WITH AI</span>
    </div>
    </div>

    <div className={style.FooterCopyContainer}>
    <small className={style.footerCopy}>
      2023 Law<span className={style.footerCopy}>faxai</span> &copy; All
      right reserved
    </small>
    </div>

    <div className={style.footerSocial}>
      <a className={style.LinkedIn} href="https://www.linkedin.com/showcase/lawfax-ai/about/?viewAsMember=true" target="_blank">
        <AiFillLinkedin />
      </a>
      
      <a className={style.FaceBook} href="http://facebook.com" target="_blank">
        <AiFillFacebook />
      </a>
      <a className={style.InstaGram} href="http://instagram.com" target="_blank">
        <AiFillInstagram />
      </a>
      <a className={style.YouTuber} href="http://youtube.com" target="_blank">
        <AiFillYoutube />
      </a>
      <a className={style.CallIcon} href={`tel:+91${phoneNumber}`} target="_blank">
          <AiOutlinePhone />
        </a>
        <a className={style.WhatsAppIcon} href={`https://wa.me/+91${phoneNumber}`} target="_blank">
          <AiOutlineWhatsApp />
        </a>
    </div>
  </footer>
  
  );
};

export default footer;
