export const service = [
    {   
        id:'1',
        title:'INSTANT LEGAL ASSISTANCE',
        info:'Get quick access to qualified lawyers specialized in various fields of Law, ensuring that legal queries and concerns are addressed promptly with Lawfax AI.'
    },
    {
        id:'2',
        title:'LEGAL DOCUMENT GENERATION',
        info:"Effortlessly generate a variety of legal documents as per the specification asked for, thus saving advocates time using Lawfax AI."
    },
    {
        id:'3',
        title:'ALERTS AND NOTIFICATIONS',
        info:"Stay informed and updated with alerts and notifications on upcoming appointments, case updates, case hearings, and more through Lawfax AI."
    },
    {
        id:'4',
        title:'DOCUMENT CONVERSION',
        info:"Convert legal documents into various languages, enhancing accessibility and convenience for both lawyers and clients with Lawfax AI."
    },
    {
        id:'5',
        title:'CLIENT DETAILS AND HISTORY',
        info:'Efficiently manage the litigation process by having access to client details and history. Foster collaboration, delegate tasks, and coordinate efforts seamlessly within your legal practice using Lawfax AI.'
    },
    {
        id:'6',
        title:'TEAM MEMBER DETAILS',
        info:'Get quick access to qualified lawyers specialized in various fields of Law, ensuring that legal queries and concerns are addressed promptly with Lawfax AI.'
    },
    {
        id:'7',
        title:'PROXY GENERATION',
        info:"Simplify proxy creation for authorized representation. Generate accurate and compliant proxies to facilitate litigation proceedings on behalf of clients with ease using Lawfax AI."
    },
    {
        id:'8',
        title:'BILLING AND INVOICING',
        info:'Streamline the billing process, create invoices, and manage payments effortlessly. Ensure transparency and accuracy in financial transactions for both your practice and your clients with Lawfax AI.'
    },
    {
        id:'9',
        title:'CENTRALIZED CASE REPOSITORY',
        info:'Effortlessly store and organize all case-related information in a centralized repository with Lawfax AI. It will be accessible anytime, anywhere, ensuring that the lawyer has all the essential case details at their fingertips.'
    },
    {
        id:'10',
        title:'DOCUMENT MANAGEMENT',
        info:'Manage and organize litigation documents securely within Lawfax AI. Lawyers can easily upload, categorize, and retrieve important documents associated with each case for a well-organized and efficient workflow.'
    },
    {
        id:'11',
        title:'CALENDAR INTEGRATION',
        info:'Sync the case-related deadlines, hearings, and meetings with your calendar for seamless scheduling and planning. Coordinate your work effectively and efficiently with Lawfax AI.'
    },
    {
        id:'12',
        title:'CASE NOTES AND ANNOTATIONS',
        info:'Take case-specific notes and make annotations directly within Lawfax AI. Keep important insights and observations in one place for easy reference during litigation discussions or hearings.'
    },
]
