import React, { useState, useEffect } from 'react';
import RightSidebar from '../RightSidebar';
import MyChats from '../MyChats';
import SingleChat from '../SingleChat';
import style from './ChatPage.module.css';
import axios from 'axios';
import {data} from '../../../evn/evn'
import { NavLink , useNavigate } from 'react-router-dom';

const Chatpage = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false); // false shows the chats list, true shows the single chat
  const ip = data.evn
  const navigate = useNavigate();
  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setIsChatVisible(true); // Show the single chat on selection
  };

  // Function to go back to the chat list
  const handleBackToChats = () => {
    setIsChatVisible(false); // Hide the single chat
  };
  useEffect(() => {
    checkSubscriptionStatus();
  });
  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };
  return (
    <div className={style.container}>
    {/* Sidebar for searching new users */}
      <div className={style.rightSidebar}>
        <RightSidebar onUserSelect={handleChatSelect} />
      </div>

      {/* Toggle visibility based on isChatVisible and screen size */}
      <div className={`${style.myChats} ${isChatVisible ? style.hideOnMobile : ''}`}>
        <MyChats onChatSelect={handleChatSelect} selectedUser={selectedChat} />
      </div>

      {/* Chatbox for communicating to other users */}
      <div className={`${style.singleChat} ${!isChatVisible ? style.hideOnMobile : ''}`}>
        <SingleChat  selectedChat={selectedChat} onBack={handleBackToChats} />
      </div>
    </div>
  );
};

export default Chatpage;
