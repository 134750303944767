import React, { useEffect, useState } from "react";
// import style from "./CaseFormData.module.css";
import style from "./ConcernPersonTable.module.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {data} from '../../../../../evn/evn'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';



const PeopleTable = () => {
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [casesData, setCasesData] = useState([]);
  const [editingCase, setEditingCase] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    caseCode: "",
    honorableJudge: "",
    client: "",
    opponentPartyName: "",
  });
  const ip = data.evn
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage, setinvoicesPerPage] = useState(5);
  const [invoicesData, setInvoicesData] = useState([]);



  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentTnvoices = invoicesData.slice(indexOfFirstInvoice, indexOfLastInvoice);
  
  // Pagination handlers
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchCasesData();
  }, []);
  
  const fetchCasesData = async () => {
    try {
      const response = await axios.get(`http://${ip}/edit/caseform`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      const data = response.data;
      setCasesData(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEditClick = (caseItem) => {
    setEditingCase(caseItem.id);
    openModal();
  };

  const handleCancelClick = () => {
    setEditingCase(null);
    closeModal();
  };


  const handleDeleteClick = async (caseId) => {
    if (window.confirm('Are you sure you want to delete this client?')) {
      try {
        await axios.delete(
          `http://${ip}/dashboard/caseformdata/${caseId}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        );
        fetchCasesData();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleDownloadClick = async (caseId) => {
    try {
      const response = await axios.get(
        `http://${ip}/dashboard/caseformdata/download-pdf/${caseId}`,
        {
          responseType: "blob",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Case_${caseId}.pdf`;
      a.click();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className={style.container}>
      <h2 className={style.heading}>Cases Form Data</h2>
      <table className={style.table}>
      <thead className={style.tableHead}>
          <tr>
            <th>SNo.</th>
            <th>FullName</th>
            <th>Email</th>
            <th>Mobile No.</th>
            <th>Assign Alerts</th>
            <th>Schedule Appointment</th>
            <th>Actions</th>
          </tr>
        </thead>
        
        <tbody className={style.tableBody}>

          {casesData.map((caseItem, index) => (
            <tr className={style.CaseInfo} key={caseItem.id}>
            <td>{index + 1}</td> {/* Added serial number */}
              <td>{caseItem.caseCode}</td>
              <td>{caseItem.client}</td>
              <td>{caseItem.honorableJudge}</td>
              <td>{caseItem.opponentPartyName}</td>
              <td>
                <button className={style.btn}  onClick={() => handleDeleteClick(caseItem.id)}>Delete</button>
              </td>
              <ToastContainer/>
            </tr>
          ))}
         
        </tbody>
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(invoicesData.length / invoicesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
      </table>

      
    </div>
  </>
  );
};

export default PeopleTable;
