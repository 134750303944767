import React from "react";
import style from "./about.module.css";
import Headers from "../../component/utilities/Header/Headers";
import Footer from "../../component/utilities/footer/footer";
import headerImage from "../../assets/headersrrr.jpg";
import {data} from '../../evn/evn'

const About = () => {
  const ip = data.evn
  const backgroundImageUrl = `${ip}/about1.webp`;
  const backgroundImageUrl2 = `${ip}/vision.jpg`;
  const backgroundImageUrl3 = `${ip}/mission.jpg`;
  const headerImage = `${ip}/headersrrr.jpg`;

  return (
<>
      <Headers title="ABOUT US" image={headerImage} />
      
      {/* About Us Section */}
      <section className={style.section}>
        <div className={style.container}>
          <div className={style.imageContainer}>
            <img src={backgroundImageUrl} alt="about story" />
          </div>
          <div className={style.textContainer}>
            <h2 className={style.title}>ABOUT US</h2>
            <p className={style.text}>
    LAWFAX AI – A gateway to seamless legal assistance and expert
    guidance. Lawfax AI provides a cutting-edge legal solution to 
    streamline <strong>lawyers</strong>' practice and enhance their client service 
    experience. It’s a specialized software meticulously crafted to 
    revolutionize the way <strong>advocates</strong> handle <strong>cases</strong> and manage <strong>litigation</strong>. 
    Our software stands as a comprehensive digital tool, uniquely designed 
    to cater to the dynamic needs of legal professionals, making it the  
    <strong>best software for advocates in India</strong>.
</p>
<p className={style.text}>
    With a sophisticated blend of innovative features, seamless <strong>case</strong> management,
    and streamlined communication, our application serves as the quintessential
    partner for every <strong>advocate</strong>. Embrace a future where technology empowers 
    legal excellence, where every tap and swipe leads to enhanced advocacy 
    and a more just world. Lawfax AI is the <strong>best software for advocates</strong>, 
    providing unparalleled support in managing <strong>litigation</strong> and <strong>cases</strong>.
</p>
<p className={style.text}>
    Our <strong>litigation management system</strong> is the <strong>best software for advocates in India</strong>,
    offering unmatched capabilities in handling <strong>cases</strong> and supporting every facet
    of <strong>litigation</strong>. Lawfax AI is dedicated to providing <strong>lawyers</strong> with the <strong>best 
    software for advocates</strong>, ensuring that each <strong>case</strong> is managed with precision 
    and efficiency. Welcome to the future of legal practice, welcome to LAWFAX AI.
</p>
<p className={style.text}>
    Lawfax AI goes beyond traditional legal software by incorporating advanced 
    features tailored for <strong>litigation management</strong> and <strong>case</strong> handling. <strong>Advocates</strong> can
    effortlessly manage their <strong>litigation</strong> workload, streamline communication with
    clients, and access the <strong>best tools available for legal professionals in 
    India</strong>. Our commitment to innovation makes Lawfax AI the ultimate choice 
    for <strong>lawyers</strong> seeking to elevate their practice and provide exceptional 
    service to their clients.
</p>

            
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className={style.section}>
        <div className={style.containerReverse}>
          <div className={style.textContainer}>
            <h2 className={style.title}>OUR VISION</h2>
            <p className={style.text}>
    At Lawfax AI, our vision is to revolutionize the legal industry by harnessing 
    advanced artificial intelligence and machine learning technologies to create an
    all-encompassing platform tailored specifically to the needs of legal professionals.
    Our <strong>litigation management system</strong> is designed to streamline and automate various 
    aspects of legal practice, enabling <strong>lawyers</strong> to focus on strategic and analytical
    tasks rather than repetitive ones. Our platform integrates a wide range of features,
    including <strong>case</strong> management, document generation, legal research, and client communication
    tools, providing a cohesive and seamless user experience for every <strong>advocate</strong>.
</p>
<p className={style.text}>
    Understanding that each legal practice is unique, we offer customizable solutions to
    meet the specific requirements of different law firms and individual practitioners.
    Lawfax AI stands as the <strong>best software for advocates in India</strong>, ensuring that <strong>litigation</strong>
    and <strong>case</strong> management are handled with utmost efficiency. We envision a future where 
    innovation is seamlessly integrated into everyday legal practice, making advanced 
    technology accessible and easy to use for all legal professionals. Our commitment 
    is to empower <strong>advocates</strong> to exceed client expectations and make a lasting impact on 
    the legal landscape through the <strong>best litigation management system</strong> available.
</p>

          </div>
          <div className={style.imageContainer}>
            <img src={backgroundImageUrl2} alt="Our Vision" />
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className={style.section}>
        <div className={style.container}>
          <div className={style.imageContainer}>
            <img src={backgroundImageUrl3} alt="Our Mission" />
          </div>
          <div className={style.textContainer}>
            <h2 className={style.title}>OUR MISSION</h2>
            <p className={style.text}>
    At Lawfax AI, our mission is to empower <strong>lawyers</strong> by providing them with
     a cutting-edge tool designed to enhance their productivity, efficiency, and overall 
     effectiveness in navigating the complexities of the legal landscape. We are committed 
     to delivering an innovative platform that caters specifically to the unique needs of 
     legal professionals, offering features that streamline their workflow and reduce 
     administrative burdens. Our <strong>litigation management system</strong> is designed 
     to simplify legal processes, improve accuracy, and facilitate better decision-making. 
     By leveraging advanced technology, we aim to streamline <strong>case</strong> management
      and support <strong>advocates</strong> in handling <strong>litigation</strong> more 
      efficiently. Our ultimate goal is to enable <strong>lawyers</strong> to achieve greater
       heights of success in their practice, ensuring they can focus on delivering exceptional
        service to their clients and making a meaningful impact in the legal field. Lawfax AI
         stands out as the <strong>best software for advocates in India</strong>, dedicated 
         to revolutionizing the legal industry.
</p>


          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
};

export default About;
