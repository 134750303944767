import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import style from './login.module.css'; 
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {data} from "../../evn/evn" 
import { ToastContainer, toast } from 'react-toastify';

const adminLoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const ip = data.evn

const AdminLogIn = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleAdminLogin = async (values, actions) => {
    try {
      const response = await axios.post(`${ip}/admin/login`, {
        username: values.username,
        password: values.password,
      });
      const { token } = response.data; 
  
      
      localStorage.setItem('adminToken', token);
  
      toast.success("Admin Logged in Successfully", {
        onClose: () => {
          // Redirect after showing toast
          navigate('/.admin/dashboard'); 
        },
        autoClose: 1000,
      });
    } catch (error) {
      // Error handling
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.error('Admin Login failed:', errorMessage);
      actions.setFieldError('general', 'Login failed: ' + errorMessage);
      toast.error('Login failed: ' + errorMessage);
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={style.midContainer}>
      <ToastContainer autoClose={5000} />
      <div className={style.midLeftContainer}>
      <img src="https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg?cs=srgb&dl=pexels-sora-shimazaki-5669602.jpg&fm=jpg" alt="logo2" />
      </div>
      <div className={style.midRightContainer}>
        <div className="container-head">
          <h2><span className="logo-span">Admin</span> Login</h2>
          <p className={style.Para}>Log in to your admin account</p>
        </div>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={adminLoginSchema}
          onSubmit={handleAdminLogin}
        >
          {({ isSubmitting }) => (
            <Form className={style.formContainer}>
              <Field
                className={style.input}
                type="text"
                name="username"
                placeholder="Username"
              />
              <ErrorMessage name="username" component="div" className={style.error} />
              <div className={style.inputContainer}>
                <Field
                  className={style.input}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                />
                <button
                  type="button"
                  className={style.visibilityButton}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>
              <ErrorMessage name="password" component="div" className={style.error} />
              <button className={style.submit} type="submit" disabled={isSubmitting}>
                Log In
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogIn;
