import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './InvoicesFormData.module.css';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import Modal from '../Client/People/ModelPop/Modal';
import EditInvoicesForm from './EditInvoiceForm/EditInvoice';
import { NavLink } from 'react-router-dom';
import SideNav from '../../utilities/SideNavBar/SideNav';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Tooltip } from "@mui/material";
import {data} from '../../../evn/evn'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const InvoicesFormData = () => {
  const [invoicesData, setInvoicesData] = useState([]);
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [downloadingCaseId, setDownloadingCaseId] = useState(null);
  const ip = data.evn;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage, setinvoicesPerPage] = useState(5);
  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentTnvoices = invoicesData.slice(indexOfFirstInvoice, indexOfLastInvoice);
  
  // Pagination handlers
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
    //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
    //   setDashboardData([...datas]); // Update local state to trigger re-render
    };
    fetchData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchInvoicesData = async () => {
    try {
      const response = await axios.get(`${ip}/invoiceform/edit`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      setInvoicesData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch invoice data from the backend when the component mounts
    fetchInvoicesData();
  }, []);

  const handleEditClick = (invoice) => {
    setEditingInvoice(invoice.id);
    openModal();
  };

  const handleCancelClick = () => {
    setEditingInvoice(null);
    closeModal();
  };

  const handleDeleteClick = async (invoiceId) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      try {
        await axios.delete(`${ip}/invoiceformdata/${invoiceId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        // Refetch the invoices to update the UI
        fetchInvoicesData();
      } catch (error) {
        console.error(error);
      }
    }
    // console.log('Delete button clicked with invoiceId:', invoiceId);
  };

  const handleDownloadClick = async (invoiceId) => {
    setDownloadingCaseId(invoiceId)
    try {
      const response = await axios.get(`${ip}/invoiceformdata/download-pdf/${invoiceId}`, {
        responseType: 'blob',
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Invoice_${invoiceId}.pdf`;
      a.click();
      setDownloadingCaseId(null);
    } catch (error) {
      setDownloadingCaseId(null);
      console.error(error);
    }
  };

  return (
    <>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
      <div className={style.container}>
        <h2 className={style.heading}>INVOICE STACK</h2>
        <table className={style.table}>
        <div className={style.scrolable}>
          <thead className={style.tableHead}>
            <tr >
              <th className={style.titleColumn}>Title</th>
              <th >Invoice Number</th>
              <th >Client</th>
              <th >Date</th>
              <th >Gross Expenses Amount</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody className={style.tableBody}>
            {currentTnvoices.map((invoice) => (
              <tr key={invoice.id} >
                <td className={style.td}>{invoice.title}</td>
                <td className={style.td}>INV-{invoice.invoiceNumber}</td>
                <td className={style.td}>{invoice.client}</td>
                <td className={style.td}>{invoice.date}</td>
                <td className={style.td}>{invoice.totalAmount}</td>
                <td className={style.td}>
                <Tooltip title="Edit" disableInteractive>
                    <button className={style.btn} onClick={() => handleEditClick(invoice)}>
                <BorderColorIcon />
                   </button>
                </Tooltip>
                <Tooltip title="Delete" disableInteractive>
                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDeleteClick(invoice.id)}
                  >
                    <DeleteIcon />
                  </button>
                  </Tooltip>
                  <Tooltip title="Download PDF" disableInteractive>
                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDownloadClick(invoice.id)}
                  > 
                    <DownloadForOfflineIcon /> 
                    {downloadingCaseId === invoice.id && <CircularProgress size='1.5rem' color="inherit" />} 
                  </button>
                </Tooltip>
                </td>
              </tr>
            ))}
           
            <Modal isOpen={isModalOpen} onClose={handleCancelClick}>
          {/* Pass the selected case data to EditCaseForm */}
          {editingInvoice && (
            <EditInvoicesForm
              onClose={closeModal}
              invoiceData={invoicesData.find((invoice) => invoice.id === editingInvoice)}
              onCancel={handleCancelClick}
            />
          )}
        </Modal>
          </tbody>

           {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>

          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(invoicesData.length / invoicesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
        </div>
        </table>
      </div>
    </>
  );
};

export default InvoicesFormData;
