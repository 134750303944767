import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import style from './CaseTitieData.module.css';
import axios from 'axios';
import {data} from '../../../../evn/evn';
import { ToastContainer, toast } from 'react-toastify';


const CaseTitleData = () => {
  const [casesData, setCasesData] = useState([]);
  const { caseId } = useParams();

  const [formData, setFormData] = useState({
    fileNumber: '1', // This is a system generated number and can't be modified.
    courtType: 'High Court',
    highCourt: 'Bombay High Court',
    bench: 'Nagpur',
    side: 'Civil',
    stampRegister: 'Register',
    affidavitFiled: 'No',
    caseType: 'Civil Application in WP',
    caseNumber: '312',
    year: '2024'
  });

  const ip = data.evn
  
  useEffect(() => {
    fetchCasesData();
  }, []);
  
  const fetchCasesData = async () => {
    try {
      const response = await axios.get(`${ip}/edit/updatecases/${caseId}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      const data = response.data;
      setCasesData(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Add a submit handler if needed
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <>
      <table className={style.FormTable}>
        <tbody className={style.Tbody}>
          {/* First Section */}
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>FILE#</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.fil_no}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>CNR Number</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.cino}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Court</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.court_type}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>State Code:</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.state_code}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>State/High Court</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.state_name}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>District/Bench</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.district_name}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>District Code</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.district_code}</td>
          </tr>
          
          {/* Additional rows for the first section */}

          {/* Visual Gap */}
          <tr className={style.Gap}><td colSpan="2"></td></tr>

          {/* Second Section */}
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Case Type</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.type_name}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Case Number</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.case_no}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Filing Year</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.fil_year}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Registration No.</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.reg_no}</td>
          </tr>
          {/* Additional rows for the second section */}
          {/* Visual Gap */}
          <tr className={style.Gap}><td colSpan="2"></td></tr>

          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Petetioner</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.petparty_name}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Respondent</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.resparty_name}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Last Hearing Date</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.date_last_list}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Upcoming Hearing</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.date_next_list}</td>
          </tr>
          <tr className={style.Tr}>
            <td className={`${style.Td} ${style.TdLabel}`}>Date Of Decision</td>
            <td className={`${style.Td} ${style.TdData}`}>{casesData.date_of_decision}</td>
          </tr>

        </tbody>
      </table>
    </>

  );
};
export default CaseTitleData