import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import { useNavigate } from 'react-router-dom';
import styles from './CourtHearingManagement.module.css'; // Adjust the path as needed

const CourtHearingManagement = () => {
  const [courtHearings, setCourtHearings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourtHearings = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/court-hearings`, {
          headers: { 'x-auth-token': token },
        });
        setCourtHearings(response.data.courtHearings);
        setSearchResults(response.data.courtHearings);
      } catch (error) {
        console.error("There was an error fetching the court hearings:", error);
      }
    };

    fetchCourtHearings();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(courtHearings);
    } else {
      const filteredCourtHearings = courtHearings.filter(hearing =>
        searchType === 'id' ? hearing.id.toString().includes(event.target.value)
                            : hearing.user_id.toString().includes(event.target.value));
      setSearchResults(filteredCourtHearings);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(courtHearings);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchHearings = courtHearings.filter(hearing => {
        if (searchType === 'id') return hearing.id.toString() === searchTerm;
        return hearing.user_id.toString() === searchTerm;
      });
      setSearchResults(exactMatchHearings);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Title</th>
              <th className={styles.tableHeader}>Assigned Lawyer</th>
              <th className={styles.tableHeader}>Status</th>
              <th className={styles.tableHeader}>Case Title</th>
              <th className={styles.tableHeader}>Hearing Date</th>
              <th className={styles.tableHeader}>Start Time</th>
              <th className={styles.tableHeader}>End Time</th>
              
            </tr>
          </thead>
          <tbody>
            {searchResults.map(hearing => (
              <tr key={hearing.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{hearing.id}</td>
                <td className={styles.tableCell}>{hearing.user_id}</td>
                <td className={styles.tableCell}>{hearing.title}</td>
                <td className={styles.tableCell}>{hearing.assignedLawyer}</td>
                <td className={styles.tableCell}>{hearing.status}</td>
                <td className={styles.tableCell}>{hearing.caseTitle}</td>
                <td className={styles.tableCell}>{hearing.hearingDate}</td>
                <td className={styles.tableCell}>{hearing.startTime}</td>
                <td className={styles.tableCell}>{hearing.endTime}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};


export default CourtHearingManagement;