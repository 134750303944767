import React from "react";
import style from "./values.module.css"; // Ensure the path is correct
import { value } from "./data"; // Your data source for the values
import Card from "../../component/utilities/card/Card"; // Ensure the path is correct
import Image from "../../assets/value.jpg"; // Ensure the path is correct
import Benifits from "../Home/benifits/Benifits"; // Ensure the path is correct
import { FaCrown } from "react-icons/fa";
import Carousel from "react-multi-carousel";

const Values = () => {

  
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
  return (
    <div className="partners">
    {/* <h2 className="headi ng">Partnered<span className="why-highlight"> With</span></h2> */}
    <h2> <Benifits className={style.main1} icons={<FaCrown />} title="Values" /></h2>
    <Carousel 
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      infinite={true}
      swipeable={true}
      draggable={false}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
    {value.map(({ id, icon, title, info1 }) => {
        return (
          <Card className={style.programsProgram} key={id}>
            <div className={style.BenifitsTitle}>
            <span>{icon}</span>
            <h4 className={style.Title}>{title}</h4>
            </div>
            <p className={style.info}>{info1}</p>
          </Card>
        );
      })}
   
    </Carousel>
  </div>
  );
};

export default Values;
