import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import styles from './ClientManagement.module.css'; // Use the same CSS module for styling

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('clientId');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      const ip = data.evn; // Make sure this value is correctly set in your env file
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/clients`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setClients(response.data.clients); // Adjust according to your response structure
        setSearchResults(response.data.clients);
      } catch (error) {
        console.error("There was an error fetching the clients:", error);
      }
    };

    fetchClients();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(clients);
    } else {
      const filteredClients = clients.filter(client =>
        searchType === 'clientId'
          ? client.id.toString().includes(event.target.value)
          : client.user_id.toString().includes(event.target.value)
      );
      setSearchResults(filteredClients);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reset search term
    setSearchResults(clients); // Show all clients
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchClients = clients.filter(client =>
        searchType === 'clientId'
          ? client.id.toString() === searchTerm
          : client.user_id.toString() === searchTerm
      );
      setSearchResults(exactMatchClients);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="clientId">Search by People ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType === 'clientId' ? 'People ID' : 'User ID'}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
        <thead>
  <tr className={styles.tableRow}>
    <th className={styles.tableHeader}>People ID</th>
    <th className={styles.tableHeader}>User ID</th>
    <th className={styles.tableHeader}>First Name</th>
    <th className={styles.tableHeader}>Last Name</th>
    <th className={styles.tableHeader}>Email</th>
    <th className={styles.tableHeader}>Mobile No</th>
    <th className={styles.tableHeader}>Alt Mobile No</th>
    <th className={styles.tableHeader}>Organization Name</th>
    <th className={styles.tableHeader}>Organization Type</th>
    <th className={styles.tableHeader}>Website</th>
    <th className={styles.tableHeader}>Case Title</th>
    <th className={styles.tableHeader}>Type</th>
    <th className={styles.tableHeader}>Home Address</th>
    <th className={styles.tableHeader}>Office Address</th>
    <th className={styles.tableHeader}>Assign Alerts</th>
    
    <th className={styles.tableHeader}>Assign Appointments</th>
  </tr>
</thead>
<tbody>
  {searchResults.map((client) => (
    <tr key={client.id} className={styles.tableRow}>
      <td className={styles.tableCell}>{client.id}</td>
      <td className={styles.tableCell}>{client.user_id}</td>
      <td className={styles.tableCell}>{client.firstName}</td>
      <td className={styles.tableCell}>{client.lastName}</td>
      <td className={styles.tableCell}>{client.email}</td>
      <td className={styles.tableCell}>{client.mobileNo}</td>
      <td className={styles.tableCell}>{client.alternateMobileNo}</td>
      <td className={styles.tableCell}>{client.organizationName}</td>
      <td className={styles.tableCell}>{client.organizationType}</td>
      <td className={styles.tableCell}>{client.organizationWebsite}</td>
      <td className={styles.tableCell}>{client.caseTitle}</td>
      <td className={styles.tableCell}>{client.type}</td>
      <td className={styles.tableCell}>{client.homeAddress}</td>
      <td className={styles.tableCell}>{client.officeAddress}</td>
      <td className={styles.tableCell}>{client.assignAlerts}</td>
     
      <td className={styles.tableCell}>{client.assignAppointments}</td>
    </tr>
  ))}
</tbody>

        </table>
      </div>
    </>
  );
};

export default ClientManagement;
