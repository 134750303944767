  import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './AlertsFormData.module.css';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import { NavLink } from 'react-router-dom';
import Modal from '../Client/People/ModelPop/Modal';
import EditTasksForm from './EditTasksForm/EditTasksForm';
import {data} from '../../../evn/evn'
import SideNav from '../../utilities/SideNavBar/SideNav';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Tooltip } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ToastContainer, toast } from 'react-toastify';


const AlertsFormData = () => {
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [alertsData, setAlertsData] = useState([]);
  const [editingAlert, setEditingAlert] = useState(null);
  const ip = data.evn
  const [downloadingCaseId, setDownloadingCaseId] = useState(null);
const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
const [currentPage, setCurrentPage] = useState(1);
const [casesPerPage, setCasesPerPage] = useState(5);

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = alertsData.slice(indexOfFirstCase, indexOfLastCase);

// Pagination handlers
const handleNextPage = () => {
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage(currentPage - 1);
};

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchAlertsData = async () => {
    try {
      const response = await axios.get(`${ip}/alerts/edit`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
        },
      });

      setAlertsData(response.data); // Set the alerts data received from the backend
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    // Fetch alerts data from the backend when the component mounts
    fetchAlertsData();
  }, []);

  const handleEditClick = (alert) => {
    setEditingAlert(alert.id); // Pass the entire alert object
    openModal();
  };
  

  const handleCancelClick = () => {
    setEditingAlert(null);
    closeModal();
  };

  const handleDeleteClick = async (alertId) => {
    if (window.confirm('Are you sure you want to delete this alert?')) {
      try {
        await axios.delete(`${ip}/alerts/${alertId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        fetchAlertsData(); // Refetch the alerts to update the UI
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleDownloadClick = async (alertId) => {
    setDownloadingCaseId(alertId)
    try {
      // Make an HTTP GET request to download the PDF for the specified alert ID
      const response = await axios.get(`${ip}/alerts/download-pdf/${alertId}`, {
        responseType: 'blob', // Set responseType to 'blob' to receive binary data
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      // Create a URL for the blob data
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `Alert_${alertId}.pdf`;
      a.click();
      setDownloadingCaseId(null)
    } catch (error) {
      setDownloadingCaseId(null)
      toast.error(error);
    }
  };

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
        
      <div className={style.container}>
      <h2 className={style.heading}>TASK STACK</h2>
        <table className={style.table}>
          <thead className={style.tableHead}>
            <tr>
              <th className={style.th}>Title</th>
              <th className={style.th}>Start Date</th>
              <th className={style.th}>Completion Date</th>
              <th className={style.th}>Assign To</th>
              <th className={style.th}>Actions</th>
            </tr>
          </thead>
          <tbody className={style.tableBody}>
            {currentCases.map((alert) => (
              <tr key={alert.id}>
                <td className={style.titleColumn2}>{alert.title}</td>
                <td className={style.td}>{alert.startDate}</td>
                <td className={style.td}>{alert.completionDate}</td>
                <td className={style.td}>{alert.assignTo}</td>
                <td>
                <Tooltip title="Edit" disableInteractive>

                 <button className={style.btn} onClick={() => handleEditClick(alert)}>
                 <BorderColorIcon />
                </button>
                </Tooltip>
                <Tooltip title="Delete" disableInteractive>

                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDeleteClick(alert.id)}
                  >
                    <DeleteIcon />
                  </button>
                 </Tooltip>

                <Tooltip title="Download PDF" disableInteractive>
                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDownloadClick(alert.id)}
                  >
                    <DownloadForOfflineIcon /> 
                    {downloadingCaseId === alert.id && <CircularProgress size="1.1rem" color="inherit" />} 

                  </button>
                  </Tooltip>

                </td>
              </tr>
            ))}
          </tbody>
            <ToastContainer/>
           {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
            </Button>
    
            <span className={style.currentPageIndicator}>
              {currentPage}
            </span>
    
            <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(alertsData.length / casesPerPage)} className={style.nextButton}>
              <span className={style.next}>Next {currentPage + 1}</span>
              <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
            </Button>
        </div>
    




          <Modal isOpen={isModalOpen} onClose={handleCancelClick}>
  {/* Pass the selected case data to EditTasksForm */}
  {editingAlert && (
    <EditTasksForm
      alertData={alertsData.find((alert) => alert.id === editingAlert)}
      onCancel={handleCancelClick}
    />
  )}
</Modal>
        </table>
      </div>
    </>
  );
};

export default AlertsFormData;