import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {data} from '../../evn/evn'
import styles from './ProxyPayment.module.css'
import Sidebar from '../AdminComponents/SideBar';
import axios from 'axios';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const ProxyPayment = () => {
    const ip = data.evn
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProxies, setFilteredProxies] = useState([]);
    const [searchType, setSearchType] = useState('id'); 
    const [proxies, setProxies] = useState([]);
   
    const navigate = useNavigate();

      // Function to handle fee conversion
      const convertFee = (fee) => {
        // For example, converting fee by 95%
        return fee * 0.95;
      };
    
      // Function to handle payment status update
      const updatePaymentStatus = (proxyId, newStatus) => {
        // Logic to update payment status
      };
    
      // Function to handle action button click
      const handleActionButtonClick = (proxyId, actionType) => {
        // Determine action based on actionType and perform it
      };
      useEffect(() => {
        const fetchProxies = async () => {
            const token = localStorage.getItem('adminToken');
            if (!token) {
              navigate('/.admin');
              return false;
          }
            

          try {
            const response = await axios.get(`${ip}/admin/proxy-payments`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            setProxies(response.data.proxies); // Adjust based on your response structure
            setFilteredProxies(response.data.proxies); // Initialize filtered proxies
        } catch (error) {
            console.error("There was an error fetching the proxy payments:", error);
        }
    };

    fetchProxies();
}, [navigate]);


const handleSearchChange = (event) => {
  const value = event.target.value;
  setSearchTerm(value);

  const lowerValue = value.toLowerCase();
  const filtered = proxies.filter(proxy => {
      const targetValue = searchType === 'id' ? proxy.id.toString() : proxy.dateOfHearing;
      return targetValue.toLowerCase().includes(lowerValue);
  });
  setFilteredProxies(filtered);
};

const handleSearchTypeChange = (event) => {
  setSearchType(event.target.value);
  // Reset search and filter all proxies again based on new search type but keep the old searchTerm
  const lowerValue = searchTerm.toLowerCase();
  const filtered = proxies.filter(proxy => {
      const targetValue = event.target.value === 'id' ? proxy.id.toString() : proxy.dateOfHearing;
      return targetValue.toLowerCase().includes(lowerValue);
  });
  setFilteredProxies(filtered);
};

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    const filtered = proxies.filter(proxy => {
      if (searchType === 'id') {
        // For IDs, ensuring an exact string match
        return proxy.id.toString() === searchTerm;
      } else {
        // For dates, assuming they are in a string format like 'YYYY-MM-DD'
        // This will require the searchTerm to be in the exact format as the dates stored
        // Consider using a date library like date-fns or Moment.js for more complex comparisons
        return proxy.dateOfHearing === searchTerm;
      }
    });
    setFilteredProxies(filtered);
  }
};



    const executePayment = async (proxyId, attendance, isAdminApproved) => {
      const token = localStorage.getItem('adminToken');
      try {
        // Now sending isAdminApproved as part of the request body.
        const response = await axios.post(`${ip}/admin/proxy-payments/${proxyId}/execute-payment`, {
          isAdminApproved: isAdminApproved,
        }, {
          headers: { 'x-auth-token': token },
        });
    
        alert(` ${response.data.message}`);
    
        // Optionally, update the proxy list or specific proxy status here
        // Update local state to reflect changes
        setProxies(proxies.map(proxy => {
          if (proxy.id === proxyId) {
            return { 
              ...proxy, 
              isAdminApproved: isAdminApproved,
              payment_executed: true, // Assuming you have a way to track this in your state
            };
          }
          return proxy;
        }));
    
      } catch (error) {
        console.error("Error executing payment:", error);
        alert(`Failed to execute payment: ${error.response?.data?.error || error.message}`);
      }
    };

    const updateAttendance = async (proxyId, newAttendance) => {
      const token = localStorage.getItem('adminToken');
      try {
        const response = await axios.post(`${ip}/admin/proxy-payments/${proxyId}/update-attendance`, {
          attendance: newAttendance,
        }, {
          headers: { 'x-auth-token': token },
        });
    
        alert(`Attendance updated: ${response.data.message}`);
        
        // Update proxies list to reflect new attendance status
        setProxies(proxies.map(proxy => {
          if (proxy.id === proxyId) {
            return { ...proxy, attendance: newAttendance };
          }
          return proxy;
        }));
      } catch (error) {
        console.error("Error updating attendance:", error);
        alert(`Failed to update attendance: ${error.response?.data?.error || error.message}`);
      }
    };
    
  return (
    <>
    <Sidebar />
    <div className={styles.container}>
    <div className={styles.searchContainer}>
                    <select
                        className={styles.searchTypeSelect}
                        value={searchType}
                        onChange={handleSearchTypeChange}
                    >
                        <option value="id">Search by Proxy ID</option>
                        <option value="date">Search by Hearing Date (Format: YYYY-MM-DD)</option>
                    </select>
                    <input
                        type="text"
                        placeholder={`Search by ${searchType === 'id' ? 'Proxy ID' : 'Hearing Date'}...`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}
                        className={styles.searchInput}
                    />
                </div>
    <table className={styles.table}>
        <thead>
            <tr className={styles.tableRow}>
                <th className={styles.tableHeader}>Proxy ID</th>
                <th className={styles.tableHeader}>Case ID</th>
                <th className={styles.tableHeader}>Proxy Generator ID</th>
                <th className={styles.tableHeader}>Proxy Acceptor</th>
                <th className={styles.tableHeader}>Hearing Date</th>
                <th className={styles.tableHeader}>Attendance Of Acceptor</th>
                <th className={styles.tableHeader}>Accept Date</th>
                <th className={styles.tableHeader}>Payment Status</th>
                <th className={styles.tableHeader}>Payment ID</th>
                <th className={styles.tableHeader}>Fee Pay By Generator</th>
                <th className={styles.tableHeader}>Fee Pay to Acceptor</th>
                <th className={styles.tableHeader}>Admin Status</th>
                <th className={styles.tableHeader}>Action Button</th>
            </tr>
        </thead>
        <tbody>
        {filteredProxies.map((proxy) => (
                            <tr key={proxy.id} className={styles.tableRow}>
                    <td className={styles.tableCell}>{proxy.id}</td>
                    <td className={styles.tableCell}>{proxy.caseId}</td>
        <td className={styles.tableCell}>{proxy.user_id}</td>
        <td className={styles.tableCell}>{proxy.accepted_by_user_id}</td>
        <td className={styles.tableCell}>{proxy.dateOfHearing}</td>
        <td className={styles.tableCell}>{proxy.attendance}  {proxy.attendance === 'A' ? (
    <button className={`${styles.actionButton} ${styles.payButton}`} onClick={() => updateAttendance(proxy.id, 'P')}>Mark Present</button>
  ) : (
    <button className={styles.redbtn} onClick={() => updateAttendance(proxy.id, 'A')}>Mark Absent</button>
  )}</td>
        <td className={styles.tableCell}>{proxy.acceptanceDate}</td>
        <td className={styles.tableCell}>{proxy.payment_status}</td>
        <td className={styles.tableCell}>{proxy.paymentId}</td>
        <td className={styles.tableCell}>{proxy.age}</td>
        <td className={styles.tableCell}>{convertFee(proxy.age)}</td>
        <td className={styles.tableCell}>
        {proxy.isAdminApproved ? (
                    <FaCheckCircle style={{ color: 'green' }} />
                  ) : (
                    <FaTimesCircle style={{ color: 'red' }} />
                  )}
                  
                </td>
                    <td className={styles.tableCell}>
                   
                    <button 
  className={`${styles.actionButton} ${styles.payButton}`}
  onClick={() => executePayment(proxy.id, proxy.attendance, true)} // Assuming approval is being given at the time of payment
>
 Execute Payment
</button>

                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>
    </>
  )
}

export default ProxyPayment