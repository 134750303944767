import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './Member.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Companyform from '../../Company/Companyform';
import Modal from '../../Client/People/ModelPop/Modal';
import GroupForm from '../../Group/GroupForm'
import { Button, Tooltip } from '@mui/material';
import {data} from '../../../../evn/evn'
import { ToastContainer, toast } from 'react-toastify';


const initialValues = {
  image: '',
  fullName: '',
  email: '',
  mobileno: '',
  designation: '',
  address: '',
  state: '',
  city: '',
  zipCode: '',
  selectedGroup: '',
  selectedCompany: '',
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  mobileno : Yup.string()
  .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
  designation: Yup.string(),
  address: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  zipCode: Yup.string(),
  selectedGroup: Yup.string(),
  selectedCompany: Yup.string(),
  image: Yup.mixed(),
});

const Member = () => {
  
  const [groupNames, setGroupNames] = useState([]); // State to store group names
  const openModalOne = () => setIsModalOpenOne(true);
  const [isModalOpenOne, setIsModalOpenOne] = useState(false);
  const openModalTwo = () => setIsModalOpenTwo(true);
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
  const closeModalOne = () => setIsModalOpenOne(false);
  const closeModalTwo = () => setIsModalOpenTwo(false);
  const [companyNames, setCompanyNames] = useState([]); // State to store group names
  const ip = data.evn
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchData = async () => {
    //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
    //   setDashboardData([...datas]); // Update local state to trigger re-render
    };
  
    fetchData();
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  
  }, []);

  useEffect(() => {
    // Fetch group names and populate the select options
    const fetchGroupNames = async () => {
      try {
        const response = await Axios.get(`${ip}/dashboard/groupform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });
        
        // Extract the group names from the response data
        const groupNamesArray = response.data.map((group) => group.groupName);
        setGroupNames(groupNamesArray);
      } catch (error) {
        toast.error(error);
      }
    };
    const fetchCompanyNames = async () => {
      try {
        const response = await Axios.get(`${ip}/dashboard/company`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });
        
        // Extract the group names from the response data
        const companyNamesArray = response.data.map((company) => company.companyName);
        setCompanyNames(companyNamesArray);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchCompanyNames();
    fetchGroupNames(); // Call the fetchGroupNames function when the component mounts
  }, []);

  const navigate = useNavigate();
  const HandleCancel=()=>{
    navigate(-1)
  }


  return (
    <div className={styles.MainContainer}>
    <div className={styles.formContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const formData = new FormData();
          Object.keys(values).forEach(key => {
            if (key === 'image' && values[key]) {
              formData.append('image', values.image);
            } else {
              formData.append(key, values[key]);
            }
          });

          try {
            let imagePath;
            if (values.image) {
              const uploadResponse = await Axios.post(`${ip}/upload`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'x-auth-token': localStorage.getItem('token'),
                },
              });
              imagePath = uploadResponse.data.imagePath;
            }

            const teamMemberData = { ...values, image: imagePath };
            const response = await Axios.post(`${ip}/dashboard/teammemberform`, teamMemberData, {
              headers: {
                'x-auth-token': localStorage.getItem('token'),
              },
            });

            toast.alert('Team Member Added successfully!');
            resetForm();
          } catch (error) {
  if (error.response && error.response.status === 400) {
    // Display error message from server
    toast.alert(error.response.data.error);
  } else {
    toast.error(error);
  }
}
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className={styles.imageUpload}>
              <label className={styles.imageLabel} htmlFor="image">
                {values.image ? (
                  <img
                    src={URL.createObjectURL(values.image)}
                    alt="Uploaded"
                    className={styles.uploadedImage}
                  />
                ) : (
                  <div className={styles.emptyImage}>Click here to Upload</div>
                  
                )}
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept=".png, .jpg, .jpeg"
                onChange={(event) => {
                  setFieldValue('image', event.currentTarget.files[0]);
                }}
                className={styles.imageInput}
              />
              <ErrorMessage name="image" component="div" className={styles.error} />
            </div>

            <div className={styles.fieldGroup}>
              <Field
                type="text"
                name="fullName" 
                placeholder="Full Name *"
                className={styles.inputField}
              />
              <ErrorMessage name="fullName" component="div" className={styles.error} />
            </div>

            <div className={styles.fieldGroup}>
              <Field type="email" name="email" placeholder="Email" className={styles.inputField} />
              <ErrorMessage name="email" component="div" className={styles.error} />
            </div>

            <div className={styles.fieldGroup}>
              <Field type="text" name="mobileno" placeholder="Mobile Number" className={styles.inputField} />
              <ErrorMessage name="mobileno" component="div" className={styles.error} />
            </div>

            <div className={styles.fieldGroup}>
              <Field
                type="text"
                name="designation"
                placeholder="Designation"
                className={styles.inputField}
              />
            </div>

            <div className={styles.fieldGroup}>
              <Field
                type="text"
                name="address"
                placeholder="Address"
                className={styles.inputField}
              />
            </div>

            <div className={styles.fieldGroup}>
              <Field type="text" name="state" placeholder="State" className={styles.inputField} />
            </div>

            <div className={styles.fieldGroup}>
              <Field type="text" name="city" placeholder="City" className={styles.inputField} />
            </div>

            <div className={styles.fieldGroup}>
              <Field type="text" name="zipCode" placeholder="Zip Code" className={styles.inputField} />
            </div>

            <div className={styles.horizontalFields}>
                <div className={styles.fieldGroup}>
                  <Field as="select" name="selectedGroup" className={styles.selectField}>
                    <option value="">Select a Group</option>
                    {groupNames.map((groupName) => (
                      <option key={groupName} value={groupName}>
                        {groupName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="selectedGroup" component="div" className={styles.error} />
                </div>

              <div className={styles.fieldGroup}>
                <NavLink to={"#"} className={styles.link} onClick={openModalOne}>
                  Add Group
                </NavLink>
              </div>

            </div>
            <div className={styles.horizontalFields}>
                <div className={styles.fieldGroup}>
                  <Field as="select" name="selectedCompany" className={styles.selectField}>
                    <option value="">Select a Company</option>
                    {companyNames.map((companyName) => (
                      <option key={companyName} value={companyName}>
                        {companyName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="selectedGroup" component="div" className={styles.error} />
            </div>

              <div className={styles.fieldGroup}>
                <NavLink to={"#"} className={styles.link} onClick={openModalTwo}>
                  Add Company
                </NavLink>
              </div>

            </div>
            
            <div className={styles.BtnContainer}>
            <Tooltip title="Submit Form">
              <button type="submit" className={styles.submitButton}>Submit</button>
            </Tooltip>
            <Tooltip title="Cancel Form">
              <button  type="submit" onClick={HandleCancel} className={styles.buttonCancel}>Cancel</button>
            </Tooltip>
            </div>
            <ToastContainer/>
          </Form>
        )}
      </Formik>
    </div>
    <Modal isOpen={isModalOpenOne} onClose={() => setIsModalOpenOne(false)}>
      <GroupForm onClose={closeModalOne}/>
    </Modal>
    <Modal isOpen={isModalOpenTwo} onClose={() => setIsModalOpenTwo(false)}>
    <Companyform onClose={closeModalTwo}/>
    </Modal>
    </div>
  );
};

export default Member;