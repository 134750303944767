import React from 'react'
import style from './services.module.css'
import Headers from "../../component/utilities/Header/Headers";
import Footer from "../../component/utilities/footer/footer";
import Card from '../../component/utilities/card/Card';
import Benifits from '../Home/benifits/Benifits';
import { FaCrown } from 'react-icons/fa';
import {service} from './data'
import {data} from '../../evn/evn'

const Services = () => {
  const ip = data.evn
  const backgroundImageUrl = `${ip}/headers.avif`;

  return (
    <>
      <Headers className={style.heading} title="SERVICES" image={backgroundImageUrl}>
        <p style={{ color: "white" }}>
        </p>
      </Headers>

      <section className={style.benfits}>
        <div className={`${style.container}${style.benfitsContainer}`}>
          <Benifits icons={<FaCrown />} title="SERVICES" />
        
        <div className={style.benfitsWrapper}>
            {/* function for render data from data.jsx */}
          {service.map(({ title, info, id }) => {
            return (
              <Card className={style.programsProgram} key={id}>
                <h4>{title}</h4>
                <p className={style.info}>{info}</p>
              </Card>
            );
          })}
        </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Services