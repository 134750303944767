import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';
import style from './DemoRequestForm.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {data} from '../../../evn/evn'


const DemoRequestForm = () => {

  useEffect(() => {
    // Load saved form data from localStorage when the component mounts
    const savedFormData = JSON.parse(localStorage.getItem('demoFormData')) || {};
    Object.keys(savedFormData).forEach(key => {
      const inputElement = document.getElementById(key);
      if (inputElement) {
        inputElement.value = savedFormData[key];
      }
    });
  }, []);

    // first fucntion
    const sendEmail = (formData) => {
      emailjs.send(
        'service_sdnu1i9', // Replace with your email service ID
        'template_b5ybwbc', // Replace with your email template ID
        formData, // Form data to send in the email
        '90GKjDPQx8XpRzcXr' // Replace with your user ID
      )
        .then((response) => {
          toast.success('Form Submitted successfully.We will contact you shortly!')
          // console.log('Email sent successfully:', response);
          // You can handle success, such as showing a success message to the user
        })
        .catch((error) => {
          toast.error('Email send error: ' + error.message);
          // You can handle errors, such as showing an error message to the user
        });
    };

    // second form
    const logFormData = (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
  
      const data = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
  
      sendEmail(data);
      event.target.reset();
    };

    // third fucntion
    // const logFormData = (event) => {
    //     event.preventDefault(); 
    //     const formData = new FormData(event.target);
        
    //     formData.forEach((value, key) => {
    //       console.log(`${key}: ${value}`);
    //     });
    //     event.target.reset();
    // };
    const handleInputChange = (event) => {
      const { id, value } = event.target;
      // Load existing saved data or initialize an empty object
      const savedFormData = JSON.parse(localStorage.getItem('demoFormData')) || {};
      savedFormData[id] = value; // Update the value for the current input field
      localStorage.setItem('demoFormData', JSON.stringify(savedFormData)); // Save the updated form data back to localStorage
    };

  return (
    <div className={style.bigBox}>
      <ToastContainer />
      <div className={style.blueSection}>
        {/* <div className={style.companyName}>Lawfax</div> */}
        <div className={style.companyDescription}>Learn How Lawfax Delivers the Best Results for Our Lawyers</div>
      </div>
      <br/>
      <div className={style.paragraph}>
        <li className={style.point}>Say how Lawfax makes getting real results from social media easier through better collaboration, deeper insight, and stronger security</li>
        <li className={style.point}> Your demo includes how to:</li>
        <ul>
          <li className={style.point}>&#10003; Discover Lawfax</li>
          <li className={style.point}>&#10003; Intuitive Case Management</li>
          <li className={style.point}>&#10003; Advanced Scheduling Tools</li>
          <li className={style.point}>&#10003; Financial Management</li>
          <li className={style.point}>&#10003; Proxy Council Feature</li>
          <li className={style.point}>&#10003; Integrated Communication Platform</li>
          <li className={style.point}>&#10003; Document Generation and Management</li>
          
        </ul>
        <li className={style.point}>Book your demo now, and take the first step towards streamlined case management, secure communication, and insightful document management. We'll contact you shortly to schedule a convenient time for your demo.</li>
      </div>

      <div className={style.container}>
        <div className={style.margin1}>
          <h1 className={style.margin}><strong>Schedule a Demo</strong></h1>
        </div>
        <form className={style.formContainer} id="demo-form" onSubmit={logFormData} onChange={handleInputChange}>          <input className={style.name} type="text" id="name" name="name" placeholder="Full Name" required />
          <input className={style.contact} type="tel" id="contact" name="contact" placeholder="Contact Number" required pattern="[0-9]{10}" />
          <input className={style.email} type="email" id="email" name="email" placeholder="Business Email Address" required />
          <input className={style.company} type="text" id="company" name="company" placeholder="Company" required />
          <div  className={`${style.demoForm} ${style.flexLabelAbove}`}>
          <select id="role" name="role" defaultValue="" required>
          <option value="" disabled>Select Role</option>
              <option>Chief Executive Officer (CEO)</option>
              <option>Chief Financial Officer (CFO)</option>
              <option>Chief Operating Officer (COO)</option>
              <option>Chief Marketing Officer (CMO)</option>
              <option>Chief Technology Officer (CTO)</option>
              <option>President</option>
              <option>Vice President</option>
              <option>Director</option>
              <option>Administrative Assistant</option>
              <option>Manager</option>
              <option>Receptionist</option>
              <option>Executive Assistant </option>
              <option>Sales Associate/Representative</option>
              <option>Digital Marketing Specialist</option>
              <option>Research Analyst</option>
              <option>Accountant</option>
              <option>Recruiter</option>
              <option>Benefits Administrator</option>
              <option>In-house Counsel</option>
              <option>Legal Assistant</option>
              <option>Contract Manager</option>
              <option>Paralegal</option>
              <option>Lawyer</option>
              <option>Prosecutor</option>
              <option>Alternative Dispute Resolution (ADR) Specialist</option>
              <option>Legal Researcher</option>
              <option>Legal Consultant</option>
              
            </select>
          </div>
          
          <br />
          <h7 style={{ display: 'flex', flexDirection:'row'}}>
           <input type="checkbox" id="terms-checkbox" className={style.privacy}  required/>
           
          Agree to<NavLink to='/termandcondition' className={style.anchor} style={{ marginTop:'2.8PX', fontSize: '14px', whiteSpace: 'nowrap' }}>Terms of Use & Privacy Policy</NavLink>
          </h7>
          <br />
          <button className={style.btn} type="submit" id="submit-button">Request a Demo</button>
        </form>
      </div>
    </div>
  );
};

export default DemoRequestForm;
