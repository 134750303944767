import React, { useEffect, useState } from 'react';
import { NavLink, useParams , useNavigate} from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { data } from '../../../../evn/evn';
import styles from './Concern&Opponent.module.css';
import { toast, ToastContainer } from 'react-toastify';

const Opponents = () => {
    const { caseId } = useParams();
    const ip = data.evn;
    const navigate = useNavigate();

   

    // Initial form values state
    const [initialFormValues, setInitialFormValues] = useState({
        opponentPartyName: '',
        lawyerName: '',
        mobileNo: '',
        emailId: '',
    });

    const validationSchema = Yup.object().shape({
        opponentPartyName: Yup.string().required('Opponent Party Name is required'),
        lawyerName: Yup.string(),
        mobileNo: Yup.string(),
        emailId: Yup.string().email('Invalid email format'),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await axios.post(`${ip}/opponent`, { ...values, caseId }, {
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                },
            });
            toast.success('Opponent added successfully!');
            resetForm();
            setTimeout(() => {
                navigate(0); // Reloads the current page
              }, 2000);
        } catch (error) {
            toast.error('Failed to add opponent.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize // Important to reinitialize form when initial values change
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className={styles.heading}>Opponent</div>
                        <div className={styles.row1}>
                            <div className={styles.column}>
                                <label className={styles.label}>Party:</label>
                                <Field type="text" name="opponentPartyName" className={styles.selectCd} />
                                <ErrorMessage name="opponentPartyName" component="div" className={styles.error} />
                            </div>
                            <div className={styles.column}>
                                <label className={styles.label}>Lawyer Name:</label>
                                <Field type="text" name="lawyerName" className={styles.selectCd} />
                            </div>
                        </div>
                        <div className={styles.row1}>
                            <div className={styles.column}>
                                <label className={styles.label}>Mobile No:</label>
                                <Field type="text" name="mobileNo" className={styles.selectCd} />
                            </div>
                            <div className={styles.column}>
                                <label className={styles.label}>Email Id:</label>
                                <Field type="text" name="emailId" className={styles.selectCd} />
                            </div>
                        </div>
                        <div className={styles.BtnContainer}>
                            <Tooltip title="Submit Form">
                                <button type="submit" disabled={isSubmitting} className={styles.submitButton}>Submit</button>
                            </Tooltip>
                            <Tooltip title="Cancel Form">
                                <NavLink to="/dashboard/Importcase">
                                    <button type="button" className={`${styles.submitButton} ${styles.CancelButton}`}>Cancel</button>
                                </NavLink>
                            </Tooltip>
                        </div>
                        <ToastContainer />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Opponents;
