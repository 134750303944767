import React, { useState } from 'react';
import styles from './UserProfilePage.module.css';
import { useNavigate } from 'react-router-dom';

const UserProfilePage = ({ onClose, handleSignOut, user }) => {
  // State for user details
 

  const [userDetails, setUserDetails] = useState({
    name: '',
    experience: '',
    lawyerType: '',
    mobileNumber: '',
    age: ''
  });

  // Handle change in form inputs
  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the userDetails to your backend or context for update
    console.log(userDetails);
    alert('Profile updated!');
  };

  const lawyerTypeOptions = [
    'Criminal lawyers',
    'Environmental lawyers',
    'Family lawyers',
    'Corporate lawyers',
    'Civil lawyers',
    'Intellectual property lawyers',
    'Tax lawyer',
    'Cyber lawyers',
    'Estate planning lawyers',
    "Worker's compensation lawyer",
    'Public interest lawyers',
    'Medical malpractice lawyers',
    'Merger and acquisition lawyer',
    'Labour lawyers',
    'Bankruptcy lawyers',
    'Securities lawyer',
    'Military lawyers',
    'Contract lawyers',
    'Government lawyers',
    'Immigration lawyers',
  ];

  return (
    <div className={styles.userProfilePage}>
      <form onSubmit={handleSubmit}>
       
      <div className={styles.formGroup}>
          <label className={styles.labels} htmlFor="name">Name:</label>
          <input
            className={styles.Holders}
            type="text"
            name="name"
            id="name"
            value={userDetails.name}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.labels} htmlFor="experience">Experience:</label>
          <input
            className={styles.Holders}
            type="text"
            name="experience"
            id="experience"
            value={userDetails.experience}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.labels} htmlFor="lawyerType">Lawyer Type:</label>
          <select
          className={styles.Holders}
            name="lawyerType"
            id="lawyerType"
            value={userDetails.lawyerType}
            onChange={handleChange}
          >
           {lawyerTypeOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.labels} htmlFor="mobileNumber">Mobile Number:</label>
          <input
          className={styles.Holders}
            type="tel"
            name="mobileNumber"
            id="mobileNumber"
            value={userDetails.mobileNumber}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.labels} htmlFor="age">Age:</label>
          <input
          className={styles.Holders}
            type="number"
            name="age"
            id="age"
            value={userDetails.age}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className={styles.submitButton}>Update Profile</button>
      </form>
    </div>
  );
};

export default UserProfilePage;
