import React, { useState } from 'react';
import LeftSideNav from './LeftSideNav';
import MessageBox from './MessageBox';
import style from './Style/ChatBox.module.css';

const ChatBox = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);

  return (
    <div className={style.container}>
      <div className={style.myChats}>
        {/* Pass setSelectedDocument to LeftSideNav */}
        <LeftSideNav setSelectedDocument={setSelectedDocument} />   
      </div>
      <div className={style.singleChat}>
        {/* Pass selectedDocument to MessageBox */}
        <MessageBox selectedDocument={selectedDocument} />
      </div>
    </div>
  );
};

export default ChatBox;
