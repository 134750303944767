// Partners.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';// Make sure to create this CSS fil
import Benifits from './Benifits';
import Card from '../../../component/utilities/card/Card';
import { benfit } from "../data";
import style from "./benfits.module.css"
import { FaCrown } from 'react-icons/fa';

 
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    },
    
  
  };
  
  export const BenifitsTwo = () => {
    return (
      <div className="partners">
        {/* <h2 className="headi ng">Partnered<span className="why-highlight"> With</span></h2> */}
        <h2> <Benifits className={style.main1} icons={<FaCrown />} title="Benefits" /></h2>
        <Carousel 
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
          draggable={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
        {benfit.map(({ id, icon, title, info }) => {
            return (
              <Card className={style.programsProgram} key={id}>
                <div className={style.BenifitsTitle}>
                <span>{icon}</span>
                <h4 className={style.Title}>{title}</h4>
                </div>
                <p className={style.info}>{info}</p>
              </Card>
            );
          })}
       
        </Carousel>
      </div>
    );
  };
  
  export default BenifitsTwo;
