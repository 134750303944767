import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,Badge, ModalFooter, Input, Image, Flex } from '@chakra-ui/react';import ChatLoading from './ChatLoading';
import styles from './Style/Mychats.module.css';
import { FormControl } from '@mui/material';
import axios from 'axios'; // Ensure axios is imported
import {data} from '../../evn/evn'

const MyChats = ({ selectedUser, onChatSelect , updateChatUnreadCount }) => {
  // Define static chat data


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const ip = data.evn

  const handleCreateGroup = () => {
    console.log('Creating group with name:', groupName);
    onClose(); // Close the modal after group creation
  };

  // const handleChatClick = (chat) => {
  //   onChatSelect(chat); // Pass the selected chat up to the parent component
  // };

  const handleSelectChat = (userId) => {
    // Find the user by userId
    const user = selectedUsers.find((u) => u.id === userId);
    if (user) {
      onChatSelect(user);
      setSelectedChatId(userId); // Set the selected chat ID to the userId, not the user object
    }
};
const fetchChats = async () => {
  setIsLoading(true);
  try {
    // Assuming you have a way to get the current user's auth token
   
    const response = await axios.get(`${ip}/api/chats`, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    });
    setChats(response.data.data); // Update the state with the fetched chats
    await axios.patch(`${ip}/api/messages/mark-global-read`, {}, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
    });
    setIsLoading(false);
  } catch (error) {
    console.error("Failed to fetch chats:", error);
    setIsLoading(false);
  }
};

// useEffect hook to fetch chats when the component mounts or selectedUser changes
useEffect(() => {
  fetchChats();
}, [selectedUser]);

  
  const [selectedChatId, setSelectedChatId] = useState(null); // State to track the selected chat

  // Function to handle selecting a chat
  // const handleSelectChat = (chatId) => {
  //   setSelectedChatId(chatId); 
  // };

  const handleChatSelection = (chat) => {
    onChatSelect(chat); // Ensure 'chat' includes the 'chatId' and 'otherUser' details
  };

  // Function to simulate selecting a chat
  const setSelectedChat = (chat) => {
    console.log('Selected chat:', chat);
  };

  const findChat = (chatId) => chats.find(chat => chat._id === chatId);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUsers(prevUsers => {
        const userExists = prevUsers.some(user => user.id === selectedUser.id);
        return userExists ? prevUsers : [...prevUsers, selectedUser];
      });
    }
  }, [selectedUser]);
 
  return (
    <Box className={styles.myChatsContainer}>
      <Box className={styles.headerBox}>My Chats</Box>

      {/* Modal for creating a group chat, if applicable */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
        <ModalOverlay className={styles.ModalContainer} />
        <ModalContent sx={{ margin: '0 auto', width: 'fit-content', borderRadius: 'lg', overflow: 'hidden', alignContent: "center" }}>
          <ModalHeader className={styles.GroupHeading}>Create a new group chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input placeholder="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} focusBorderColor="teal.500" />
          </ModalBody>
          <ModalFooter>
            <Button className={styles.CreateBtn} colorScheme="teal" mr={3} onClick={() => {/* Handle group creation */}}>Create</Button>
            <Button className={styles.CancelBtn} variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isLoading ? (
        <ChatLoading />
      ) : (
        <Stack>
        {chats.length > 0 ? (
          chats.map((chat) => (
            <Box key={chat.chatId} onClick={() => handleChatSelection(chat)} cursor="pointer" bg='var(--color-fourth)' color='black' px={3} py={2} borderRadius="lg" display="flex" alignItems="center" gap="12px" className={styles.chatItem}>
              {/* Flex container for avatar and online status dot */}
              <Flex alignItems="center" gap="2" position="relative">
                  <Image borderRadius="50%" boxSize="30px" src={chat.otherUser.avatarUrl} alt={chat.otherUser.name} />
                  {chat.otherUser.unreadMessagesCount > 0 && (
  <Box
  sx={{
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'translate(100%, -50%)',
    backgroundColor: 'red', // Assuming var(--color-danger) is red
    color: 'white', // Assuming var(--color-fifth) is white
    borderRadius: '50%',
    padding: '0 5px',
    minWidth: '18px',
    height: '18px',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  {chat.otherUser.unreadMessagesCount}
</Box>
)}

                </Flex>
              <Text className={styles.chatName}>{chat.otherUser.name}</Text> {/* Display other user's name */}
              <Box 
                  borderRadius="50%" 
                  boxSize="10px" 
                  bg={Number(chat.otherUser.isOnline) === 1 ? 'green' : 'var(--color-danger)'}
                />
            </Box>
          ))
        ) : (
          <Text>No chats found</Text>
        )}
      </Stack>
      
      )}
    </Box>
  );
};

export default MyChats;
