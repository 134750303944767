import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import TextField from '@mui/material/TextField';
import style from './Style/RightSideBar.module.css'
import { SearchIcon } from '@chakra-ui/icons';
import { DrawerHeader } from '@chakra-ui/react';
import { Typography } from '@mui/material';
import {data} from '../../evn/evn'
import Avatar from '@mui/material/Avatar';


const RightSidebar = ({ onUserSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const ip = data.evn
  
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Make sure this line is correct
    const inputValue = event?.target?.value; // Safely access value, resulting in 'undefined' if anything is missing
  };
  
  const searchUsers = async (query) => {
    try {
      const response = await axios.get(`${ip}/searchUsers?query=${query}`, {
        // Your authentication headers
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      setSearchResults(response.data.data); // Update the search results state
    } catch (error) {
      console.error("Failed to search users:", error);
    }
  };

  // Call searchUsers function whenever searchQuery changes
  useEffect(() => {
    if (searchQuery) {
      searchUsers(searchQuery);
    } else {
      setSearchResults([]); // Clear results if query is empty
    }
  }, [searchQuery]);


  const handleListItemClick = async (user) => {
    try {
      const { data: chatSession } = await axios.post(`${ip}/api/chats`, { selectedUserId: user.id }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      console.log(chatSession); // Debugging
      onUserSelect(chatSession.user); // Assuming chatSession.user contains the user details
    } catch (error) {
      console.error("Error creating/getting chat session:", error);
    }
  };
  
  
  
  
  
    const [state, setState] = useState({
        search: false, // Changed from 'right' to 'search'
    });
    

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown') {
            return;
        }
        setState({ ...state, search: open }); // Changed to use 'search' key
    };

    // const handleSearchChange = (event) => {
    //     setSearchQuery(event.target.value);
    // };

    const list = () => (
      <Box
        sx={{ width: 250 }} // Simplified as we're only dealing with 'search' now
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
       <Box sx={{ padding: 2, backgroundColor: 'var(--bg-primary)', color: 'var(--color-fifth)' }}>
          <Typography variant="h6" noWrap>
            search users 
          </Typography>
        </Box>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          onClick={(event) => event.stopPropagation()}
        />
        <Divider />
        <List>
  {searchResults.map((user) => (
    <ListItem key={user.id} disablePadding onClick={() => handleListItemClick(user)}>
      <ListItemButton>
        <ListItemIcon>
          <Avatar src={user.avatar_url} alt={user.name}>
            {!user.avatar_url ? user.name[0] : ''}
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={user.name} />
      </ListItemButton>
    </ListItem>
  ))}
</List>
        
      </Box>
  );
    return (
        <div className={style.navbar}>
          {/* <h2 className={style.heading}>LAWFAX CHAT APP</h2> */}
        
            <Button  onClick={toggleDrawer(true)}>
              <span className={style.searchBtn}>
                <SearchIcon /> SEARCH USERS
                </span>
            </Button>
            <Drawer
                anchor="right"
                open={state.search}
                onClose={toggleDrawer(false)}
                title='Search User by name or username'
            >
                {list()}
            </Drawer>
        </div>
    );
}

export default RightSidebar;
