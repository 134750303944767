import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import styles from './AlertManagement.module.css'; // Assuming a similar CSS module for styling
import { useNavigate } from 'react-router-dom';

const AlertManagement = () => {
  const [alerts, setAlerts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAlerts = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/alerts`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setAlerts(response.data.alerts); // Adjust this according to your actual response structure
        setSearchResults(response.data.alerts);
      } catch (error) {
        console.error("There was an error fetching the alerts:", error);
      }
    };

    fetchAlerts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(alerts);
    } else {
      const filteredAlerts = alerts.filter(alert => {
        switch (searchType) {
          case 'id':
            return alert.id.toString().includes(event.target.value);
          case 'userId':
            return alert.user_id.toString().includes(event.target.value);
          case 'caseId':
            return alert.caseTitle.toLowerCase().includes(event.target.value.toLowerCase());
          default:
            return false;
        }
      });
      setSearchResults(filteredAlerts);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reset search term
    setSearchResults(alerts); // Show all alerts
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchAlerts = alerts.filter(alert => {
        switch (searchType) {
          case 'id':
            return alert.id.toString() === searchTerm;
          case 'userId':
            return alert.user_id.toString() === searchTerm;
          case 'caseId':
            return alert.caseTitle.toLowerCase() === searchTerm.toLowerCase();
          default:
            return false;
        }
      });
      setSearchResults(exactMatchAlerts);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
        <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by Alert ID</option>
            <option value="userId">Search by User ID</option>
            <option value="caseId">Search by Case ID</option> {/* Added caseId search option */}
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Alert ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Case ID</th>
              <th className={styles.tableHeader}>Title</th>
              <th className={styles.tableHeader}>Start Date</th>
              <th className={styles.tableHeader}>Completion Date</th>
              
              <th className={styles.tableHeader}>Case Type</th>
              <th className={styles.tableHeader}>Assigned From</th>
              <th className={styles.tableHeader}>Assigned To</th>
              
            </tr>
          </thead>
          <tbody>
            {searchResults.map((alert) => (
              <tr key={alert.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{alert.id}</td>
                <td className={styles.tableCell}>{alert.user_id}</td>
                <td className={styles.tableCell}>{alert.caseTitle}</td>
                <td className={styles.tableCell}>{alert.title}</td>
                <td className={styles.tableCell}>{alert.startDate}</td>
                <td className={styles.tableCell}>{alert.completionDate}</td>
                
                <td className={styles.tableCell}>{alert.caseType}</td>
                <td className={styles.tableCell}>{alert.assignFrom}</td>
                <td className={styles.tableCell}>{alert.assignTo}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AlertManagement;
