import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { data } from '../../../../../evn/evn'; // Correctly import your environment configuration
import style from "./ConcernPersonTable.module.css"; // Adjust path as necessary
import { Button, CircularProgress } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Import MUI download icon
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { ToastContainer, toast } from 'react-toastify';



const DocumentTable = () => {
  const { caseId } = useParams(); // Use caseId from URL
  const [documentData, setDocumentData] = useState([]); // State to hold document details
  const ip = data.evn; // Ensure this is configured to your server's IP or hostname
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5)
  const [invoicesPerPage, setinvoicesPerPage] = useState(5);
  const [caseDetails, setCaseDetails] = useState([]); // State to hold case details


  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await axios.get(`${ip}/case-files/${caseId}`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        setDocumentData(response.data); // Update state with fetched document data
      } catch (error) {
        toast.error("Failed to fetch document data:", error);
      }
    };

    fetchDocumentData();
  }, [caseId, ip]);

  const handleDownloadClick = async (filename) => {
    try {
      // Update this URL to use the filename instead of fileId
      const url = `${ip}/download/${encodeURIComponent(filename)}`;
  
      // Fetch file as a Blob
      const response = await axios.get(url, {
        responseType: 'blob', // Important for handling binary files
        headers: {
          "x-auth-token": localStorage.getItem("token"), // Ensure authentication token is sent if needed
        },
      });

      // Create a URL for the blob and trigger download
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); // Set the filename for download
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up
    } catch (error) {
      toast.error("Failed to download file:", error);
      toast.alert('Failed to download file');
    }
};


  const handleDeleteDocument = async (documentId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        await axios.delete(`${ip}/delete-document/${documentId}`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        // Remove the document from the state to update the UI
        const updatedDocuments = documentData.filter(doc => doc.id !== documentId);
        setDocumentData(updatedDocuments);
        toast('Document deleted successfully');
      } catch (error) {
        toast.error("Failed to delete document:");
      }
    }
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = documentData.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleNextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => prev > 1 ? prev - 1 : 1);
  };

  return (
    <>
      <div className={style.container}>
      <table className={style.table}>
      <thead className={style.tableHead}>
          <tr>
          <th className={style.Sno}>S.No.</th>
            <th>File</th>
            <th>Actions</th>
          </tr>
        </thead>
        
        <tbody className={style.tableBody}>

        {currentRecords.map((doc, index) => (
              <tr className={style.Tr} key={index}>
                <td className={style.td}>{index+1}</td>  
                <td className={style.td}>{doc.file_path.split('/').pop()}</td> Display filename only 
                <td className={style.td}>{doc.file_path.split('/').pop()}</td>
                <td className={style.td}>
                <button className={style.btn}  onClick={() => handleDeleteDocument(doc.id)}><DeleteIcon /></button>
                <button className={style.btn} onClick={() => handleDownloadClick(doc.file_path.split('/').pop())}><DownloadForOfflineIcon /></button>
              </td>
              <ToastContainer/>
              </tr>
            ))}
         
        </tbody>
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(caseDetails.length / invoicesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
      </table>
    </div>
    </>
  );
};

export default DocumentTable;
