import React, { useState } from 'react';
import styles from './CustomDropdown.module.css'; 

const CustomDropdown = ({ options, onSelection }) => {
  const [hovered, setHovered] = useState(null);
  const [subHovered, setSubHovered] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null); 

  const handleHover = (key) => {
    setHovered(key);
    setSubHovered(null); 
  };

  const handleSubHover = (key) => {
    setSubHovered(key);
  };

  const handleSelect = (value, displayValue) => {
    setSelected(displayValue);
    setSelectedSub(null); 
    setHovered(null);
    setSubHovered(null);
    onSelection(value);
  };

  const handleSubSelect = (subKey) => {
    setSelectedSub(subKey);
    setSubHovered(null); 
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.selected}>
        {selected || 'Select Court'}
        {!selected && <span className={styles.arrow}>▼</span>}
        <div className={`${styles.menu} ${hovered !== null ? styles.menuVisible : ''}`}>
          {Object.keys(options).map((key) => (
            <div
              key={key}
              className={styles.menuItem}
              onMouseEnter={() => handleHover(key)}
              onClick={() => {
                if (key === 'Supreme Court') {
                  handleSelect(key, key);
                }
              }}
            >
              {key}
              {hovered === key && key !== 'Supreme Court' && (
                <div className={`${styles.subMenu} ${subHovered !== null || selectedSub ? styles.menuVisible : ''}`}>
                  {Object.keys(options[key]).map((subKey) => (
                    <div
                      key={subKey}
                      className={styles.subMenuItem}
                      onMouseEnter={() => handleSubHover(subKey)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubSelect(`${key} - ${subKey}`);
                      }}
                    >
                      {subKey}
                      {subHovered === subKey || selectedSub === `${key} - ${subKey}` ? (
                        <div className={`${styles.subSubMenu} ${styles.menuVisible}`}>
                          {options[key][subKey].map((item) => (
                            <div
                              key={item}
                              className={styles.subSubMenuItem}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelect(`${key} - ${subKey} - ${item}`, item);
                              }}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;
