import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import style from './Navbar.module.css';
import { links } from "./data"; // Adjust the import as needed
import { NotificationContext } from '../Notifications/NotificationsContext'; // Adjust path as needed
import { FaBell, FaWallet } from "react-icons/fa";
import WalletDisplayComponent from '../../form/Wallet/Wallet';
import {data} from '../../../evn/evn'
import { Tooltip } from '@mui/material';
import { MdMessage } from 'react-icons/md';

  const DashboardNavbar = ({ id, wallet, Chat, bells, proxy }) => { 
  const { notifications, setNotifications } = useContext(NotificationContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const handleCloseWalletModal = () => setShowWalletModal(false);
  const ip = data.evn
  // Function to mark notifications as viewed
  const toggleWalletModal = () => setShowWalletModal(!showWalletModal);
  const [globalUnreadCount, setGlobalUnreadCount] = useState(0);

  const resetNotificationCount = async () => {
    try {
      await axios.put(`${ip}/dashboard/user/notifications/viewed`, {}, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      // Update the notification count to 0
      setNotificationsCount(0);
      // Update notifications state as well (optional based on your logic)
      setNotifications(notifications.map(notification => ({ ...notification, isViewed: true })));
      
    } catch (error) {
      console.error('Error marking notifications as viewed:', error);
    }
  };

  // Fetch the count of unviewed notifications
  useEffect(() => {
    axios.get(`${ip}/dashboard/user/notifications/count`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    })
    .then((response) => {
      setNotificationsCount(response.data.count);
    })
    .catch((error) => {
      console.error('Error fetching notification count:', error);
    });
  }, []);

  const renderSubmenu = (submenu) => (
    <ul className={style.Links}>
      {submenu.map((item, index) => (
        <li className={style.LinksList} key={index}>
          <NavLink className={style.submenuLinks} to={item.path}>{item.name}</NavLink>
        </li>
      ))}
    </ul>
  );

  return (
    <nav className={style.Container} id={id}>
      <div className={style.navbarMenuLinks}> 
        <ul className={style.Links}>
          {links.map(({ name, path, submenu, bell, id }, index) => (
            <li className={style.LinksList} key={index} 
                onMouseEnter={() => submenu && setHoveredMenuItem(name)}
                onMouseLeave={() => submenu && setHoveredMenuItem(null)}
                >
                <div ></div>

              <NavLink className={style.mainMenu} to={path} >
                {name}
                {bell && (
                  <div className={style.BellIcon} onClick={resetNotificationCount} id={bells}>
                    {bell}
                    {notificationsCount > 0 && (
                      <span className={style.NotifiCount}>{notificationsCount}</span>
                    )}
                  </div>
                )}
              </NavLink>
                
              {/* Show submenu if hovered */}
              {submenu && hoveredMenuItem === name && (
                <div className={style.DropDown}>
                  {renderSubmenu(submenu)}
                </div>
              )}
            </li>
          ))}
          <Tooltip title="Wallet" placement="bottom">

          <li className={style.LinksList} onClick={toggleWalletModal}>
                        <FaWallet size="24px" id={wallet}/>
                    </li>
                    </Tooltip>
          <li className={style.LinksList} >
            <Tooltip title="Lawfax Chat App" placement="right">
              <NavLink  to={'/Chatpage'} style={{ cursor: 'pointer' }}>
                <MdMessage className={style.MdMessage} size="2rem" id={Chat}/>
                {globalUnreadCount > 0 && (
                  <span className={style.unreadCountBadge} >
                    {globalUnreadCount}
                  </span>
                )}
              </NavLink>
            </Tooltip>
          </li>
        </ul>
      </div>
      {showWalletModal && (
  <div className={style.ModalOverlay} onClick={toggleWalletModal}>
    <div className={style.ModalContent} onClick={(e) => e.stopPropagation()}>
      <WalletDisplayComponent onClose={handleCloseWalletModal} />
    </div>
  </div>
)}
    </nav>
  );
};

export default DashboardNavbar;
