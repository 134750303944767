import React, { useState } from "react";
import emailjs from "emailjs-com";
import style from "./contact.module.css";
import Headers from "../../component/utilities/Header/Headers";
import Footer from "../../component/utilities/footer/footer";
import axios from 'axios';
import image from "../../assets/contact.jpg";
import {data} from '../../evn/evn'

const Contact = () => {
  const ip = data.evn
  const backgroundImageUrl = `${ip}/contact.jpg`;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = () => {
    emailjs.sendForm(
      "service_fay8o8p",
      "template_d2euhz7",
      "#contact-form",
      "90GKjDPQx8XpRzcXr"
    ).then(
      (result) => {
        console.log("Email sent successfully:", result.text);
        setIsSubmitted(true);
        // Reset form here if needed
      },
      (error) => {
        console.error("Email sending failed:", error);
        setSubmitError("Failed to send message. Please try again later.");
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    setSubmitError("");

    // Replace with the actual endpoint of your backend
    const endpoint = 'http://localhost:8052/contactSubmit';

    try {
      const response = await axios.post(endpoint, formData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.status === 200) {
        // If backend storage is successful, proceed to send an email
        sendEmail(); // Calling EmailJS function
        setFormData({ name: "", email: "", message: "" }); // Reset form fields
      } else {
        alert.error('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError("Failed to submit the form. Please try again later.");
    }
  };

  return (
    <>
      <Headers className={style.HeadersImage} title="CONTACT US" image={backgroundImageUrl}>
            {/* <h1 className={style.MainHeading}>Contact Us</h1> */}
        <div className={style.flexContainer}>
          <div className={style.contactFormContainer}>
            {isSubmitted && (
              <p className={style.successMessage}>Your message has been sent successfully!</p>
            )}
            {submitError && <p className={style.errorMessage}>{submitError}</p>}
            <form id="contact-form" onSubmit={handleSubmit}>
              <div className={style.formGroup}>
                <label htmlFor="name">Name</label>
                <input
                  className={style.input}
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  className={style.input}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.formGroup}>
                <label htmlFor="message">Message</label>
                <textarea
                  className={style.textArea}
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  required
                />
              </div>
              <button type="submit" className={style.btn}>
                Submit
              </button>
            </form>
          </div>
          <div className={style.companyInfo}>
            <h2 className={style.Heading}>Our Office</h2>
            <p style={{color:'white'}}>2372, Second Floor,Sector-57, Gurugram ( Greater Delhi)</p>
            <a href="https://wa.me/919056869267" target="_blank" style={{color:'pink'}}>+91 90568 69267</a>
          </div>
        </div>
      </Headers>
      <Footer />
    </>
  );
};

export default Contact;
