import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure this points to your environment variables
import Sidebar from '../AdminComponents/SideBar.jsx'; // Ensure this points to your Sidebar component
import { useNavigate } from 'react-router-dom';
import styles from './style/NewCustomerLead.module.css'; // Using the same styles as NewCustomerLead
import { FaDownload, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';

const ContactFormSubmissionsAdmin = () => {
  const [submissions, setSubmissions] = useState({});
  const navigate = useNavigate();
  const [downloadingDates, setDownloadingDates] = useState({});
  const [dateSearchTerm, setDateSearchTerm] = useState('');
  const ip = data.evn; // Ensure this is correctly set up
  const [reviewStatuses, setReviewStatuses] = useState({});

  
  
  useEffect(() => {
    const fetchSubmissions = async () => {
        const token = localStorage.getItem('adminToken');
        if (!token) {
            navigate('/.admin');
            return;
        }
        try {
            const response = await axios.get(`${ip}/admin/contact-submissions`, {
                headers: { 'x-auth-token': token },
            });
            const organizedSubmissions = {};
            const initialReviewStatuses = {}; // To track the review status of submissions by date
            response.data.submissions.forEach(submission => {
                const date = submission.submitted_date.split('T')[0]; // Extract the date part
                organizedSubmissions[date] = organizedSubmissions[date] || [];
                organizedSubmissions[date].push(submission);

                // Determine if all submissions on a date are reviewed
                initialReviewStatuses[date] = initialReviewStatuses[date] !== false && submission.admin_reviewed;
            });
            setSubmissions(organizedSubmissions);
            setReviewStatuses(initialReviewStatuses); // Set the initial review statuses based on fetched data
        } catch (error) {
            console.error('Error fetching contact form submissions:', error);
        }
    };

    fetchSubmissions();
}, [navigate, ip]);


  const handleDateSearchChange = (e) => {
    setDateSearchTerm(e.target.value);
  };

  const handleDownloadPdf = async (date) => {
    setDownloadingDates(prev => ({ ...prev, [date]: true }));
    try {
      const response = await axios.get(`${ip}/dashboard/contact-submissions/download-pdf/${date}`, {
        responseType: 'blob',
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `contact-submissions-${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    } finally {
      setDownloadingDates(prev => ({ ...prev, [date]: false }));
    }
  };

  const handleReviewStatusChange = async (date) => {
    const currentStatus = reviewStatuses[date] ? 0 : 1; // Determine the new status based on current state
  
    try {
      await axios.post(`${ip}/admin/review-contact-submissions/${date}`, { newStatus: currentStatus }, {
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
      });
      setReviewStatuses(prev => ({ ...prev, [date]: !!currentStatus })); // Update state based on new status
    } catch (error) {
      console.error('Error updating review status:', error);
    }
  };

  // Filter submissions by the searched date
  const filteredSubmissions = dateSearchTerm ? Object.keys(submissions).reduce((acc, date) => {
    if (date.includes(dateSearchTerm)) {
      acc[date] = submissions[date];
    }
    return acc;
  }, {}) : submissions;

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <h2 className={styles.heading}>Contact Form Submissions Review</h2>
        <input
          type="date"
          value={dateSearchTerm}
          onChange={handleDateSearchChange}
          className={styles.searchInput}
        />
        {Object.entries(filteredSubmissions).map(([date, submissionsForDate]) => (
          <React.Fragment key={date}>
            <div className={styles.dateHeaderContainer}>
              <h3 className={styles.dateHeader}>{date}</h3>
              {downloadingDates[date] ? (
                <FaSpinner className={`${styles.downloadIcon} ${styles.spinner}`} />
              ) : (
                <FaDownload
                  className={styles.downloadIcon}
                  onClick={() => handleDownloadPdf(date)}
                />
              )}
              {reviewStatuses[date] ? (
                <FaCheckCircle
                  className={`${styles.statusIcon}`}
                  style={{ color: 'green' }}
                  onClick={() => handleReviewStatusChange(date)}
                />
              ) : (
                <FaTimesCircle
                  className={`${styles.statusIcon}`}
                  style={{ color: 'red' }}
                  onClick={() => handleReviewStatusChange(date)}
                />
              )}
            </div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHeader}>ID</th>
                  <th className={styles.tableHeader}>Name</th>
                  <th className={styles.tableHeader}>Email</th>
                  <th className={styles.tableHeader}>Message</th>
                </tr>
              </thead>
              <tbody>
                {submissionsForDate.map((submission, index) => (
                  <tr key={index}>
                    <td className={styles.tableCell}>{submission.id}</td>
                    <td className={styles.tableCell}>{submission.name}</td>
                    <td className={styles.tableCell}>{submission.email}</td>
                    <td className={styles.tableCell}>{submission.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default ContactFormSubmissionsAdmin;
