import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Center, HStack, Input, Button } from '@chakra-ui/react';
import ScrollableChat from '../chat/ScrollableChat';
import style from './Style/MessageBox.module.css';


const MessageBox = ({  selectedDocument  }) => {
  const initialMessages = [
    { _id: 'm1', sender: { _id: 'u1', name: 'Alice' }, content: 'Hi there!', createdAt: '2020-01-01T00:00:00Z' },
    { _id: 'm2', sender: { _id: 'u2', name: 'Bob' }, content: 'Hello!', createdAt: '2020-01-01T01:00:00Z' },
    { _id: 'm3', sender: { _id: 'u1', name: 'Alice' }, content: 'How are you?', createdAt: '2020-01-01T02:00:00Z' }
  ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const [messages, setMessages] = useState(initialMessages);
  const [message, setMessage] = useState('');
  const scrollableChatRef = useRef(null);

  // const selectedChat = { _id: 'chat1', chatName: 'Tech Support', isGroupChat: true };
  

  const handleSend = () => {
    if (message.trim() === '') {
      return; // If message is empty or only whitespace, do nothing
    }

    const newMessage = {
      _id: Date.now().toString(),
      sender: { _id: 'u1', name: 'Alice' },
      content: message,
      createdAt: new Date().toISOString()
    };

    setMessages([...messages, newMessage]);
    setMessage('');
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    console.log('Effect triggered by messages change');
    if (scrollableChatRef.current) {
      console.log('scrollableChatRef is not null', scrollableChatRef.current);
      const scroll = scrollableChatRef.current;
      // Smooth scroll to the bottom
      const scrollHeight = scroll.scrollHeight;
      const height = scroll.clientHeight;
      console.log(`Scroll Height: ${scrollHeight}, Client Height: ${height}`);
      const maxScrollTop = scrollHeight - height;
      console.log(`Max Scroll Top: ${maxScrollTop}`);
      scroll.scrollTo({ top: maxScrollTop, behavior: "smooth" });
    } else {
      console.log('scrollableChatRef is null');
    }

  }, [message]);

  useEffect(() => {
    // Example: Fetch messages for the selected document
    if (selectedDocument) {
      console.log(`Displaying chat for document: ${selectedDocument.documentName}`);
      // Fetch messages related to `selectedDocument` here
      // For demonstration, let's pretend we set new messages
      // setMessages(fetchedMessages);
    }
  }, [selectedDocument]);
  
  

  return (
    <Box className={style.chatContainer}>
      {selectedDocument ? (
        <div className={style.container}>
            <Text className={style.chatHeader}>{selectedDocument.chatName || selectedDocument.name}</Text>
            <ScrollableChat messages={messages} />
          </div>
      ) : (
        <Center className={style.placeholder}>
          <Text className={style.placeholderText}>
            Add new document 
          </Text>
        </Center>
      )}
      {selectedDocument && (
        <HStack className={style.MessageInputContainer}>
          <Input
            className={style.MessageInput}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Type your message here..."
            variant="filled"
          />
          <Button className={style.SendButton} onClick={handleSend}>Send</Button>
        </HStack>
      )}
    </Box>
  );
};

export default MessageBox;
