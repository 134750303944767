import React, { useState } from 'react';
import style from './subscription.module.css';
import { GiCheckMark } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import SubscriptionOptions from './SubscriptionOptions'; 

const Subcription = () => {
    const [showOptions, setShowOptions] = useState(false);
    const toggleModal = () => setShowOptions(!showOptions);

  return (
    <section className={style.container}>
      
      <h1 className={style.heading}>Subscription Model</h1>

      <div className={style.serviceContainer} >
        {/* Starting of the card 1 */}
        <article className={style.service}>
          <div className={style.serviceHead}>
            <h2 style={{color:'white'}}>BASIC</h2>
            <h4 className={style.price}>FREE OF COST</h4>
          </div>
          <ul className={style.serviceList}>
            <li>
              <GiCheckMark  className={style.serviceListIcon} />
              <p className={style.Titles}>Import/Add Case</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>CNR Search</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Party Search</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add People</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Calendar</p>
            </li>
            <li>
            <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Generate Documents</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Invoices & Bills</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Teammembers</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Search Cases By name or number</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Chat Bot</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Tasks</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Review Document</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Convert Document</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Generate Proxy</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Chat Box</p>
            </li>
            <li>
              <FaTimes className={style.serviceListIconCross} />
              <p className={style.Titles}>Notifications</p>
            </li>
           
          </ul>
        </article>
        {/* End of card 1 UI/UX */}

        {/* Starting of the card 2 wen Development */}
        <article className={style.service}>
          <div className={style.serviceHead}>
            <h2 style={{color:'white'}}>PREMIUM</h2>
            <div className={style.DiscountContainer}>
            <span className={style.BeforeDiscount}> ₹599</span>
            <p className={style.Discount}> Save 20%</p>
            </div>
            <h1 className={style.price}>₹ 499.00/mo</h1>

            <button className={style.selectButton} onClick={toggleModal}>Select</button>
          </div>
          <ul className={style.serviceList}>
          <li>
              <GiCheckMark  className={style.serviceListIcon} />
              <p className={style.Titles}>Import/Add Case</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>CNR Search</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Party Search</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add People</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Calendar</p>
            </li>
            <li>
            <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Generate Documents</p>
            </li>

            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Invoices & Bills</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Teammembers</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Search Cases By name or number</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Chat Bot</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Add Tasks</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Review Document</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Convert Document</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Generate Proxy</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Chat Box</p>
            </li>
            <li>
              <GiCheckMark className={style.serviceListIcon} />
              <p className={style.Titles}>Notifications</p>
            </li>
          </ul>
        </article>
        {/* End of card 2 web development*/}

        
      </div>
      {showOptions && <SubscriptionOptions closeModal={toggleModal} />}
      </section>
  );
};

export default Subcription;