import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaDownload, FaEdit, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import { data } from '../../evn/evn'; // Make sure this path is correct
import Sidebar from '../AdminComponents/SideBar';
import styles from './CaseManagement.module.css';

const CaseManagement = () => {
  const [cases, setCases] = useState([]);
  const [selectedCases, setSelectedCases] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('caseId');
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCases = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }
      try {
        const response = await axios.get(`${ip}/admin/updated-cases`, {
          headers: { 'x-auth-token': token },
        });
        setCases(response.data.cases);
        setSearchResults(response.data.cases);
      } catch (error) {
        console.error("There was an error fetching the cases:", error);
      }
    };
    fetchCases();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(cases);
    } else {
      const filteredCases = cases.filter(caseItem =>
        searchType === 'caseId'
          ? caseItem.id.toString().includes(event.target.value)
          : caseItem.user_id.toString().includes(event.target.value)
      );
      setSearchResults(filteredCases);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(cases);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const filteredCases = cases.filter(caseItem =>
        searchType === 'caseId'
          ? caseItem.id.toString().includes(searchTerm)
          : caseItem.user_id.toString().includes(searchTerm)
      );
      setSearchResults(filteredCases);
    }
  };

  const handleCaseSelection = (caseId) => {
    const newSelection = new Set(selectedCases);
    if (newSelection.has(caseId)) {
      newSelection.delete(caseId);
    } else {
      newSelection.add(caseId);
    }
    setSelectedCases(newSelection);
  };

  const handleSelectAllCases = (event) => {
    if (event.target.checked) {
      const allCaseIds = new Set(searchResults.map(caseItem => caseItem.id));
      setSelectedCases(allCaseIds);
    } else {
      setSelectedCases(new Set());
    }
  };

  const downloadSelectedCases = async () => {
    setIsDownloading(true); // Start showing the spinner
    const caseIds = Array.from(selectedCases).join(',');
    const ip = data.evn;
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.get(`${ip}/admin/download-selected-cases?caseIds=${caseIds}`, {
        headers: { 'x-auth-token': token },
        responseType: 'blob', // Important for downloading files
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'selected_cases.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("There was an error downloading selected cases:", error);
    } finally {
      setIsDownloading(false); // Stop showing the spinner
    }
  };
  

  const updateStatusForSelectedCases = async (newStatus) => {
    const caseIdsArray = Array.from(selectedCases);
    const ip = data.evn;
    const token = localStorage.getItem('adminToken');
    try {
      await axios.post(`${ip}/admin/update-cases-status`, {
        caseIds: caseIdsArray,
        newStatus,
      }, {
        headers: { 'x-auth-token': token },
      });
      alert('Status updated successfully');
      // Optionally reset selection and refresh cases list here
    } catch (error) {
      console.error("There was an error updating the cases status:", error);
    }
  };
  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.actions}>
        <button className={styles.b1} onClick={() => downloadSelectedCases()}>
    {isDownloading ? <FaSpinner className={styles.spinner} /> : <FaDownload />}
    {isDownloading ? 'Downloading...' : 'Download Selected'}
  </button>
          <button className={styles.b2} onClick={() => updateStatusForSelectedCases(1)}><FaEdit /> Update Selected Download Status</button>
        </div>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="caseId">Search by Case ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType === 'caseId' ? 'Case ID' : 'User ID'}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}><input type="checkbox" onChange={handleSelectAllCases} /></th>
              <th className={styles.tableHeader}>Case ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Admin Download status</th>
              <th className={styles.tableHeader}>Court Type</th>
              <th className={styles.tableHeader}>Court</th>
              <th className={styles.tableHeader}>CNR No.</th>
              <th className={styles.tableHeader}>Case No</th>
              <th className={styles.tableHeader}>Case Type</th>
              <th className={styles.tableHeader}>Title</th>
              <th className={styles.tableHeader}>Court No/Desg Name</th>
              <th className={styles.tableHeader}>Last Hearing Date</th>
              <th className={styles.tableHeader}>Next Hearing Date</th>
              <th className={styles.tableHeader}>Final Decision Date</th>
              <th className={styles.tableHeader}>District Code</th>
              <th className={styles.tableHeader}>District Name</th>
              <th className={styles.tableHeader}>State Code</th>
              <th className={styles.tableHeader}>State Name</th>
              <th className={styles.tableHeader}>Specific Jurisdiction Code</th>
              <th className={styles.tableHeader}>Specific Jurisdiction Name</th>
              <th className={styles.tableHeader}>Petitioner</th>
              <th className={styles.tableHeader}>Respondent</th>
              <th className={styles.tableHeader}>Reg No</th>
              <th className={styles.tableHeader}>Reg Year</th>
              <th className={styles.tableHeader}>Filing No</th>
              <th className={styles.tableHeader}>Filing Year</th>
              <th className={styles.tableHeader}>Updated</th>
              <th className={styles.tableHeader}>Client</th>
              <th className={styles.tableHeader}>Team</th>
              <th className={styles.tableHeader}>Client Designation</th>
              <th className={styles.tableHeader}>Opponent Party Name</th>
              <th className={styles.tableHeader}>Lawyer Name</th>
              <th className={styles.tableHeader}>Mobile No</th>
              <th className={styles.tableHeader}>Email Id</th>
              <th className={styles.tableHeader}>Type</th>
              <th className={styles.tableHeader}>Lawyer Type</th>
              <th className={styles.tableHeader}>Case File</th>
              <th className={styles.tableHeader}>Note</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((caseItem) => (
              <tr key={caseItem.id} className={styles.tableRow}>
                <td className={styles.tableCell}>
                  <input
                    type="checkbox"
                    checked={selectedCases.has(caseItem.id)}
                    onChange={() => handleCaseSelection(caseItem.id)}
                  />
                </td>
                <td className={styles.tableCell}>{caseItem.id}</td>
                <td className={styles.tableCell}>{caseItem.user_id}</td>
                <td className={styles.tableCell}>
        {caseItem.Admin_Download_Status === 1 ? (
          <FaCheckCircle style={{ color: 'green' }} />
        ) : (
          <FaTimesCircle style={{ color: 'red' }} />
        )}
      </td>
                <td className={styles.tableCell}>{caseItem.court_type}</td>
                <td className={styles.tableCell}>{caseItem.court}</td>
                <td className={styles.tableCell}>{caseItem.cino}</td>
                <td className={styles.tableCell}>{caseItem.case_no}</td>
                <td className={styles.tableCell}>{caseItem.type_name}</td>
                <td className={styles.tableCell}>{caseItem.title}</td>
                <td className={styles.tableCell}>{caseItem.court_no_desg_name}</td>
                <td className={styles.tableCell}>{caseItem.date_last_list}</td>
                <td className={styles.tableCell}>{caseItem.date_next_list}</td>
                <td className={styles.tableCell}>{caseItem.date_of_decision}</td>
                <td className={styles.tableCell}>{caseItem.district_code}</td>
                <td className={styles.tableCell}>{caseItem.district_name}</td>
                <td className={styles.tableCell}>{caseItem.state_code}</td>
                <td className={styles.tableCell}>{caseItem.state_name}</td>
                <td className={styles.tableCell}>{caseItem.establishment_code}</td>
                <td className={styles.tableCell}>{caseItem.establishment_name}</td>
                <td className={styles.tableCell}>{caseItem.petparty_name}</td>
                <td className={styles.tableCell}>{caseItem.resparty_name}</td>
                <td className={styles.tableCell}>{caseItem.reg_no}</td>
                <td className={styles.tableCell}>{caseItem.reg_year}</td>
                <td className={styles.tableCell}>{caseItem.fil_no}</td>
                <td className={styles.tableCell}>{caseItem.fil_year}</td>
                <td className={styles.tableCell}>{caseItem.updated}</td>
                <td className={styles.tableCell}>{caseItem.client}</td>
                <td className={styles.tableCell}>{caseItem.team}</td>
                <td className={styles.tableCell}>{caseItem.clientDesignation}</td>
                <td className={styles.tableCell}>{caseItem.opponentPartyName}</td>
                <td className={styles.tableCell}>{caseItem.lawyerName}</td>
                <td className={styles.tableCell}>{caseItem.mobileNo}</td>
                <td className={styles.tableCell}>{caseItem.emailId}</td>
                <td className={styles.tableCell}>{caseItem.type}</td>
                <td className={styles.tableCell}>{caseItem.lawyerType}</td>
                <td className={styles.tableCell}>{caseItem.case_file}</td>
                <td className={styles.tableCell}>{caseItem.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
  
};

export default CaseManagement;
