import React, { createContext, useState, useContext, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {data} from "../../evn/evn"
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(null);
  const ip = data.evn
  // const navigate = useNavigate();

  useEffect(() => {
    const pathsToSkip = [
      '/.admin/dashboard', 
      '/newcustomerlead', 
      '/proxypayment', 
      '/casemanagement', 
      '/allusers', 
      '/allwallets',
      '/allpeople', 
      '/allinvoices', 
      '/allbills', 
      '/allteammembers', 
      '/alltasks', 
      '/allgroups', 
      '/allcompanies', 
      '/allhearings',
      '/allappointments', 
      '/allchats', 
      '/allreviewdocs', 
      '/particularcasedocuments', 
      '/particularcasenotes', 
      '/particularcasepersons',
      '/particularcaseopponents',
      '/generatenotifications',
      '/.admin',
      '/contact',
      '/register',
      '/services',
      '/about',
      '/DemoRequestForm',
      '/contactformsub',
      '/demoformsub',
      '/verifyemail/', // Ensure trailing slash if URL structure uses it
      '/reset-password/', // Ensure trailing slash if URL structure uses it
      // Add more paths as needed
    ];
    
    const currentPath = window.location.pathname;
    const token = localStorage.getItem('token');

    const shouldSkip = pathsToSkip.some(path => currentPath.startsWith(path)) || currentPath.startsWith('/uploads');
    const isDynamicTokenPath = currentPath.startsWith('/verifyemail/') || currentPath.startsWith('/reset-password/');

    if (!token && !shouldSkip && !isDynamicTokenPath && currentPath !== '/login') {
      console.log("Redirecting to login..."); // Confirm redirection
      window.location.href = '/login';
      return; // Prevent further execution if redirecting
    }
    const sessionFlag = sessionStorage.getItem('isSessionActive');
    // Check if the token and session flag exist
    if (token && sessionFlag) {
      setIsLoggedIn(true);
      setAutomaticLogout();
      startSessionValidation();
    } else if (token && !sessionFlag) {
      logout(); // Logout if no session flag is found
    }
    // Set or reset the session flag
    sessionStorage.setItem('isSessionActive', 'true');
    // Cleanup
    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, ['logoutTimer']);

  const setAutomaticLogout = () => {
    // Clear any existing timers
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    // Set a new timer
    const timer = setTimeout(() => {
      logout(true);
    }, 3 * 3600000); // 3 hours in milliseconds 3 * 3600000

    setLogoutTimer(timer);
  };

  const startSessionValidation = () => {
    setInterval(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        logout(); // If there's no token, log out the user
        return;
      }
      axios.get(`${ip}/validate-session`, { headers: {
        'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
      } })
        .then(response => {
          if (!response.data.valid) {
            logout(); // If the session is not valid, log out the user
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            logout(); // If the server returns a 401 error, log out the user
          }
        });
    }, 1000); // Check every 60 seconds
  };

  const login = (token, sessionId) => {
    localStorage.setItem('token', token);
    localStorage.setItem('sessionId', sessionId);
    sessionStorage.setItem('isSessionActive', 'true');
    setIsLoggedIn(true);
    setAutomaticLogout();
    startSessionValidation();
  };

  const logout = (showAlert = false) => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('isSessionActive');
    setIsLoggedIn(false);
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setLogoutTimer(null);
    }
    if (showAlert) {
      window.alert('Your Session has Expired. Please Log In again');
    }
    // navigate('/login');
    console.log('Logging out and redirecting...');
    window.location.href = '/login';
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

