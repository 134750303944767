import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from '../AddCase/AddCase.module.css';
import axios from 'axios';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import {data} from '../../../evn/evn'
import SideNav from '../../utilities/SideNavBar/SideNav';
import { Button, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GiToaster } from 'react-icons/gi';
import { courts } from './courts';
import CustomDropdown from './CustomDropdown';


const UpdateCaseForm = () => {
  const [clientNames, setClientNames] = useState([]); // State to store client first names
  const [teamMembers, setTeamMembers] = useState([]); // State to store team member full names
  const [isForm1Submitted, setIsForm1Submitted] = useState(false);
  const [isForm2Submitted, setIsForm2Submitted] = useState(false);
  const [option, setOption] = useState('import'); // For tracking selected option
  const [titleError, setTitleError] = useState('');
  const [caseId, setCaseId] = useState(null);
  const navigate = useNavigate();
  const ip = data.evn
  const [cases, setCases] = useState([]);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedCourt, setSelectedCourt] = useState('');
  const [manualData, setManualData] = useState({
    cino: '',
    type_name: '',
    petparty_name: '',
    resparty_name: '',
    date_next_list: '', // Add other required fields here with default empty strings
});
  

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
  
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
  
    // Set up the event listener
    window.addEventListener("resize", handleResize);
  
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch client first names and populate the select options
    const fetchClientNames = async () => {
      try {
        const response = await axios.get(`${ip}/clientform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });
        // Extract the first names from the response data
        const firstNamesArray = response.data.map((client) => client.firstName);
        setClientNames(firstNamesArray);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch team member full names and populate the select options
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${ip}/teammemberform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });

        // Extract the full names from the response data
        const fullNamesArray = response.data.map((member) => member.fullName);
        setTeamMembers(fullNamesArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientNames(); // Call the fetchClientNames function when the component mounts
    fetchTeamMembers(); // Call the fetchTeamMembers function when the component mounts
  }, []);

const initialValue = {
    
    cino: '',
    court: '', 
    type_name: '',
    petparty_name: '',
    resparty_name: '',
    date_next_list: '',
    case_no: '',
    reg_year: '',
    reg_no: '',
    fil_year: '',
    fil_no: '',
    establishment_name: '',
    establishment_code: '',
    state_code: '',
    state_name: '',
    district_code: '',
    district_name: '',
    court_no_desg_name: '',
    date_of_decision: '',
    date_last_list: '',
    diaryno: '',
    update:'',
    note: '',
    courtType:''
};


const concernedPersonFormInitialValues = { 
  manage: '',
  client: '',
  addNewClient: '',
  team: '',
  addNewMember: '',
  type: '', 
  lawyerType: '',
  clientDesignation: '',
  opponentPartyName: '',
  lawyerName: '',
  mobileNo: '',
  emailId: '',
 };


 const getValidationSchema = () => {
  return Yup.object({
    courtType: Yup.string().required('Court selection is required'),
    cino: Yup.string().when("courtType", {
      is: "Other Courts",
      then: Yup.string().required('CNR No. is required'),
      // otherwise: Yup.string().notRequired(),
    }),

    type_name: Yup.string().required('Case type is required'),
    petparty_name: Yup.string().required('Petitioner is required'),
    resparty_name: Yup.string().required('Respondent is required'),
    date_next_list: Yup.string().required('Upcoming Hearing Date is required'),
    // Include other fields as needed
  });
};

const concernedPersonFormValidationSchema = Yup.object().shape({ 
  opponentPartyName: Yup.string().required('Opponent Party Name is required'), 
 });



const handleSubmit = async (values, { setSubmitting }) => {
    try {
        const response = await axios.post(`${ip}/updatecase`, { cases: [values] }, {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        });
        
        // Assuming the backend returns { caseId: X, message: 'Case added successfully' }
        const { caseId } = response.data;
        if (caseId) {
            toast('Case submitted successfully!');
            console.log('Case submitted successfully!');
            setCaseId(caseId); // Update the state with the received caseId
            // Allow the second form to be displayed
        } else {
            // Handle case where caseId is not returned
            toast('Case submitted successfully');
            console.log('Case submitted successfully');
        }

  // Within your handleSubmit function
} catch (error) {
    console.error('Error submitting case', error);
    const errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Error submitting case';
    toast.error(errorMessage); // Displaying the error message from backend
}
 finally {
        setSubmitting(false); // Stop form submission process
    }
};

const handleSubmit2 = async () => {
  
    try {
        const endpointUrl = `${ip}/updatecase`;
       

        let dataToSubmit = option === 'import'
            ? cases.filter(c => c.cino && c.cino.trim() !== '')
            : [];

        if (option === 'import' && dataToSubmit.length === 0) {
            alert('All cases must have a valid CNR No.');
            return;
        }
       
        const response = await axios.post(endpointUrl, { cases: dataToSubmit }, {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        });

        toast('Case update submitted successfully!');
        setCases([]);
        setIsFileLoaded(false);

    // Within your handleSubmit2 function
} catch (error) {
    console.error(error);
    const errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Error submitting case update';
    toast.error(errorMessage); // Displaying the error message from backend
}
};

const handleConcernedPersonSubmit = async (values, { setSubmitting }) => {
    if (!caseId) {
        toast('No case associated. Please submit the first form.');
        return;
    }
    try {
        const response = await axios.post(`${ip}/concernedperson`, { ...values, caseId }, {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        });
        toast('Concerned Person and Opponent Added successfully!');
        setIsForm2Submitted(true); // Optionally, use this state to control UI flow
        // Reset or navigate as needed
    } catch (error) {
        console.error('Error submitting concerned person details', error);
        toast('Error submitting concerned person details');
    } finally {
        setSubmitting(false);
    }
};

const handleOptionChange = (event) => {
    console.log('Option change:', event.target.value); // Check the option being selected
    setOption(event.target.value);
    setCases([]);
    setIsFileLoaded(false);
    setValidationErrors({});
};

const handleFileRead = async (event) => {
  const file = event.target.files[0];
  if (file && file.type === "text/plain") {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      try {
        // Assuming the file content is a JSON array of JSON strings
        const parsedCases = JSON.parse(text).map(caseStr => JSON.parse(caseStr));
        setCases(parsedCases);
        setIsFileLoaded(true);
      } catch (error) {
        alert("Error parsing the file");
        setIsFileLoaded(false);
      }
    };
    reader.readAsText(file);
  } else {
    toast.error("Use valid file type");
    setIsFileLoaded(false);
  }
};

// const handleStateSelectionChange = (event) => {
//   setSelectedState(event.target.value);
// };

const handleDropdownSelection = (value, setFieldValue) => {
  setSelectedCourt(value);
  // Parse the value to extract court type, state, and district
  const [courtType, state, district] = value.split(' - ');
  setFieldValue('courtType', courtType);
  setFieldValue('state', state);
  setFieldValue('districtCourt', district);
};

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
      <div className={styles.container}>
      <h2 style={{textAlign:'center'}} className={styles.heading}>ADD CASE</h2>
      <div className={styles.radioGroup} style={{display:'flex', flexDirection:'column', textAlign:'center'}}>
                        
                        <input
                            type="radio"
                            value="import"
                            id="import"
                            name="updateOption"
                            className={styles.radio}
                            checked={option === 'import'}
                            onChange={handleOptionChange}
                        />
                        <label htmlFor="import" className={styles.label}>Import Case</label>
                    
                        <input
                            type="radio"
                            value="manual"
                            id="manual"
                            name="updateOption"
                            className={styles.radio}
                            checked={option === 'manual'}
                            onChange={handleOptionChange}
                        />
                        <label htmlFor="manual" className={styles.label}>Add Case Manually</label>
                    </div>
                    {option === 'import' && (
                        <div className={styles.fileInputContainer}>
                          <div className={styles.column}>
                        <label htmlFor="fileInput" className={styles.label}>Upload Case File<span className={styles.required}>*</span></label>
                        <input type="file" className={styles.fileInput} onChange={handleFileRead} accept=".txt" id="fileInput" />
                        
                        </div>
                          <div className={styles.BtnContainer}>
                            <Tooltip title="Submit Form">

                                <button  onClick={handleSubmit2} disabled={option === 'import' ? !isFileLoaded : !manualData.cino || manualData.cino.trim() === ''} className={styles.submitButton} >
                                    SUBMIT
                                </button>
                                <ToastContainer />
                                </Tooltip>
                                    
                                <NavLink to={"/dashboard/Importcase"}>

                                <Tooltip title="Cancel Form">
                                <button type="submit" className={`${styles.submitButton}, ${styles.CancelButton}`}>CANCEL</button>
                                </Tooltip>
                                </NavLink>
                                </div> 
                        </div>


                    )}

      {option === 'manual' && !isForm1Submitted && ( 
        <Formik
        initialValues={initialValue}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => (
          <Form>
            <div className={styles.row}>
              <div className={styles.column}>
                <label className={styles.label}>Court:</label>
                <CustomDropdown options={courts} onSelection={(value) => handleDropdownSelection(value, setFieldValue)} />
                <ErrorMessage name="courtType" component="div" className={styles.error} />
              </div>
            </div>

            {(selectedCourt.includes("District Courts")) || 
            (selectedCourt.includes("High Court") && !selectedCourt.includes("Delhi")) ? (
              <div className={styles.row}>
                <div className={styles.column}>
                  <label className={styles.label} htmlFor="cino">CNR No<span className={styles.required}>*</span>:</label>
                  <Field type="text" id="cino" name="cino" placeholder="CNR No." className={styles.input} />
                  <ErrorMessage name="cino" component="div" className={styles.error} />
                </div>
              </div>
            ) : null}


            <div className={styles.row}>     
            {/* Case Type (Radio Buttons) */}
           
            <div className={styles.column}>
            <label className={styles.label}>Case Type <span className={styles.required}>*</span></label>
            <Field type="text" name="type_name" placeholder="Case type" className={styles.input} />
              <ErrorMessage name="type_name" component="div" className={styles.error} />
              {titleError && <div className={styles.error}>{titleError}</div>}  
              </div>


               {/* Petitioner */}
            <div className={styles.column}>
             <label className={styles.label}>Petitioner<span className={styles.required}>*</span></label>
             <Field type="text" name="petparty_name" placeholder="Petitioner" className={styles.input} />
             <ErrorMessage name="petparty_name" component="div" className={styles.error} />
              {titleError && <div className={styles.error}>{titleError}</div>} 
            </div>
            {/* Respondent */}
            <div className={styles.column}>
             <label className={styles.label}>Respondent <span className={styles.required}>*</span></label>
             <Field type="text" name="resparty_name" placeholder="Respondent" className={styles.input} />
             <ErrorMessage name="resparty_name" component="div" className={styles.error} />
              {titleError && <div className={styles.error}>{titleError}</div>} 
            </div>
            <div className={styles.column}>
            <label className={styles.label}>Upcoming Hearing Date<span className={styles.required}>*</span></label>
              <Field type="date" name="date_next_list" placeholder="Upcoming Hearing Date" className={styles.input} />
              <ErrorMessage name="date_next_list" component="div" className={styles.error} />
              {titleError && <div className={styles.error}>{titleError}</div>}
            </div>
              </div>
          
            
            
            
            <div className={styles.row}>       
            
            {/* Role-Specific Jurisdiction */}
            <div className={styles.column}>
             <label className={styles.label}>Role-Specific Jurisdiction</label>
             <Field type="text" name="establishment_name" placeholder="Role-Specific Jurisdiction" className={styles.input} />
            </div>

            {/* Specific Jurisdiction Code */}
            <div className={styles.column}>
             <label className={styles.label}>Specific Jurisdiction Code</label>
             <Field type="text" name="establishment_code" placeholder="Specific Jurisdiction Code" className={styles.input} />
            </div>

            {/* State Code */}
            <div className={styles.column}>
             <label className={styles.label}>State Code</label>
             <Field type="text" name="state_code" placeholder="State Code" className={styles.input} />
            </div>

            <div className={styles.column}>
            <label className={styles.label}>Filing No.</label>
             <Field type="text" name="fil_no." placeholder="Filing No." className={styles.input} />
            </div>
            </div>
            <div className={styles.row}>

            {/* State Name */}
            <div className={styles.column}>
             <label className={styles.label}>State Name</label>
             <Field type="text" name="state_name" placeholder="State Name" className={styles.input} />
            </div>
            
            {/* District Code */}
            <div className={styles.column}>
             <label className={styles.label}>District Code</label>
             <Field type="text" name="district_code" placeholder="District Code" className={styles.input} />
            </div>
            


            {/* District Name */}
            
            <div className={styles.column}>
            <label className={styles.label}>District Name</label>
              <Field type="text" name="district_name" placeholder="District Name" className={styles.input} />
            </div>

            {/* Date of Filing (Date Picker) */}
           
            <div className={styles.column}>
            <label className={styles.label}>Judicial Role</label>
              <Field type="text" name="court_no_desg_name" placeholder="Judicial Role" className={styles.input} />
              
            </div>
            </div>
            <ErrorMessage name="court_no_desg_name" component="div" className={styles.error1} />

            <div className={styles.row}>
            
            {/* Final Decision Date */}
            <div className={styles.column}>
            <label className={styles.label}>Final Decision Date</label>
              <Field type="text" name="date_of_decision" placeholder="Final Decision Date" className={styles.input} />
            </div>

            {/* Last Hearing Date */}
            <div className={styles.column}>
            <label className={styles.label}>Last Hearing Date</label>
              <Field type="date" name="date_last_list" placeholder="Last Hearing Date" className={styles.input} />
            </div>
            
            {/* Upcoming Hearing Date */}
            {/* <div className={styles.column}>
            <label className={styles.label}>Filing No.</label>
             <Field type="text" name="fil_no." placeholder="Filing No." className={styles.input} />
           
            </div> */}
            {/* Diary No. */}
            <div className={styles.column}>
            <label className={styles.label}>Diary No.</label>
             <Field type="text" name="diaryno" placeholder="Diary No." className={styles.input} />
            </div>
              {/* Update */}
              <div className={styles.column}>
            <label className={styles.label}>Update</label>
              <Field as="select" name="update" className={styles.input}>
                <option value="">Select</option>
                <option value="True">True</option>
                <option value="False"> False </option>
                
              </Field>
              <ErrorMessage name="caseStatus2" component="div" className={styles.error} />
            </div>
           

            </div>

           
            
            <div className={styles.row}>       

            {/* Registration No. */}
            <div className={styles.column}>
             <label className={styles.label}>Registration No.</label>
             <Field type="text" name="reg_no" placeholder="Registration" className={styles.input} />
            </div>
          

             {/* Registration Year */}
            
             <div className={styles.column}>
              <label className={styles.label}>Registration Year:</label>
              <Field type="text" name="reg_year" placeholder="Registration Year" className={styles.input} />
            </div>

            {/* Filing Year */}
            <div className={styles.column}>
             <label className={styles.label}>Filling Year</label>
             <Field type="text" name="fil_year" placeholder="Filling" className={styles.input} />
            </div>
            
            </div>


            <div className={styles.BtnContainer}>
              <button type="submit" className={styles.submitButton} >Submit</button>
              <ToastContainer />
              <NavLink to={"/dashboard/Importcase"}>
              <button type="submit" className= {styles.CancelButton}>Cancel</button>
              </NavLink>
              </div>
          </Form>
        )}
      </Formik>
      )}
</div>
    </>
  );
};

export default UpdateCaseForm;
