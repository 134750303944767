import React from 'react';
import style from './style/Header.module.css';
import { BsFillBellFill, BsFillEnvelopeFill, BsJustify, BsPersonCircle, BsSearch } from 'react-icons/bs';

const Header = ({OpenSidebar}) => {
    return (
        <header className={style.Header}>
        <div className={style.MenuIcon}>
            <BsJustify className={style.Icon} onClick={OpenSidebar}/>
        </div>
        <div className={style.HeaderLeft}>
            <BsSearch  className={style.Icon}/>
        </div>
        <div className={style.HeaderRight}>
            <BsFillBellFill className={style.Icon}/>
            <BsFillEnvelopeFill className={style.Icon}/>
            <BsPersonCircle className={style.Icon}/>
        </div>
    </header>
    );
}

export default Header;
