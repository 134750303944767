import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import style from './login.module.css';
import { useAuth } from './AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import {data} from "../../evn/evn"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

// Validation schema using Yup
const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Username is Required'),
  password: Yup.string()
  .required('Password is required')
  .test(
    'password-requirements',
    'Password must be at least 8 characters, contain at least one uppercase letter, one digit, and one symbol: & % $ # @ ^ _ ~',
    value => {
      const isValidLength = value && value.length >= 8;
      const hasUppercase = /(?=.*[A-Z])/.test(value);
      const hasDigit = /(?=.*\d)/.test(value);
      const hasSpecialChar = /(?=.*[&%$#@^_~])/.test(value);
      return isValidLength && hasUppercase && hasDigit && hasSpecialChar;
    }
  ),
});
const ip = data.evn

const LogIn = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = async (values, actions) => {
    try {
      const response = await axios.post(`${ip}/login`, {
        username: values.email,
        password: values.password
      });
      const { token, sessionId } = response.data; // Assuming the server sends back both token and sessionId
  
      // Save both token and sessionId in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('sessionId', sessionId); // Store the session ID
  
      // Update login call to include sessionId
      login(token, sessionId); // Assuming you've adjusted the login function to accept sessionId
  
      toast.success("Login Successfully", {
        onClose: () => {
          // Redirect after showing toast
          window.location.href = '/dashboard';
        },
        autoClose: 1000, // Delay for the toast
      });
    } catch (error) {
      if (!error.response) { // Assuming network error or server not reachable
        navigate('/maintenance');
      } else {
        console.error('Login failed:', error.response.data.error);
        actions.setFieldError('general', 'Login failed: ' + error.response.data.error);
        toast.error('Login failed: ' + error.response.data.error);
      }
      actions.setSubmitting(false);
    }
  };
  

  return (
    <div className={style.midContainer}>
      <ToastContainer autoClose={5000}  />
      <div className={style.midLeftContainer}>
        <img src="https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg?cs=srgb&dl=pexels-sora-shimazaki-5669602.jpg&fm=jpg" alt="logo2" />
      </div>
      <div className={style.midRightContainer}>
        <div className="container-head">
          <h1><span style={{ color: 'var(--color-accent2)' }}>LAW</span>FAX <span style={{ color: 'var(--color-accent2)' }}>AI</span></h1>
          <h2 className={style.Para}>Log in to your account</h2>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting, errors }) => (
            <Form className={style.formContainer}>
              <Field
                className={style.input}
                type="email"
                name="email"
                placeholder="What is your email?"
              />
              <ErrorMessage name="email" component="div" className={style.error} />
              <div className={style.inputContainer}>
              <Field
              className={style.input}
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
            />
            <button
              type="button"
              className={style.visibilityButton}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
            </div>
              <ErrorMessage  name = "password" component="div" className={style.error} />
              <button className={style.submit} type="submit" disabled={isSubmitting}>
                Continue
              </button>
              <NavLink className={style.forgotPass} to={"/forgot"}>Forgot password?</NavLink>
            </Form>
          )}
        </Formik>
        <small>Don't have an account? <NavLink className={style.signUp} to={"/register"}>Register</NavLink></small>
      </div>
    </div>
  );
};

export default LogIn;
