import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { links } from "./data"; // Assuming formLinks is exported from './data'
import style from "./sidenav.module.css";
import { FaBars, FaBell, FaCaretDown, FaCaretUp, FaWallet } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { NotificationContext } from '../Notifications/NotificationsContext'; // Adjust path as needed
import {data} from '../../../evn/evn'
import WalletDisplayComponent from "../../form/Wallet/Wallet";


const SideNav = ({onClose}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track dropdown visibility
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [proxy, setProxy] = useState([]);
  const [resetNotifications, setResetNotifications] = useState(false);
  const { notifications, setNotifications } = useContext(NotificationContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isNavShowing, setIsNavShowing] = useState(false);
  const toggleWalletModal = () => setShowWalletModal(!showWalletModal);
  const [showWalletModal, setShowWalletModal] = useState(false);

  const ip = data.evn


// When you want to close the modal explicitly
const handleCloseWalletModal = () => setShowWalletModal(false);

  
  const resetNotificationCount = async () => {
    try {
      await axios.put(`${ip}/dashboard/user/notifications/viewed`, {}, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      // Update the notification count to 0
      setNotificationsCount(0);

      // Update notifications state as well (optional based on your logic)
      setNotifications(notifications.map(notification => ({ ...notification, isViewed: true })));
      
    } catch (error) {
      console.error('Error marking notifications as viewed:', error);
    }
  };

  // Fetch the count of unviewed notifications
  useEffect(() => {
    axios.get(`${ip}/dashboard/user/notifications/count`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    })
    .then((response) => {
      setNotificationsCount(response.data.count);
    })
    .catch((error) => {
      console.error('Error fetching notification count:', error);
    });
  }, []);
  
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };




  const renderSubmenu = (submenu) => (
    <ul>
      {submenu.map((item, index) => (
        <li key={index}>
          <NavLink to={item.path}>{item.name}</NavLink>
        </li>
      ))}
    </ul>
    );

  return (
    <>
      <div className={style.container}>
        {!isOpen ? (
          <FaBars className={style.hamBurger} onClick={handleOpen} />
        ) : (
          <MdClose className={style.closeIcon} onClick={handleClose} />
        )}

        <nav className={`${style.navContainer} ${isOpen ? style.open : style.close}`}>
          <div className={style.linksContainer}>
            <ul>
            {links.map(({ name, path, submenu, bell, msg }, index) => (
              <li key={index} 
                  onMouseEnter={() => submenu && setHoveredMenuItem(name)}
                  onMouseLeave={() => submenu && setHoveredMenuItem(null)}>
                <NavLink to={path}>
                  {name}
                  <div className={style.BellIcon} onClick={resetNotificationCount}>
                  {msg}

                  </div>
                   {bell && (
      <div className={style.notificationWrapper} onClick={resetNotificationCount}>
        {bell}
        <span className={style.notificationsCount}>{notificationsCount}</span>
        {msg}
      </div>
    )}
                </NavLink>

                {/* Show submenu if hovered */}
                {submenu && hoveredMenuItem === name && (
                  <div className={style.DropDown}>
                    {renderSubmenu(submenu)}
                  </div>
                )}
                
              </li>
            ))}
            {/* <span className={style.NotifiCount}>{notificationsCount}</span> */}
            {/* <Link to={'/dashboard/notifications'}><FaBell /></Link> */}
            <li className={style.LinksList} onClick={toggleWalletModal}>
                        <FaWallet size="24px" />
                    </li>

            </ul>
          </div>
        </nav>
      </div>
      {showWalletModal && (
  <div className={style.ModalOverlay} onClick={toggleWalletModal}>
    <div className={style.ModalContent} onClick={(e) => e.stopPropagation()}>
      <WalletDisplayComponent onClose={handleCloseWalletModal} />
    </div>
  </div>
)}
    </>
  );
};

export default SideNav;
