import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import { useNavigate } from 'react-router-dom';
import styles from './ReviewDocManagement.module.css'; // Ensure this path matches your CSS file structure

const ReviewDocManagement = () => {
  const [reviewDocs, setReviewDocs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchReviewDocs = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }
      

      try {
        const response = await axios.get(`${ip}/admin/review-docs`, {
          headers: { 'x-auth-token': token },
        });
        setReviewDocs(response.data.reviewDocs);
        setSearchResults(response.data.reviewDocs);
      } catch (error) {
        console.error("There was an error fetching the review documents:", error);
      }
    };

    fetchReviewDocs();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(reviewDocs);
    } else {
      const filteredReviewDocs = reviewDocs.filter(doc =>
        searchType === 'id' ? doc.id.toString().includes(event.target.value)
                            : doc.user_id.toString().includes(event.target.value));
      setSearchResults(filteredReviewDocs);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(reviewDocs);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchDocs = reviewDocs.filter(doc => {
        if (searchType === 'id') return doc.id.toString() === searchTerm;
        return doc.user_id.toString() === searchTerm;
      });
      setSearchResults(exactMatchDocs);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Review Method</th>
              <th className={styles.tableHeader}>Contact Method</th>
              <th className={styles.tableHeader}>Mobile No</th>
              <th className={styles.tableHeader}>Email</th>
              <th className={styles.tableHeader}>Text</th>
              <th className={styles.tableHeader}>Document</th>
              <th className={styles.tableHeader}>Payment ID</th>
              
            </tr>
          </thead>
          <tbody>
            {searchResults.map((doc) => (
              <tr key={doc.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{doc.id}</td>
                <td className={styles.tableCell}>{doc.user_id}</td>
                <td className={styles.tableCell}>{doc.reviewMethod}</td>
                <td className={styles.tableCell}>{doc.contactMethod}</td>
                <td className={styles.tableCell}>{doc.mobileNo}</td>
                <td className={styles.tableCell}>{doc.email}</td>
                <td className={styles.tableCell}>{doc.text}</td>
                <td className={styles.tableCell}>{doc.file}</td>
                <td className={styles.tableCell}>{doc.paymentId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReviewDocManagement;
