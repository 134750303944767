// ProfileDropdown.js
import React, { useState, useEffect, useRef } from "react";
import style from './Profile.module.css';
import { data } from '../../../../evn/evn'
import EditIcon from '@mui/icons-material/Edit';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { FaSun, FaMoon } from "react-icons/fa";

import 'react-toastify/dist/ReactToastify.css';

const ProfileDropdown = ({ onClose, handleSignOut, user }) => {
  const [formData, setFormData] = useState({
    username:"",
    name: "",
    age: "",
    mobile: "",
    lawyerType: "", 
    experience: "", 
    avatar_url: "",
    photo: null,
  });
  const [editMode, setEditMode] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const ip = data.evn;
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${ip}/profile`, {
          method: "GET",
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching profile data. Status: ${response.status}`);
        }
        const data = await response.json();
        // Check and prepend domain if necessary
        data.avatar_url = data.avatar_url.startsWith('http') ? data.avatar_url : `${ip}${data.avatar_url}`;
        setFormData(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        toast.error('Error fetching profile data: ' + error.message);
      }
    };
    fetchProfileData();
  }, [user, ip]);

  // Added ip as a dependency if it's expected to change

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setFormData((prevData) => ({
        ...prevData,
        photo: e.target.files[0],
        avatar_url: URL.createObjectURL(e.target.files[0]) // Create a URL for preview
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      if (key === 'photo' && formData[key]) {
        formDataToSubmit.append(key, formData[key]);
      } else if (key !== 'photo') {
        formDataToSubmit.append(key, formData[key]);
      }
    }
    
    fetch(`${ip}/profile/edit/updatee`, {
      method: "PATCH",
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        // "Content-Type": "application/json",
      },
      body: formDataToSubmit
    })
    .then(async (response) => {
      const data = await response.json(); 
      if (!response.ok) {
        
        throw new Error(data.error || 'Unknown error occurred');
      }
      return data; 
    })
    .then((data) => {
      console.log("Profile updated successfully:", data);
      toast.success('Profile updated successfully', {
        onClose: () => {
          
          setTimeout(() => {
            onClose(); 
            setEditMode(false); 
          }, 500); 
        }
      });
    })
    .catch((error) => {
      console.error("Error updating profile:", error);
      toast.error(error.toString());
    });
  };
  
  const handleThemeChange = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(theme);
  }, [theme]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={style.ProfileDropdown} ref={dropdownRef}>
      <ToastContainer />
      {/* Toggle Edit Mode */}
      <div className="theme-switcher" onClick={handleThemeChange} title="Switch Theme">
        {theme === 'light' ? <FaSun /> : <FaMoon />}
      </div>
      <EditIcon onClick={() => setEditMode(!editMode)} className={style.EditIcon}/>
        
      {editMode ? (
        // Edit mode: Render form for editing profile details
        <form className={style.FormContainer} onSubmit={handleSubmit}>
        <div className={style.avatarIconContainer}>
    <img src={formData.avatar_url || "default-avatar-placeholder-url"} alt="User Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
    <label htmlFor="icon-button-file">
      <input accept="image/*" id="icon-button-file" type="file" onChange={handleFileChange} style={{ display: 'none' }}/>
      <PhotoCamera className={style.avatarIcon} />
    </label>
  </div>
          <button className={style.Button1} onClick={handleSignOut}>SIGN OUT</button>
          <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} className={style.Input} />
          <input type="text" name="age" placeholder="Enrollment No." value={formData.age} onChange={handleChange} className={style.Input} />
          <input type="text" name="mobile" placeholder="Mobile No." value={formData.mobile} onChange={handleChange} className={style.Input} />
          <select name="lawyerType" value={formData.lawyerType} onChange={handleChange} className={style.Input}>
  <option value="" disabled>Select Lawyer Type</option>
  <option value="Criminal lawyers">Criminal lawyers</option>
  <option value="Environmental lawyers">Environmental lawyers</option>
  <option value="Family lawyers">Family lawyers</option>
  <option value="Corporate lawyers">Corporate lawyers</option>
  <option value="Civil lawyers">Civil lawyers</option>
  <option value="Intellectual property lawyers">Intellectual property lawyers</option>
  <option value="Tax lawyer">Tax lawyer</option>
  <option value="Cyber lawyers">Cyber lawyers</option>
  <option value="Estate planning lawyers">Estate planning lawyers</option>
  <option value="Worker's compensation lawyer">Worker's compensation lawyer</option>
  <option value="Public interest lawyers">Public interest lawyers</option>
  <option value="Medical malpractice lawyers">Medical malpractice lawyers</option>
  <option value="Merger and acquisition lawyer">Merger and acquisition lawyer</option>
  <option value="Labour lawyers">Labour lawyers</option>
  <option value="Bankruptcy lawyers">Bankruptcy lawyers</option>
  <option value="Securities lawyer">Securities lawyer</option>
  <option value="Military lawyers">Military lawyers</option>
  <option value="Contract lawyers">Contract lawyers</option>
  <option value="Government lawyers">Government lawyers</option>
  <option value="Immigration lawyers">Immigration lawyers</option>
</select>

          <input type="text" name="experience" placeholder="Experience" value={formData.experience} onChange={handleChange} className={style.Input} />
          <button type="submit" className={style.Button}>Save Changes</button>
        </form>
      ) : (
        // Display mode: Show profile details
        <>
        <img src={formData.avatar_url || "default-avatar-placeholder-url"} alt="User Avatar" style={{ width: '70px', height: '70px', borderRadius: '50%', display: 'block', margin: '10px auto' }} />
           <label style={{textAlign:'center'}} className={style.Label} value={formData.name}>
          {formData.name}
        </label>
          <label style={{textAlign:'center', fontSize:'0.8rem'}} className={style.Label} value={formData.username}>{formData.username}</label>
          
          <form className={style.FormContainer}>
        <label className={style.Label}>
          Enrollment No.: {formData.age}
        </label>
        <label className={style.Label}>
          Mobile No.: {formData.mobile}
        </label>
        <label className={style.Label} value={formData.lawyerType}>
          Lawyer Type: {formData.lawyerType}
        </label>
        <label className={style.Label} value={formData.experience}>
          Work Experience:{formData.experience}
        </label>
      </form>
      <button className={style.Button} onClick={handleSignOut}>SIGN OUT</button>
        </>
      )}
    </div>
  );
};

export default ProfileDropdown;
