import React, { useEffect, useState } from 'react';
import style from './ProxyData.module.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import SideNav from '../../utilities/SideNavBar/SideNav';
import {Button, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {data} from '../../../evn/evn'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const ProxyData = () => {
  const [proxyActivity, setProxyActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const ip = data.evn
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [currentPage, setCurrentPage] = useState(1);
const [casesPerPage, setCasesPerPage] = useState(5);

const indexOfLastCase = currentPage * casesPerPage;
const indexOfFirstCase = indexOfLastCase - casesPerPage;
const currentCases = proxyActivity.slice(indexOfFirstCase, indexOfLastCase);

// Pagination handlers
const handleNextPage = () => {
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage(currentPage - 1);
};
  useEffect(() => {
    checkSubscriptionStatus();
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Make an HTTP GET request to fetch proxy activity data from the backend
    axios.get(`${ip}/dashboard/user/proxy-activity`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'), // Include authentication token if required
      },
    })
    .then((response) => {
      setProxyActivity(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);
  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };
  const handleDelete = (activityId) => {
    // Make an HTTP DELETE request to delete the proxy activity with the given ID
    axios.delete(`${ip}/dashboard/user/proxy-activity/${activityId}`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'), // Include authentication token if required
      },
    })
    .then((response) => {
      // Remove the deleted activity from the state
      setProxyActivity((prevActivity) => prevActivity.filter((activity) => activity.id !== activityId));
    })
    .catch((error) => {
      console.error('Error deleting data:', error);
    });
  };

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}

    <div className={style.container}>
      <h2 className={style.heading}>PROXY COUNCIL STACK</h2>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
        <div className={style.tableContainer}>
      <table className={style.table}>
        <thead className={style.tableHead}>

        
        <tr>
            <th>Creator </th>
            <th>Acceptor</th>
            <th>Acceptance Date</th>
            <th>Court</th>
            <th>Hearing Date</th>
            <th>State</th>
            <th>Actions</th>

          </tr>

          </thead>

          <tbody className={style.tableBody} >
          {currentCases.map((activity) => (
            <tr className={style.CaseInfo} key={activity.id}>
              
              <td> {activity.creatorFullName}</td>
              <td> {activity.acceptorFullName}</td>
              <td> {activity.acceptanceDate}</td>
              <td> {activity.type}</td>
              <td> {activity.hearingDate}</td>
              <td> {activity.zipStateProvince}</td>

              <Tooltip title="Delete" disableInteractive>
                <button className={style.btn} onClick={() => handleDelete(activity.id)}><DeleteIcon /></button>
              </Tooltip>
            </tr>
          ))}
            </tbody>
            {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(proxyActivity.length / casesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
            </table>
            </div>
        </div>
           )}
    </div>
</>
  );
};

export default ProxyData;
