import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import {data} from '../../../../evn/evn';
import styles from './Concern&Opponent.module.css';
import { toast, ToastContainer } from 'react-toastify';
import People from './People';
import Modal from '../../Client/People/ModelPop/Modal'
import ConcernPersonTable from './Tables/ConcernPersonTable';


const ConcernPerson = ({ switchToPeopleTab, switchToMembers }) => { 
  const { caseId } = useParams();
  const ip = data.evn;
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const [clientNames, setClientNames] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({
    client: '',
    team: '',
    type: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  
 
  // const [ switchToPeopleTab, setswitchToPeopleTab]  = useState();
  const handleAddNewPeopleClick = () => {
    switchToPeopleTab();
  };
  const handleAddNewMemberClick = () => {
    switchToMembers();
  };

  useEffect(() => {
    const fetchClientNames = async () => {
      try {
        const response = await axios.get(`${ip}/clientform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        setClientNames(response.data.map(client => client.firstName));
      } catch (error) {
        toast.error(error);
      }
    };

    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${ip}/teammemberform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        setTeamMembers(response.data.map(member => member.fullName));
      } catch (error) {
        toast.error(error);
      }
    };
    

    fetchClientNames();
    fetchTeamMembers();
    
  }, [caseId, ip]);

  const concernedPersonFormValidationSchema = Yup.object().shape({
    client: Yup.string().required('Client is required'),
    team: Yup.string().required('Team is required'),
    type: Yup.string().required('Type is required'),
  });

  
  const handleConcernedPersonSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!caseId) {
      toast.error('Error: No associated case found.');
      return;
    }

    try {
      await axios.post(`${ip}/person`, { ...values, caseId }, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      toast.success('Concerned Person Added successfully!');
      resetForm();
      setTimeout(() => {
        navigate(0); // Reloads the current page
      }, 2000);
      setSubmitting(false);
    } catch (error) {
      toast.error('Error adding concerned person.');
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize // Important to reinitialize the form when initial values change
        validationSchema={concernedPersonFormValidationSchema}
        onSubmit={handleConcernedPersonSubmit}
      >
 {({ values }) => (   
         <Form>
            <div className={styles.row}>
              <div className={styles.column}>
                <label className={styles.label}>People:</label>
                <Field as="select" name="client" className={styles.selectClient}>
                  <option value="">Select</option>
                  {clientNames.map((firstName, index) => (
                    <option key={`${firstName}-${index}`} value={firstName}>
                      {firstName}
                    </option>
                  ))}
                </Field>
                {/* <NavLink className={styles.linkClient} to="/dashboard/peopleform">Add New People</NavLink> */}
                {/* <NavLink className={styles.linkClient}  onClick={handleAddNewPeopleClick} >Add New People</NavLink> */}
              </div>
              <div className={styles.columnTeam}>
                <label className={styles.labelTeam}>Team:</label>
                <Field as="select" name="team" className={styles.selectTeam}>
                  <option value="">Select an option</option>
                  {teamMembers.map((fullName, index) => (
                    <option key={`${fullName}-${index}`} value={fullName}>
                      {fullName}
                    </option>
                  ))}
                </Field>
                {/* <NavLink className={styles.linkClient} onClick={handleAddNewMemberClick} >Add New Member</NavLink> */}
              </div>
            <div className={styles.formGroup}>
              <div className={styles.column}>
                <label className={styles.label} htmlFor="type">Type</label>
                <Field as="select" name="type" className={styles.selectCd}>
                  <option value="">Select Type</option>
                  <option value="Client">Client</option>
                  <option value="Lawyers">Lawyers</option>
                  <option value="OpposingClient">Opposing Client</option>
                  <option value="Witness">Witness</option>
                </Field>
                <ErrorMessage name="type" component="div" className={styles.error} />
              </div>
            </div>
            </div>
            
{/* Conditional Lawyer Type Dropdown */}
{values.type === "Lawyers" && (
  <div className={styles.formGroup}>
    <div className={styles.column}>
    <label className={styles.label} htmlFor="lawyerType">Lawyer Type:</label>
    <Field as="select" name="lawyerType" className={styles.selectCd}>
      <option value="">Select Lawyer Type</option>
      <option value="CorporateLawyer">Corporate Lawyer</option>
      <option value="CriminalDefenseLawyer">Criminal Defense Lawyer</option>
      <option value="FamilyLawyer">Family Lawyer</option>
      <option value="TaxLawyer">Tax Lawyer</option>
      <option value="IntellectualPropertyLawyer">Intellectual Property Lawyer</option>
      <option value="EmploymentLawyer">Employment Lawyer</option>
      <option value="EnvironmentalLawyer">Environmental Lawyer</option>
      <option value="EstatePlanningLawyer">Estate Planning Lawyer</option>
      <option value="PersonalInjuryLawyer">Personal Injury Lawyer</option>
      <option value="ImmigrationLawyer">Immigration Lawyer</option>
      <option value="BankruptcyLawyer">Bankruptcy Lawyer</option>
      <option value="CivilLitigationLawyer">Civil Litigation Lawyer</option>
      <option value="RealEstateLawyer">Real Estate Lawyer</option>
      <option value="ConstitutionalLawyer">Constitutional Lawyer</option>
      <option value="EntertainmentLawyer">Entertainment Lawyer</option>
    </Field>
    <ErrorMessage name="lawyerType" component="div" className={styles.error} />
  </div>
  </div>
)}   
            

            <div className={styles.BtnContainer}>
              <Tooltip title="Submit Form">
                <button type="submit" className={styles.submitButton} >Submit</button>
              </Tooltip>
              <Tooltip title="Cancel Form">
                <NavLink to={"/dashboard/Importcase"}>
                  <button type="button" className={`${styles.submitButton} ${styles.CancelButton}`}>Cancel</button>
                </NavLink>
              </Tooltip>
            </div>
            <ToastContainer />
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <People onClose={closeModal} />
        </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConcernPerson;
