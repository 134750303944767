import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../AdminComponents/Header'
import SideBar from '../AdminComponents/SideBar'
import Content from '../AdminComponents/Context'
import Style from './Admin.module.css'

const AdminMainPanel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    // If there's no token, redirect to the login page or another appropriate route
    if (!token) {
      navigate('/.admin'); // Change '/login' to your actual login route
    }
  }, [navigate]);
  return (
    <div className={Style.gridContainer}>
    <Header />
        <SideBar />
        <Content />
    </div>
  )
}

export default AdminMainPanel