export const courts = {

    "Supreme Court": [],
    "High Court": {
      Allahabad: ["Prayagraj High Court", "Uttar Pradesh High Court", "Lucknow High Court"],
    AndhraPradesh: ["Amaravati High Court", "Andhra Pradesh High Court"],
    Bombay: ["Mumbai High Court", "Nagpur High Court", "Aurangabad High Court", "Panaji High Court"],
    Calcutta: ["Kolkata High Court", "West Bengal High Court", "Port Blair High Court", "Jalpaiguri High Court"],
    Chhattisgarh: ["Bilaspur High Court", "Chhattisgarh High Court"],
    Delhi: ["New Delhi High Court", "Delhi High Court"],
    Gauhati: ["Guwahati High Court", "Assam High Court", "Aizawl High Court", "Itanagar High Court", "Kohima High Court"],
    Gujarat: ["Ahmedabad High Court", "Gujarat High Court"],
    HimachalPradesh: ["Shimla High Court", "Himachal Pradesh High Court"],
    JammuKashmirAndLadakh: ["Srinagar/Jammu (Summer/Winter) High Court"," Jammu & Kashmir and Ladakh High Court"],
    Jharkhand: ["Ranchi High Court"," Jharkhand High Court"],
    Karnataka: ["Bengaluru High Court"," Karnataka High Court", "Dharwad High Court", "Kalaburagi High Court"],
    Kerala: ["Kochi High Court", "Kerala High Court"],
    MadhyaPradesh: ["Jabalpur High Court", "Madhya Pradesh High Court", "Gwalior High Court", "Indore High Court"],
    Madras: ["Chennai High Court", "Tamil Nadu High Court", "Madurai High Court"],
    Manipur: ["Imphal High Court", "Manipur High Court"],
    Meghalaya: ["Shillong High Court", "Meghalaya High Court"],
    Orissa: ["Cuttack High Court", "Odisha High Court"],
    Patna: ["Patna High Court", "Bihar High Court"],
    PunjabAndHaryana: ["Chandigarh High Court", "Punjab and Haryana High Court"],
    Rajasthan: ["Jodhpur High Court", "Rajasthan High Court", "Jaipur High Court"],
    Sikkim: ["Gangtok High Court", "Sikkim High Court"],
    Telangana: ["Hyderabad High Court", "Telangana High Court"],
    Tripura: ["Agartala High Court", "Tripura High Court"],
    Uttarakhand: ["Nainital High Court", "Uttarakhand High Court"]
  
    },
  
  
    "District Courts": {
    Delhi: [
      "Tis Hazari",
      "Saket",
      "Patiala House",
      "Karkardooma",
      "Rohini",
      "Dwarka ",
      "rouse avenue",
    ],
  
    Haryana: [
      "Ambala",
      "Karnal",
      "Gurgaon",
      "Faridabad",
      "Rohtak",
      "Hisar",
      "Panipat",
      "Sonipat",
      "Sirsa",
      "Kurukshetra",
      "Jind",
      "Bhiwani",
      "Rewari",
      "Panchkula",
      "Mahendragarh",
      "Fatehabad",
      "Kaithal",
      "Jhajjar",
      "Palwal",
      "Nuh",
      "Charkhi Dadri",
      "Yamunanagar",
    ],
  
    HimachalPradesh: [
      "Shimla",
      "Kangra",
      "Mandi",
      "chamba",
      "kullu",
      "Hamirpur",
      "Una",
      "Bilaspur HP",
      "Solan",
      "Sirman",
      "Kinnaur",
    ],
    
    JammuAndKashmir: [
      "Srinagar",
      "Jammu",
      "Anantnag",
      "Kupwara",
      "Bandipora",
      "Baramula",
      "Ganderbal",
      "Badgam",
      "Pulwama",
      "Shopian",
      "Kulgam",
      "Kishtwar",
      "Poonch",
      "Rajouri",
      "Reasi",
      "Ramban",
      "Doda",
      "Udhampur",
      "Samba",
      "Kathua",
    ],
    Punjab: [
      "Amritsar",
      "Ludhiana",
      "Patiala",
      "Jalandhar",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Fatehgarh Sahib",
      "Ferozepur",
      "Gurdaspur",
      "Hoshiarpur",
      "Kapurthala",
      "Mansa",
      "Moga",
      "Sh. Muktsar Sahib",
      "Pathankot",
      "Rupnagar",
      "SAS Nagar",
      "S.B.S Nagar",
      "Sangrur",
      "Tarn Taran",
    ],
    Uttarakhand: [
      "Dehradun",
      "Nainital",
      "Haridwar",
      "Uttarkashi",
      "Tehri Garhwal",
      "Pauri Garhwal",
      "Rudraprayag",
      "Chamoli",
      "Almora",
      "Bageshwar",
      "Champawat",
      "Pithoragarh",
      "Udham Singh Nagar",
    ],
    UttarPradesh: [
      "Lucknow",
      "Kanpur",
      "Allahabad",
      "Varanasi",
      "Ghaziabad",
      "Agra",
      "Meerut",
      "Bareilly",
      "Aligarh",
      "Moradabad",
      "Saharanpur",
      "Gorakhpur",
      "Faizabad",
      "Firozabad",
      "Jhansi",
      "Amroha",
      "Auraiya",
      "Azamgarh",
      "Baghpat",
      "Bahraich",
      "Ballia",
      "Balrampur",
      "Banda",
      "Barabanki",
      "Basti",
      "Bhadohi",
      "Bijnor",
      "Budaun",
      "Bulandshahar",
      "Chandauli",
      "Chitrakoot",
      "Deoria",
      "Etah",
      "Etawah",
      "Ayodhya",
      "Farukhabad",
      "Fatehpur",
      "Gautam Buddha Nagar",
      "Ghazipur",
      "Gonda",
      "Hamirpur",
      "Hapur",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Kannauj",
      "Kanpur Dehat",
      "Kasganj",
      "Kaushambi",
      "Kushinagar",
      "Lakhimpur Kheri",
      "Lalitpur",
      "Maharajganj",
      "Mahoba",
      "Mainpuri",
      "Mathura",
    ],
    Goa: ["North Goa", "South Goa"],
    Gujarat: [
      "Ahmedabad",
      "Surat",
      "Vadodara",
      "Rajkot",
      "Kutch",
      "Banaskantha",
      "Patan",
      "Mehsana",
      "Sabarkantha",
      "Aravalli",
      "Mahisagar",
      "Gandhinagar",
      "Ahmedabad (Rural)",
      "Kheda",
      "Anand",
      "Chhota Udepur",
      "Dahod",
      "Panchmahals",
      "Vadodara",
      "Bharuch",
      "Narmada",
      "Surat",
      "Tapi",
      "Dang",
      "Navsari",
      "Valsad",
      "Junagadh",
      "Amreli",
      "Bhavnagar",
      "Botad",
      "Devbhumi Dwarka",
      "Gir Somnath",
      "Jamnagar",
      "Morbi",
      "Porbandar",
      "Rajkot",
      "Surendranagar",
    ],
    Maharashtra: [
      "Mumbai",
      "Pune",
      "Nagpur",
      "Thane",
      "Nandurbar",
      "Dhule",
      "Jalgaon",
      "Buldhana",
      "Akola",
      "Washim",
      "Amravati",
      "Wardha",
      "Gondia",
      "Bhandara",
      "Chandrapur",
      "Gadchiroli",
      "Yavatmal",
      "Nanded",
      "Hingoli",
      "Parbhani",
      "Jalna",
      "Aurangabad",
      "Nashik",
      "Ahmednagar",
      "Beed",
      "Latur",
      "Osmanabad",
      "Solapur",
      "Satara",
      "Ratnagiri",
      "Sindhudurg",
      "Kolhapur",
      "Sangli",
      "Raigad",
    ],
  
    Rajasthan: [
      "Jaipur",
      "Jodhpur",
      "Udaipur",
      "Kota",
      "Ajmer",
      "Alwar",
      "Banswara",
      "Baran",
      "Barmer",
      "Bharatpur",
      "Bhilwara",
      "Bikaner",
      "Bundi",
      "Chittorgarh",
      "Churu",
      "Dausa",
      "Dholpur",
      "Dungarpur",
      "Hanumangarh",
      "Jaisalmer",
      "Jalore",
      "Jhalawar",
      "Jhunjhunu",
      "Karauli",
      "Nagaur",
      "Pali",
      "Pratapgarh",
      "Rajsamand",
      "Sawai Madhopur",
      "Sikar",
      "Sirohi",
      "Sri Ganganagar",
      "Tonk",
      "Bundi",
    ],
  
    Chhattisgarh: [
      "Raipur",
      "Bilaspur",
      "Durg-Bhilai",
      "Balod",
      "Baloda Bazar",
      "Balrampur",
      "Bastar",
      "Bemetara",
      "Bijapur",
      "Dantewada",
      "Dhamtari",
      "Gariaband",
      "Janjgir-Champa",
      "Jashpur",
      "Kabirdham",
      "Kanker",
      "Kondagaon",
      "Korba",
      "Koriya",
      "Mahasamund",
      "Mungeli",
      "Narayanpur",
      "Raigarh",
      "Rajnandgaon",
      "Sukma",
      "Surajpur",
      "Surguja",
    ],
  
    MadhyaPradesh: [
      "Indore",
      "Bhopal",
      "Jabalpur",
      "Gwalior",
      "Agar Malwa",
      "Alirajpur",
      "Anuppur",
      "Ashoknagar",
      "Balaghat",
      "Barwani",
      "Betul",
      "Bhind",
      "Burhanpur",
      "Chhatarpur",
      "Chhindwara",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhar",
      "Dindori",
      "Guna",
      "Gwalior",
      "Harda",
      "Hoshangabad",
      "Indore",
      "Jabalpur",
      "Jhabua",
      "Katni",
      "Khandwa",
      "Khargone",
      "Mandla",
      "Mandsaur",
      "Morena",
      "Narsinghpur",
      "Neemuch",
      "Panna",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Sehore",
      "Seoni",
      "Shahdol",
      "Shajapur",
      "Sheopur",
      "Shivpuri",
      "Sidhi",
      "Singrauli",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
    ],
  
    Bihar: [
      "Patna",
      "Gaya",
      "Bhagalpur",
      "Muzaffarpur",
      "Araria",
      "Arwal",
      "Aurangabad",
      "Banka",
      "Begusarai",
      "Bhagalpur",
      "Bhojpur",
      "Buxar",
      "Darbhanga",
      "East Champaran",
      "Gaya",
      "Gopalganj",
      "Jamui",
      "Jehanabad",
      "Kaimur",
      "Katihar",
      "Khagaria",
      "Kishanganj",
      "Lakhisarai",
      "Madhepura",
      "Madhubani",
      "Munger",
      "Muzaffarpur",
      "Nalanda",
      "Nawada",
      "Patna",
      "Purnia",
      "Rohtas",
      "Saharsa",
      "Samastipur",
      "Saran",
      "Sheikhpura",
      "Sheohar",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Vaishali",
      "West Champaran",
    ],
  
    Jharkhand: [
      "Ranchi",
      "Dhanbad",
      "Jamshedpur",
      "Bokaro",
      "Chatra",
      "Deoghar",
      "Dhanbad",
      "Dumka",
      "East Singhbhum",
      "Garhwa",
      "Giridih",
      "Godda",
      "Gumla",
      "Hazaribagh",
      "Jamtara",
      "Khunti",
      "Koderma",
      "Latehar",
      "Lohardaga",
      "Pakur",
      "Palamu",
      "Ramgarh",
      "Ranchi",
      "Sahebganj",
      "Seraikela Kharsawan",
      "Simdega",
      "West Singhbhum",
    ],
  
    Odisha: [
      "Cuttack",
      "Bhubaneswar",
      "Berhampur",
      "Angul",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhadrak",
      "Boudh",
      "Cuttack",
      "Deogarh",
      "Dhenkanal",
      "Gajapati",
      "Ganjam",
      "Jagatsinghapur",
      "Jajpur",
      "Jharsuguda",
      "Kalahandi",
      "Kandhamal",
      "Kendrapara",
      "Kendujhar",
      "Khordha",
      "Koraput",
      "Malkangiri",
      "Mayurbhanj",
      "Nabarangpur",
      "Nayagarh",
      "Nuapada",
      "Puri",
      "Rayagada",
      "Sambalpur",
      "Subarnapur",
      "Sundargarh",
    ],
  
    WestBengal: [
      "Kolkata",
      "Darjeeling",
      "Jalpaiguri",
      "Midnapore",
      "Alipurduar",
      "Bankura",
      "Birbhum",
      "Cooch Behar",
      "Dakshin Dinajpur",
      "Hooghly",
      "Howrah",
      "Jalpaiguri",
      "Jhargram",
      "Kalimpong",
      "Malda",
      "Murshidabad",
      "Nadia",
      "North 24 Parganas",
      "Paschim Bardhaman",
      "Purba Bardhaman",
      "Purba Medinipur",
      "Purulia",
      "South 24 Parganas",
      "Uttar Dinajpur",
    ],
  
    AndhraPradesh: [
      "Visakhapatnam",
      "Vijayawada",
      "Guntur",
      "Anantapur",
      "Chittoor",
      "East Godavari",
      "Kadapa",
      "Krishna",
      "Kurnool",
      "Nellore",
      "Prakasam",
      "Srikakulam",
      "Visakhapatnam",
      "Vizianagaram",
      "West Godavari",
    ],
  
    Karnataka: [
      "Bengaluru",
      "Mysore",
      "Hubli-Dharwad",
      "Bagalkot",
      "Ballari",
      "Belagavi",
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
      "Kalaburagi",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Raichur",
      "Ramanagara",
      "Shivamogga",
      "Tumakuru",
      "Udupi",
      "Uttara Kannada",
      "Vijayanagara",
      "Vijayapura",
      "Yadgir",
    ],
  
    Kerala: [
      "Kochi",
      "Thiruvananthapuram",
      "Kozhikode",
      "Alappuzha",
      "Ernakulam",
      "Idukki",
      "Kannur",
      "Kasaragod",
      "Kollam",
      "Kottayam",
      "Malappuram",
      "Palakkad",
      "Pathanamthitta",
      "Thrissur",
      "Wayanad",
    ],
  
    TamilNadu: [
      "Chennai",
      "Coimbatore",
      "Madurai",
      "Salem",
      "Ariyalur",
      "Chengalpattu",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kallakurichi",
      "Kancheepuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Ranipet",
      "Salem",
      "Sivaganga",
      "Tenkasi",
      "Thanjavur",
      "Theni",
      "Thoothukudi",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tirupathur",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvarur",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
  
    Telangana: [
      "Hyderabad",
      "Warangal",
      "Nizamabad",
      "Adilabad",
      "Bhadradri Kothagudem",
      "Jagtial",
      "Jangaon",
      "Jayashankar Bhupalapally",
      "Jogulamba Gadwal",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Komaram Bheem Asifabad",
      "Mahabubabad",
      "Mahabubnagar",
      "Mancherial",
      "Medak",
      "Medchal–Malkajgiri",
      "Mulugu",
      "Nagarkurnool",
      "Nalgonda",
      "Narayanpet",
      "Nirmal",
      "Nizamabad",
      "Peddapalli",
      "Rajanna Sircilla",
      "Rangareddy",
      "Sangareddy",
      "Siddipet",
      "Suryapet",
      "Vikarabad",
      "Wanaparthy",
      "Warangal Rural",
      "Warangal Urban",
      "Yadadri Bhuvanagiri",
    ],
  
    ArunachalPradesh: [
      "Itanagar",
      "Tawang",
      "West Kameng",
      "East Kameng",
      "Papum Pare",
      "Kurung Kumey",
      "Kra Daadi",
      "Lower Subansiri",
      "Upper Subansiri",
      "West Siang",
      "East Siang",
      "Siang",
      "Upper Siang",
      "Lower Siang",
      "Lower Dibang Valley",
      "Upper Dibang Valley",
      "Dibang Valley",
      "Anjaw",
      "Lohit",
      "Namsai",
      "Changlang",
      "Tirap",
      "Longding",
    ],
  
    Assam: [
      "Guwahati",
      "Dibrugarh",
      "Silchar",
      "Baksa",
      "Barpeta",
      "Biswanath",
      "Bongaigaon",
      "Cachar",
      "Charaideo",
      "Chirang",
      "Darrang",
      "Dhemaji",
      "Dhubri",
      "Dibrugarh",
      "Dima Hasao",
      "Goalpara",
      "Golaghat",
      "Hailakandi",
      "Hojai",
      "Jorhat",
      "Kamrup",
      "Kamrup Metropolitan",
      "Karbi Anglong",
      "Karimganj",
      "Kokrajhar",
      "Lakhimpur",
      "Majuli",
      "Morigaon",
      "Nagaon",
      "Nalbari",
      "Dima Hasao",
      "Sivasagar",
      "Sonitpur",
      "South Salmara-Mankachar",
      "Tinsukia",
      "Udalguri",
      "West Karbi Anglong",
    ],
  
    Manipur: [
      "Imphal",
      "Bishnupur",
      "Chandel",
      "Churachandpur",
      "Kakching",
      "Kamjong",
      "Kangpokpi",
      "Noney",
      "Pherzawl",
      "Senapati",
      "Tamenglong",
      "Tengnoupal",
      "Thoubal",
      "Ukhrul",
    ],
  
    Meghalaya: [
      "Shillong",
      "East Garo Hills",
      "West Garo Hills",
      "South Garo Hills",
      "North Garo Hills",
      "South West Garo Hills",
      "East Khasi Hills",
      "West Khasi Hills",
      "South West Khasi Hills",
      "Ri-Bhoi",
      "West Jaintia Hills",
      "East Jaintia Hills",
    ],
  
    Mizoram: [
      "Aizawl",
      "Champhai",
      "Serchhip",
      "Lunglei",
      "Lawngtlai",
      "Mamit",
      "Kolasib",
      "Saiha",
    ],
  
    Nagaland: [
      "Kohima",
      "Dimapur",
      "Mokokchung",
      "Tuensang",
      "Wokha",
      "Zunheboto",
      "Mon",
      "Phek",
      "Peren",
      "Longleng",
    ],
  
    Tripura: [
      "Agartala",
      "Dhalai",
      "Gomati",
      "Khowai",
      "North Tripura",
      "Sepahijala",
      "South Tripura",
      "Unakoti",
      "West Tripura",
    ],
  
    "Andaman and Nicobar Islands": [
      "Port Blair",
      "Nicobar",
      "North and Middle Andaman",
      "South Andaman",
    ],
  
    Chandigarh: ["Chandigarh"],
  
    DNHDD: [
      "Silvassa",
      "Daman",
      "Dadra",
      "Diu",
    ],
  
    Lakshadweep: [
      "Kavaratti",
      "Agatti",
      "Amini",
      "Andrott",
      "Bitra",
      "Chetlat",
      "Kadmat",
      "Kalpeni",
      "Minicoy",
    ],
  
    Puducherry: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  }
  };
  