import React, { useState, useEffect } from 'react';
import { PDFDocument,rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import axios from 'axios';
import styles from './Casemine.module.css'


const CaseMine = ({onClose}) => {

    const [highlightSearch, setHighlightSearch] = useState(false);
    const [citationCodes, setCitationCodes] = useState(true);
    const [info, setInfo] = useState(true);
    const [summary, setSummary] = useState(true);


  

    // Toggle function
    const handleToggle = (toggle) => {
      switch (toggle) {
        case 'highlightSearch':
          setHighlightSearch(!highlightSearch);
          break;
        case 'citationCodes':
          setCitationCodes(!citationCodes);
          break;
        case 'info':
          setInfo(!info);
          break;
        case 'summary':
          setSummary(!summary);
          break;
        default:
          // handle default case
      }
    };

    // const handleDownload = async () => {
    //   if (!highlightSearch && !citationCodes && !info && !summary) {
    //       const pdfUrl = 'http://localhost:8052/serve-pdf/2'; // URL to your PDF
    //       const logoUrl = 'http://localhost:8052/logo.png'; // URL to your logo image
    
    //       try {
    //           const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
    //           const logoImageBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
    
    //           const pdfDoc = await PDFDocument.load(existingPdfBytes);
    //           const logoImage = await pdfDoc.embedPng(logoImageBytes);
    
    //           pdfDoc.getPages().forEach(page => {
    //             const { width, height } = page.getSize();
                
    //             // Coordinates to cover the old logo
    //             // These should be adjusted based on the old logo's actual size and position
    //             const coverX = (width / 2) - 65; // Assuming the logo is in the middle of the page
    //             const coverY = 795; // Assuming the logo is at the very top of the page
    //             const coverWidth = 130; 
    //             const coverHeight = 65; 
          
                
    //             page.drawRectangle({
    //                 x: coverX,
    //                 y: coverY,
    //                 width: coverWidth,
    //                 height: coverHeight,
    //                 color: rgb(1, 1, 1), 
    //             });
    //             const logoX = coverX;
    //             const logoY = coverY;
          
    //             // Draw the new logo
    //             page.drawImage(logoImage, {
    //                 x: logoX,
    //                 y: logoY,
    //                 width: coverWidth, // or your new logo's width
    //                 height: coverHeight, // or your new logo's height
    //             });
    //         });
    
    //           const pdfBytes = await pdfDoc.save();
    //           const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //           saveAs(blob, 'modified_pdf.pdf');
    //       } catch (error) {
    //           console.error('Error modifying PDF:', error);
    //           alert('Failed to modify PDF');
    //       }
    //   } else {
    //       alert("Please turn off all toggles to download the PDF.");
    //   }
    // };
    

    const handleDownload = async () => {
      if (!highlightSearch && !citationCodes && !info && !summary) {
          fetch('http://localhost:8052/serve-pdf/2')
              .then(response => response.text())  // assuming the server sends back the extracted text
              .then(data => {
                  console.log('Received data:', data);
                  alert('PDF processed and data stored in DB.');
              })
              .catch(error => {
                  console.error('Error fetching PDF data:', error);
                  alert('Failed to process PDF');
              });
      } else {
          alert("Please turn off all toggles to download the PDF.");
      }
  };
  
  const handleDownload1 = async () => {
    const id = 2; // Example ID, replace with actual
    try {
        // Include the id in the URL path and send the toggles in the body of the request
        const response = await axios.post(`http://localhost:8052/generate-pdf/${id}`, {
            highlightSearch,
            citationCodes,
            info,
            summary
        }, {
            responseType: 'blob' // Important to handle the binary data correctly
        });

        // Blob creation and download initiation
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `case-file-${id}.pdf`); // Dynamic file name based on case ID
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading PDF:', error);
        alert('Failed to generate PDF');
    }
};
    

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>Select Preference</h2>
          <button onClick={onClose} className={styles.closeButton}>X</button>
        </div>
        <div className={styles.modalContent}>
          <form>
            <label className={styles.toggleContainer}>
              <input
                type="checkbox"
                checked={highlightSearch}
                onChange={() => handleToggle('highlightSearch')}
                className={styles.toggleInput}
              />
              <span className={styles.toggleSlider} />
              case no.
            </label>
            <label className={styles.toggleContainer}>
              <input
                type="checkbox"
                checked={citationCodes}
                onChange={() => handleToggle('citationCodes')}
                className={styles.toggleInput}
              />
              <span className={styles.toggleSlider} />
              Citation Codes
            </label>
            <label className={styles.toggleContainer}>
              <input
                type="checkbox"
                checked={info}
                onChange={() => handleToggle('info')}
                className={styles.toggleInput}
              />
              <span className={styles.toggleSlider} />
              judges
            </label>
            <label className={styles.toggleContainer}>
              <input
                type="checkbox"
                checked={summary}
                onChange={() => handleToggle('summary')}
                className={styles.toggleInput}
              />
              <span className={styles.toggleSlider} />
              acts
            </label>
            <button type="button" onClick={handleDownload} className={styles.printPdfButton}>Print PDF</button>
            <button type="button" onClick={handleDownload1} className={styles.printPdfButton}>Download PDF</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CaseMine