import React, { useState } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import { useNavigate } from 'react-router-dom';
import styles from './NotificationManagement.module.css'; // Ensure this path matches your CSS file location

const NotificationManagement = () => {
  const [message, setMessage] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [notificationType, setNotificationType] = useState('general');
  const navigate = useNavigate();

  const handleCreateNotification = async () => {
    const ip = data.evn;
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/.admin'); // Adjust as per your routing setup
      return;
    }

    // Apply styling to the message right before submission if it's not already styled
    const styledMessage = message && !message.includes('<strong') 
      ? `<strong style="color: #800000;">${message}</strong>` 
      : message;

    try {
      await axios.post(`${ip}/admin/notifications/generate`, {
        message: styledMessage,
        expirationDate,
        type: notificationType,
      }, {
        headers: { 'x-auth-token': token },
      });

      alert('Notification successfully created');
      setMessage(''); // Clear fields after submission
      setExpirationDate('');
      setNotificationType('general');
    } catch (error) {
      console.error("There was an error creating the notification:", error);
      alert('Failed to create notification');
    }
  };

  const handleFrontendSelectionChange = (e) => {
    const selectedOption = e.target.value;
    let predefinedMessage = '';

    switch (selectedOption) {
      case 'proxy':
        predefinedMessage = '[feature] added. Uses: [specify the uses]. Please have a look.';
        break;
      case 'maintenance':
        predefinedMessage = 'The site is under maintenance From [date][Time] to [date][time]. Sorry for the inconvenience.';
        break;
        case 'offer':
          predefinedMessage = 'Refere [number of people] people, get [percentage value to be deducted]% off.. Use code [code].';
          break;
      case 'other':
        // Clear the message to allow manual input for the "Other" option
        setMessage('');
        return; // Exit early as we don't want to set any predefined message for "Other"
      default:
        predefinedMessage = ''; // Reset message for any unhandled case
    }

    // For predefined options, set the message without HTML styling
    setMessage(predefinedMessage);
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <h1 className={styles.heading}>Generate Notifications</h1>
        <div className={styles.formGroup}>
          <label className={styles.label}>Frontend Notification Options:</label>
          <select
            onChange={handleFrontendSelectionChange}
            className={styles.select}
          >
            <option value="">Select Option</option>
            <option value="proxy">New Feature</option>
            <option value="maintenance">Backend Maintenance</option>
            <option value="offer">Offer</option>
            <option value="other">Other (manual input)</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles.textInput}
            placeholder="Enter or edit your message"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Expiration Date:</label>
          <input
            type="date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            className={styles.input}
          />
        </div>
        <button onClick={handleCreateNotification} className={styles.button}>Create Notification</button>
      </div>
    </>
  );
};

export default NotificationManagement;
