import "./App.css";
import { NotificationContext, NotificationProvider } from './component/utilities/Notifications/NotificationsContext.jsx'; // adjust path as needed
  import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./pages/Login-Logout/AuthContext";
// UI Library Chakra ui
import { ChakraProvider } from '@chakra-ui/react'
import Navbar from "./component/utilities/Navbar/Navbar";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import DashBoard from "./pages/Dashboard/DashBoard";
import Services from "./pages/Services/Services";
import LogIn from "./pages/Login-Logout/LogIn";
import AdminLogin from "./pages/Login-Logout/AdminLogin.jsx";
import Register from "./pages/Login-Logout/Register";
import Maintenance from "./pages/MaintenancePage/MaintenancePage.jsx";
import VerifyEmail from "./pages/Login-Logout/VerifyEmail";
import Forgot from "./pages/Login-Logout/Forgot";
import ResetPassword from "./pages/Login-Logout/ResetPassword";
import Contact from "./pages/Contact/Contact";
import Explore from "./pages/Explore/Explore";
import CaseDetail from "./component/utilities/caseDetail/CaseDetail";
import DemoRequestForm from "./component/form/DemoRequestForm/DemoRequestForm";
import ClientForm from "./component/form/Client/ClientForm";
// import CaseForm from "./component/form/AddCase/CaseForm";
import UpdateCaseForm from "./component/form/AddCaseUpdate/UpdateCaseForm";
import ShowUpdateCase from "./component/form/AddCaseUpdate/ShowUpdateCase";
import AlertsForm from "./component/form/Alerts/AlertsForm";
import TeamMembersForm from "./component/form/TeamMember/TeamMemberForm";
import AppointmentForm from "./component/form/Appointment/AppointmentForm";
import GroupForm from "./component/form/Group/GroupForm";
import DocumentGenrationForm from "./component/form/DocumentGenration/DocumentGenrationForm";
import Proxy from "./component/form/Proxy/ProxyForm";
import Subscription from "./component/form/Subscription/Subscription";
import PartyNameForm from "./component/form/ComingSoon/ComingSoon.jsx";
import ImportCase from "./component/form/AddCase/importcase/ImportCase";
import CnrForm from "./component/form/ComingSoon/ComingSoon.jsx";
import AdvocateForm from "./component/form/ComingSoon/ComingSoon.jsx";
import Finances from "./component/form/Bill/Finances/Finances";
import BillForm from "./component/form/Bill/BillForm";
import InvoicesForm from "./component/form/Invoices/InvoicesForm";
import FinancesView from "./component/form/Bill/FinancesView/FinancesView";
// import BillFormData from "./component/form/Bill/FinancesView/BillData/BillFormData";
// import InvoiceFormData from "./component/form/Bill/FinancesView/InvoiceData/InvoiceFormData";
import ReviewDocForm from "./component/form/ComingSoon/ComingSoon.jsx";
// import CasesFormData from "./component/form/AddCase/CaseFormData";
import ClientFormData from "./component/form/Client/ClientFormData";
import ProxyData from "./component/form/Proxy/ProxyData";
import InvoicesFormData from "./component/form/Invoices/InvoicesFormData";
import BillFormData from "./component/form/Bill/BillFormData";
import AlertsFormData from "./component/form/Alerts/AlertsFormData";
import TeamMemberdata from "./component/form/TeamMember/TeamMemberData";
import CalendarForm from "./component/form/Calendar/CalendarForm";
import PeopleForm from "./component/form/Client/People/PeopleForm";
// import GenrationDocs from "./component/form/ComingSoon/ComingSoon.jsx";
import GenrationDocs from "./component/DocumentChatBot/ChatBox.jsx";
import Notification from "./component/utilities/Notifications/Notifications.jsx";
import DashboardNavbar from "./component/utilities/DashboardNavbar/DashboardNavbar.jsx";
import ConvertDocument from "./component/form/ComingSoon/ComingSoon.jsx";
import CaseHistory from "./component/form/AddCase/CaseHistory/CaseHistory.jsx";
import NotificationSetting from "./component/utilities/Notifications/NotificationSetting/NotificationSetting.jsx";
import UserProfilePage from "./component/utilities/Navbar/Profile/UserProfilePage.jsx";
import MultiFormTabs from "./component/form/AddCase/CaseTitleData/MultiFormTabs.jsx";
import CaseTitleForm from "./component/form/AddCase/CaseTitleData/CaseTitleForm.jsx";
import  People  from "./component/form/AddCase/CaseTitleData/People.jsx";
import TermAndCondition from "./component/form/DemoRequestForm/TermAndCondition.jsx";
import Chatpage from "./component/chat/MainChatpage/Chatpage.js"; 

//admin
import AdminMainPanel from "./Admin/AdminMainPanel/AdminMainPanel.jsx";
import NewCustomerLead from "./Admin/AdminComponents/NewCustomerLead.jsx";
import ContactFormSubmissionsAdmin from "./Admin/AdminComponents/ContactFormSubmissionsAdmin.jsx";
import DemoFormSubmissionsAdmin from "./Admin/AdminComponents/DemoRequestsAdmin.jsx";
import ProxyPayment from "./Admin/ProxyPayment/ProxyPayment.jsx";
import CaseManagement from "./Admin/CaseManagemnt/CaseManagement.jsx";
import AllUsers from "./Admin/UserManagement/UserManagement.jsx";
import AllWallets from "./Admin/WalletManagement/WalletManagement.jsx";
import AllPeople from "./Admin/ClientManagement/ClientManagement.jsx";
import AllInvoices from "./Admin/InvoiceManagement/InvoiceManagement.jsx";
import AllBills from "./Admin/BillManagement/BillManagement.jsx";
import AllTeammembers from "./Admin/TeamMemberManagement/TeamMemberManagement.jsx";
import AllAlerts from "./Admin/AlertManagement/AlertManagement.jsx";
import AllGroups from "./Admin/GroupManagement/GroupManagement.jsx";
import AllCompanies from "./Admin/CompanyManagement/CompanyManagement.jsx";
import AllHearings from "./Admin/CourtHearingManagement/CourtHearingManagement.jsx";
import AllAppointments from "./Admin/AppointmentManagement/AppointmentManagement.jsx";
import AllChats from "./Admin/ChatManagement/ChatManagement.jsx";
import AllReviewDocs from "./Admin/ReviewDocManagement/ReviewDocManagement.jsx";
import ParticularCaseDocuments from "./Admin/CaseFileManagement/CaseFileManagement.jsx";
import ParticularCaseNotes from "./Admin/NoteManagement/NoteManagement.jsx";
import ParticularCasePersons from "./Admin/CaseDetailsManagement/CaseDetailsManagement.jsx";
import ParticularCaseOpponents from "./Admin/OpponentDetailsManagement/OpponentDetailsManagement.jsx";
import GenerateNotifications from "./Admin/NotificationManagement/NotificationManagement.jsx";

function App() {
 
 
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <NotificationProvider>
            <AppContent />
          </NotificationProvider>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}


const AppContent = () => {
  const location = useLocation();
  const hideNavbarRoutes = ['/.admin/dashboard', '/newcustomerlead', '/proxypayment', '/casemanagement', '/allusers' , '/allwallets'
  , '/allpeople', '/allinvoices', '/allbills', '/allteammembers', '/alltasks' , '/allgroups', '/allcompanies', '/allhearings'
  , '/allappointments', '/allchats', '/allreviewdocs', '/particularcasedocuments', '/particularcasenotes', '/particularcasepersons',
   '/particularcaseopponents','/generatenotifications','/contactformsub','/demoformsub'];
  const shouldShowNavbar = !hideNavbarRoutes.some(path => location.pathname.includes(path));

  return (
    <div className="App">
      {shouldShowNavbar && <Navbar />}
      <Routes>
        {/* Define your routes here as before */}
        
        {/* Include other routes */}

          {/* Pages Routes */}
        
            <Route path=".admin/dashboard" element={<AdminMainPanel />} />
            <Route path="newcustomerlead" element={<NewCustomerLead />} />
            <Route path="contactformsub" element={<ContactFormSubmissionsAdmin />} />
            <Route path="demoformsub" element={<DemoFormSubmissionsAdmin />} />
            <Route path="proxypayment" element={<ProxyPayment />} />
            <Route path="casemanagement" element={<CaseManagement />} />
            <Route path="allusers" element={<AllUsers />} />
            <Route path="allwallets" element={<AllWallets />} />
            <Route path="allpeople" element={<AllPeople />} />
            <Route path="allinvoices" element={<AllInvoices />} />
            <Route path="allbills" element={<AllBills />} />
            <Route path="allteammembers" element={<AllTeammembers />} />
            <Route path="alltasks" element={<AllAlerts />} />
            <Route path="allgroups" element={<AllGroups />} />
            <Route path="allcompanies" element={<AllCompanies />} />
            <Route path="allhearings" element={<AllHearings />} />
            <Route path="allappointments" element={<AllAppointments />} />
            <Route path="allchats" element={<AllChats />} />
            <Route path="allreviewdocs" element={<AllReviewDocs />} />
            <Route path="particularcasedocuments" element={<ParticularCaseDocuments />} />
            <Route path="particularcasenotes" element={<ParticularCaseNotes />} />
            <Route path="particularcasepersons" element={<ParticularCasePersons />} />
            <Route path="particularcaseopponents" element={<ParticularCaseOpponents />} />
            <Route path="generatenotifications" element={<GenerateNotifications />} />

            <Route index element={<Home />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="about" element={<About />} />
            <Route path="dashboard" element={<DashBoard />} />      
            <Route path="dashboard/DashboardNavbar" element={<DashboardNavbar />} />      
            <Route path="services" element={<Services />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<LogIn />} />
            <Route path=".admin" element={<AdminLogin />} />
            <Route path="register" element={<Register />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="verifyemail/:token" element={<VerifyEmail />} />
            <Route path="chatpage" element={<Chatpage />} />
            
    
           
            
            <Route path="forgot" element={<Forgot />} />
            <Route path="explore" element={<Explore />} />
            <Route path="DemoRequestForm" element={<DemoRequestForm />} />
            <Route path="dashboard/notifications" element={<Notification />} />
            <Route path="dashboard/notificationssetting" element={<NotificationSetting />} />
            
            {/* Forms Routes */}
            {/* <Route path="dashboard/caseform" element={<CaseForm />} /> */}
            <Route path="dashboard/caseform" element={<UpdateCaseForm />} />
            <Route path="dashboard/Updateshowcases" element={<ShowUpdateCase />} />
            <Route path="dashboard/clientform" element={<ClientForm />} />
            <Route path="dashboard/alertsform" element={<AlertsForm />} />
            <Route path="dashboard/teammemberform" element={<TeamMembersForm />} />
            <Route path="appointmentform" element={<AppointmentForm />} />
            <Route path="dashboard/groupform" element={<GroupForm />} />
            <Route path="documentgenrationform" element={<DocumentGenrationForm />} />
            <Route path="genrationdocs" element={<GenrationDocs />} />
            <Route path="dashboard/proxy" element={<Proxy />} />
            <Route path="partynameform" element={<PartyNameForm />} />
            <Route path="cnrform" element={<CnrForm />} />
            <Route path="advocateform" element={<AdvocateForm />} />
            <Route path="dashboard/billform" element={<BillForm />} />
            <Route path="dashboard/invoicesform" element={<InvoicesForm />} />
            <Route path="dashboard/reviewdocform" element={<ReviewDocForm />} />
            <Route path="dashboard/calendarform" element={<CalendarForm />} />
            <Route path="dashboard/peopleform" element={<PeopleForm />} />
            <Route path="dashboard/convertdocument" element={<ConvertDocument />} />
            <Route path="subscription" element={<Subscription />} />
            {/* Form data routes */}
            {/* <Route path="dashboard/caseformdata" element={<CasesFormData />} /> */}
            <Route path="dashboard/clientformdata" element={<ClientFormData />} />
            <Route path="dashboard/invoicesformdata" element={<InvoicesFormData />} />
            <Route path="dashboard/alertsformdata" element={<AlertsFormData />} />
            <Route path="dashboard/teammemberdata" element={<TeamMemberdata />} />
            <Route path="dashboard/proxydata" element={<ProxyData />} />
            <Route path="dashboard/People" element={<People />} />
            <Route path="dashboard/multiformtabs" element={<MultiFormTabs />} />
            <Route path="termandcondition" element={<TermAndCondition />} />
            {/* Form View routes */}
            <Route path="dashboard/financesview" element={<FinancesView />} />
            <Route path="dashboard/billformdata" element={<BillFormData />} />
            {/* <Route path="invoiceformdata" element={<InvoiceFormData />} /> */}

            {/* Inside Form Routes */}
            <Route path="dashboard/Importcase" element={<ImportCase />} />
            <Route path="dashboard/finances" element={<Finances />} />
            
            {/* Dynamkc routes */}
            <Route path="case/:case_no" element={<CaseDetail />} />
            {/* <Route path="case/cases" element={<CaseHistory />} /> */}
            <Route path="case/cases/:caseId" element={<CaseTitleForm />} />

            {/* User Profile */}
            <Route path="dashboard/UserProfilePage" element={<UserProfilePage />} />
      </Routes>
    </div>
  );
};

export default App;
