import React, { useEffect, useState } from 'react';
import Search from '../../component/utilities/SearchBar/Search'
import DashboardNavbar from '../../component/utilities/DashboardNavbar/DashboardNavbar'
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios'; // Import axios for HTTP requests
import { data } from "../../evn/evn";
import SideNav from '../../component/utilities/SideNavBar/SideNav';

const Explore = () => {
  const navigate = useNavigate(); // useNavigate hook for navigation
  const ip = data.evn;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  
  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription'); // Redirect to subscription page if not subscribed
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      // Handle the error appropriately, possibly redirecting to an error page or login
    }
  };

  // Check subscription status on component mount
  useEffect(() => {
    checkSubscriptionStatus();
  }, []); // Empty dependency array ensures this runs once on mount
  return (
    <>
      <h1>
        
      </h1>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
      <Search/>
    </>
  )
}

export default Explore