import React, { useState, useEffect } from 'react';
import style from './comingsoon.module.css'; // Make sure to create this CSS file in the same directory
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
const ComingSoon = () => {
    const navigate = useNavigate();
    const calculateTimeLeft = () => {
        const difference = +new Date("2024-05-01") - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
            minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
            seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0'),
          };
        }
    
        return timeLeft;
      };
  const goBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  const goToContact = () => {
    navigate('/contact'); // Replace '/contact' with the path to your contact page
  };

  return (
    <><DashboardNavbar/>
    <div className={style.comingSoonContainer}>
      <h1 className={style.title}>UNDER DEVELOPMENT</h1>
      <h6 className={style.dec}>COMING SOON</h6>
      
      <div className={style.timer}>
        {Object.keys(timeLeft).length > 0 ? (
          Object.keys(timeLeft).map((interval) => (
            <div key={interval} className={style.timerComponent}>
              <div className={style.timeValue}>
                {timeLeft[interval]}
              </div>
              <div className={style.timeLabel}>
                {interval.toUpperCase()}
              </div>
            </div>
          ))
        ) : (
          <span className={style.timesUp}>Time's up!</span>
        )}
      </div>
      <div className={style.buttonGroup}>
      <button onClick={goToContact} className={style.submitButton}> {/* Add a new class in your CSS for styling this button */}
          CONTACT US
        </button>
      <button onClick={goBack} className={style.closeDemoBtn}>
        BACK
      </button>
      </div>
    </div>
    </>
  );
};

export default ComingSoon;
