import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './TeamMemberData.module.css';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar';
import { NavLink } from 'react-router-dom';
import EditTeamMembersForm from './EditTeamMemberForm/EditTeamMemberForm';
import Modal from '../Client/People/ModelPop/Modal';
import SideNav from '../../utilities/SideNavBar/SideNav';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Tooltip } from "@mui/material";
import {data} from '../../../evn/evn'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const TeamMemberdata = () => {
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editingTeam, setEditingTeam] = useState(null);
  const [downloadingCaseId, setDownloadingCaseId] = useState(null);
  const ip = data.evn
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [currentPage, setCurrentPage] = useState(1);
const [casesPerPage, setCasesPerPage] = useState(5);

const indexOfLastCase = currentPage * casesPerPage;
const indexOfFirstCase = indexOfLastCase - casesPerPage;
const currentCases = teamMembers.slice(indexOfFirstCase, indexOfLastCase);

// Pagination handlers
const handleNextPage = () => {
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage(currentPage - 1);
};




  useEffect(() => {
    const fetchData = async () => {
    //   await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
    //   setDashboardData([...datas]); // Update local state to trigger re-render
    };
  
    fetchData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  
  }, []);
  

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${ip}/dashboard/teammemberform/edit`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      setTeamMembers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch team members data from the backend when the component mounts
    fetchTeamMembers();
  }, []);

  const handleEditClick = (member) => {
    setEditingTeam(member.id);
    openModal();
  };

  const handleCancelClick = () => {
    setEditingTeam(null);
    closeModal();
  };

  const handleDeleteClick = async (memberId) => {
    
    if (window.confirm('Are you sure you want to delete this team member?')) {
      try {
        await axios.delete(`${ip}/dashboard/teammemberform/${memberId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        fetchTeamMembers(); // Refetch the team members to update the UI
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDownloadClick = async (memberId) => {
    setDownloadingCaseId(memberId)
    try {
      // Make an HTTP GET request to download the PDF for the specified team member ID
      const response = await axios.get(`${ip}/dashboard/teammemberform/download-pdf/${memberId}`, {
        responseType: 'blob',
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = `TeamMember_${memberId}.pdf`;
      a.click();
      setDownloadingCaseId(null)

    } catch (error) {
      setDownloadingCaseId(null)

      console.error(error);
    }
  };

  return (
    <>
      {isMobile ? <SideNav /> : <DashboardNavbar />}
      <div className={style.container}>
        <h2 className={style.heading}>TEAM MEMBERS STACK</h2>
        <table className={style.table}>
          <thead className={style.tableHead}>
            <tr>
            {/* <th className={style.th}>Image</th> */}
              <th className={style.titleColumn}>Full Name</th>
              <th>Email</th>
              <th >Contact number</th>
              <th >Assigned Group</th>
              <th >Assigned Campany</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody className={style.tableBody}>
            {currentCases.map((member) => (
              <tr key={member.id}>
                <td className={style.titleColumn}>{member.fullName}</td>
                <td className={style.td}>{member.email}</td>
                <td className={style.td}>{member.mobileno}</td>
                <td className={style.td}>{member.selectedGroup}</td>
                <td className={style.td}>{member.selectedCompany}</td>
                <td className={style.td}>
                <Tooltip title="Edit" disableInteractive>

                <button className={style.btn} onClick={() => handleEditClick(member)}>
                <BorderColorIcon />
              </button>
              </Tooltip>
                <Tooltip title="Edit" disableInteractive>

                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDeleteClick(member.id)}
                  >
                    <DeleteIcon />
                  </button>
                  </Tooltip>
                  <Tooltip title="Edit" disableInteractive>
                  
                  <button
                  className={style.btn}
                    type="button"
                    onClick={() => handleDownloadClick(member.id)}
                  >
                    <DownloadForOfflineIcon />
                    {downloadingCaseId === member.id && <CircularProgress size="1.5rem" color="inherit" />} 

                  </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>

           {/* Pagination Controls */}
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(teamMembers.length / casesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
    
          
          <Modal isOpen={isModalOpen} onClose={handleCancelClick}>
          {/* Pass the selected case data to EditCaseForm */}
          {editingTeam && (
            <EditTeamMembersForm
              teamData={teamMembers.find((member) => member.id === editingTeam)}
              onCancel={handleCancelClick}
              onClose={closeModal}
            />
          )}
        </Modal>

        </table>
      </div>
    </>
  );
};

export default TeamMemberdata;
