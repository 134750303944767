import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
  BsFillBriefcaseFill, // Assuming this for "Cases"
  BsCalendar2Event, // Assuming this for "Appointments"
  BsCashStack, // Assuming this for "Invoices" and "Bills"
  BsFillPersonLinesFill, // Assuming this for "People"
  BsFillPeopleFill, // Assuming this for "Teams"
  BsFillClipboardDataFill, // Assuming this for "Tasks"
  BsFillBuildingFill,
  BsWallet,
  BsFileEarmarkText, BsChatDots, // Assuming this for "Companies" and "Groups"
  BsFillCalendarEventFill // Assuming this for "Court Hearings"
} from 'react-icons/bs';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import style from './style/Context.module.css'
import { useNavigate } from 'react-router-dom';
import { data } from '../../evn/evn';

function Context() {
  const navigate = useNavigate(); // Step 2
  const [counts, setCounts] = useState({});
  useEffect(() => {
    const fetchCounts = async () => {
      const ip = data.evn; // Ensure you have the correct environment variable or URL
      const token = localStorage.getItem('adminToken'); // Assuming authentication token is stored in localStorage
      
      try {
        const response = await axios.get(`${ip}/admin/dashboard-counts`, {
          headers: { 'x-auth-token': token },
        });
        setCounts(response.data);
      } catch (error) {
        console.error("There was an error fetching the dashboard counts:", error);
      }
    };

    fetchCounts();
  }, []);
  const handleNavigate = (path) => {
    navigate(path); // This function navigates to the given path
  };



  return (

    
      <main className={style.MainContainer}>
        <div className={style.MainTitle}>
          <h3>DASHBOARD</h3>
        </div>
        
        <div className={style.MainCards}>
        <div onClick={() => handleNavigate('/allusers')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>USERS</h3>
              <BsPeopleFill className={style.CardIcon}/>
            </div>
            <h1>{counts.users || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allwallets')} className={style.Card}>
          <div className={style.CardInner}>
            <h3>WALLETS</h3>
            <BsWallet className={style.CardIcon}/> {/* Wallet Icon */}
          </div>
          <h1>{counts.wallets || 0}</h1> 
        </div>
          <div onClick={() => handleNavigate('/casemanagement')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>CASES</h3>
              <BsFillBriefcaseFill className={style.CardIcon}/>
            </div>
            <h1>{counts.cases || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allpeople')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>PEOPLE</h3>
              <BsFillPersonLinesFill className={style.CardIcon}/>
            </div>
            <h1>{counts.clients || 0}</h1>
          </div>
          
          <div onClick={() => handleNavigate('/allinvoices')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>INVOICES</h3>
              <BsCashStack className={style.CardIcon}/>
            </div>
            <h1>{counts.invoices || 0}</h1>
          </div>

          <div onClick={() => handleNavigate('/allbills')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>BILLS</h3>
              <BsCashStack className={style.CardIcon}/>
            </div>
            <h1>{counts.bills || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allteammembers')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>TEAMS</h3>
              <BsFillPeopleFill className={style.CardIcon}/>
            </div>
            <h1>{counts.teamMembers || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/alltasks')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>TASKS</h3>
              <BsFillClipboardDataFill className={style.CardIcon}/>
            </div>
            <h1>{counts.alerts || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/proxypayment')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>PROXIES</h3>
              <BsFillBellFill className={style.CardIcon}/>
            </div>
            <h1>{counts.proxies || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allgroups')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>GROUPS</h3>
              <BsFillBuildingFill className={style.CardIcon}/>
            </div>
            <h1>{counts.groups || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allcompanies')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>COMPANIES</h3>
              <BsFillBuildingFill className={style.CardIcon}/>
            </div>
            <h1>{counts.companies || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allhearings')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>COURT HEARINGS</h3>
              <BsFillCalendarEventFill className={style.CardIcon}/>
            </div>
            <h1>{counts.courtHearings || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allappointments')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>APPOINTMENTS</h3>
              <BsCalendar2Event className={style.CardIcon}/>
            </div>
            <h1>{counts.appointments || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allreviewdocs')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>REVIEW DOC</h3>
              <BsFileEarmarkText className={style.CardIcon}/>
            </div>
            <h1>{counts.reviewDocs || 0}</h1>
          </div>
          <div onClick={() => handleNavigate('/allchats')} className={style.Card}>
            <div className={style.CardInner}>
              <h3>CHATS</h3>
              <BsChatDots className={style.CardIcon}/>
            </div>
            <h1>{counts.chats || 0}</h1>
          </div>
        </div>
      </main>
    );
  }
  
  export default Context;