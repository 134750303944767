import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn';
import Sidebar from '../AdminComponents/SideBar';
import styles from './BillManagement.module.css';
import { useNavigate } from 'react-router-dom';

const BillManagement = () => {
  const [bills, setBills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();
  const ip = data.evn;

  useEffect(() => {
    const fetchBills = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/bills`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setBills(response.data.bills);
        setSearchResults(response.data.bills);
      } catch (error) {
        console.error("There was an error fetching the bills:", error);
      }
    };

    fetchBills();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(bills);
    } else {
      const filteredBills = bills.filter(bill => {
        if (searchType === 'id') return bill.id.toString().includes(event.target.value);
        else if (searchType === 'userId') return bill.user_id.toString().includes(event.target.value);
        else return bill.billNumber.includes(event.target.value);
      });
      setSearchResults(filteredBills);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(bills);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchBills = bills.filter(bill => {
        if (searchType === 'id') return bill.id.toString() === searchTerm;
        else if (searchType === 'userId') return bill.user_id.toString() === searchTerm;
        else return bill.billNumber === searchTerm;
      });
      setSearchResults(exactMatchBills);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
            <option value="billNumber">Search by Bill Number</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Bill ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Bill Number</th>
              <th className={styles.tableHeader}>Title</th>
              <th className={styles.tableHeader}>Current Date</th>
              <th className={styles.tableHeader}>Date From</th>
              <th className={styles.tableHeader}>Date To</th>
              <th className={styles.tableHeader}>Full Address</th>
              <th className={styles.tableHeader}>Amount</th>
              <th className={styles.tableHeader}>Tax Type</th>
              <th className={styles.tableHeader}>Tax Percentage</th>
              <th className={styles.tableHeader}>Total Amount With Tax</th>
              <th className={styles.tableHeader}>Description</th>
              <th className={styles.tableHeader}>Document</th>
              
            </tr>
          </thead>
          <tbody>
            {searchResults.map((bill) => (
              <tr key={bill.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{bill.id}</td>
                <td className={styles.tableCell}>{bill.user_id}</td>
                <td className={styles.tableCell}>BILL-{bill.billNumber}</td>
                <td className={styles.tableCell}>{bill.title}</td>
                <td className={styles.tableCell}>{bill.currentDate}</td>
                <td className={styles.tableCell}>{bill.dateFrom}</td>
                <td className={styles.tableCell}>{bill.dateTo}</td>
                <td className={styles.tableCell}>{bill.fullAddress}</td>
                <td className={styles.tableCell}>{bill.amount}</td>
                <td className={styles.tableCell}>{bill.taxType}</td>
                <td className={styles.tableCell}>{bill.taxPercentage}</td>
                <td className={styles.tableCell}>{bill.totalAmountWithTax}</td>
                <td className={styles.tableCell}>{bill.description}</td>
                <td className={styles.tableCell}>
  {bill.addDoc ? (
    <a className={styles.link} href={`${ip}/uploads/${bill.addDoc}`} target="_blank" rel="noopener noreferrer">
      {bill.addDoc}
    </a>
  ) : 'No document available'}
</td>

                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BillManagement;
