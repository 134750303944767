import React from 'react'
import style from './VideoModal.module.css'

const VideoModal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  return (
    <div className={style.videoModalOverlay} style={{ display: isOpen ? 'flex' : 'none' }}>
      <div className={style.videoModalContent}>
        <button onClick={onClose} className={style.modalCloseButton}>X</button>
        <video className={style.video} controls autoPlay>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default VideoModal