import React, { useEffect, useState } from 'react';
import styles from './EditUpdateCaseForm.module.css';
// import DashboardNavbar from '../../../utilities/DashboardNavbar/DashboardNavbar';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Tooltip } from '@mui/material';
import {data} from '../../../../evn/evn'

const EditUpdateCaseForm = ({onClose, caseId }) => {
    const navigate = useNavigate();
    const [manualData, setManualData] = useState({});
    const [formData, setFormData] = useState({});
    const ip = data.evn
    const [clientNames, setClientNames] = useState([]); // State to store client first names
    const [teamMembers, setTeamMembers] = useState([]); // State to store team member full names
    const [isForm1Submitted, setIsForm1Submitted] = useState(false);
    const [isForm2Submitted, setIsForm2Submitted] = useState(false);
    
  
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const handleManualChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    
   

      useEffect(() => {
        axios.get(`${ip}/edit/updatecases/${caseId}`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        })
        .then((response) => {
          const responseData = response.data;
          setFormData(responseData);
        })
        .catch((error) => {
          console.error(error);
        });
      
      }, [caseId]); // Add caseId as a dependency here

      const handleSubmit = async () => {
        try {
            const response = await axios.put(
                `${ip}/edit/updatecases/update/${formData.id}`,
                formData,
                {
                    headers: {
                        'x-auth-token': localStorage.getItem('token'),
                    },
                }
            );
    
            alert('Case Updated successfully!');
            navigate(0); // This will reload the page. Consider using navigate to a specific route instead.
    
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data.error) {
                // Handle specific error message here if needed
                alert(error.response.data.error);
            } else {
                alert('An error occurred while updating the case.');
            }
        }
    };
    const handleCancel = () => {
        onClose();
      }
      


      const handleConcernedPersonSubmit = async (values, { resetForm }) => {
        if (caseId) {
          try {
            values.caseId = caseId; // Attach the caseId to the form data
            const response = await axios.post(`${ip}/concernedperson`, values, {
              headers: {
                'x-auth-token': localStorage.getItem('token'),
              },
            });
            alert('Concerned Person and Opponent Added successfully!');
            setIsForm2Submitted(true);
            navigate(-1);
            resetForm();
            setIsForm1Submitted(true);
      
          } catch (error) {
            console.error(error);
          }
        } else {
          alert('Error: No associated case found.');
        }
      };
      
     
  useEffect(() => {
    // Fetch client first names and populate the select options
    const fetchClientNames = async () => {
      try {
        const response = await axios.get(`${ip}/clientform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });

        // Extract the first names from the response data
        const firstNamesArray = response.data.map((client) => client.firstName);
        setClientNames(firstNamesArray);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch team member full names and populate the select options
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${ip}/teammemberform`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Get the token from localStorage or your authentication mechanism
          },
        });

        // Extract the full names from the response data
        const fullNamesArray = response.data.map((member) => member.fullName);
        setTeamMembers(fullNamesArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientNames(); // Call the fetchClientNames function when the component mounts
    fetchTeamMembers(); // Call the fetchTeamMembers function when the component mounts
  }, []);

  
     

    return (
        <>
        
        <div className={styles.formContainer}>
            
            
            <h2 className={styles.heading}>EDIT CASE</h2> 
            <div className={styles.manualEntryForm}>
    {/* Row 1 */}
    <div className={styles.formRow}>
        {/* CNR No. */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="cino">CNR No.</label>
            <input id="cino" name="cino" placeholder="CNR No." value={formData.cino || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Type Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="type_name">Case Type</label>
            <input id="type_name" name="type_name" placeholder="Type Name" value={formData.type_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Case No. */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="case_no">Case No.</label>
            <input id="case_no" name="case_no" placeholder="Case No." value={formData.case_no || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Registration Year */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="reg_year">Registration Year</label>
            <input id="reg_year" name="reg_year" placeholder="Registration Year" value={formData.reg_year || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
    </div>

    {/* Row 2 */}
    <div className={styles.formRow}>
        {/* Registration No. */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="reg_no">Registration No.</label>
            <input id="reg_no" name="reg_no" placeholder="Registration No." value={formData.reg_no || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Petparty Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="petparty_name">Petitioner</label>
            <input id="petparty_name" name="petparty_name" placeholder="Petparty Name" value={formData.petparty_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Resparty Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="resparty_name">Respondent</label>
            <input id="resparty_name" name="resparty_name" placeholder="Resparty Name" value={formData.resparty_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Filing Year */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="fil_year">Filing Year</label>
            <input id="fil_year" name="fil_year" placeholder="Filing Year" value={formData.fil_year || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
    </div>

    {/* Row 3 */}
    <div className={styles.formRow}>
        {/* Filing No. */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="fil_no">Filing No.</label>
            <input id="fil_no" name="fil_no" placeholder="Filing No." value={formData.fil_no || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Establishment Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="establishment_name">Role-Specific Jurisdiction</label>
            <input id="establishment_name" name="establishment_name" placeholder="Establishment Name" value={formData.establishment_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Establishment Code */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="establishment_code">Specific Jurisdiction Code</label>
            <input id="establishment_code" name="establishment_code" placeholder="Establishment Code" value={formData.establishment_code || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* State Code */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="state_code">State Code</label>
            <input id="state_code" name="state_code" placeholder="State Code" value={formData.state_code || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
    </div>

    {/* Row 4 */}
    <div className={styles.formRow}>
        {/* State Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="state_name">State Name/High Court</label>
            <input id="state_name" name="state_name" placeholder="State Name" value={formData.state_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* District Code */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="district_code">District Code</label>
            <input id="district_code" name="district_code" placeholder="District Code" value={formData.district_code || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* District Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="district_name">District Name/Bench</label>
            <input id="district_name" name="district_name" placeholder="District Name" value={formData.district_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
        {/* Court No/Desg Name */}
        <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="court_no_desg_name">Judicial Role</label>
            <input id="court_no_desg_name" name="court_no_desg_name" placeholder="Court No/Desg Name" value={formData.court_no_desg_name || ''} onChange={handleManualChange}  className={styles.inputn} />
        </div>
    </div>
    <div className={styles.formRow}>
    {/* Date of Decision */}
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="date_of_decision">Final Decision Date</label>
        <input id="date_of_decision" name="date_of_decision" type="date" value={formData.date_of_decision || ''} placeholder="Date of Decision" onChange={handleManualChange}  className={styles.inputn} />
    </div>
    {/* Date Last List */}
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="date_last_list">Last Hearing Date</label>
        <input id="date_last_list" name="date_last_list" type="date" value={formData.date_last_list || ''} placeholder="Date Last List" onChange={handleManualChange}  className={styles.inputn} />
    </div>
     {/* Diary No. */}
     <div className={styles.fileInputContainer}>
            <label className={styles.labels} htmlFor="diaryno">Diary No.</label>
             <input id="diaryno" type="text" name="diaryno" value={formData.diaryno || ''} placeholder="Diary No."onChange={handleManualChange}  className={styles.inputn}/>
            </div>
    {/* Updated */}
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="updated">Updated</label>
        <input id="updated" name="updated" type="text" value={formData.updated || ''} placeholder="Updated" onChange={handleManualChange}  className={styles.inputn} />
    </div>
    {/* Date Next List */}
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="date_next_list">Upcoming Hearing Date</label>
        <input id="date_next_list" name="date_next_list" type="date" value={formData.date_next_list || ''} placeholder="Date Next List" onChange={handleManualChange}  className={styles.inputn} />
    </div>
</div>
{/* <div className={styles.formRow}>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="ltype_name">Ltype Name</label>
        <input id="ltype_name" name="ltype_name" placeholder="Ltype Name" value={formData.ltype_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="lpetparty_name">LPetparty Name</label>
        <input id="lpetparty_name" name="lpetparty_name" placeholder="LPetparty Name" value={formData.lpetparty_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="lresparty_name">LResparty Name</label>
        <input id="lresparty_name" name="lresparty_name" placeholder="LResparty Name" value={formData.lresparty_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="lestablishment_name">LEstablishment Name</label>
        <input id="lestablishment_name" name="lestablishment_name" placeholder="LEstablishment Name" value={formData.lestablishment_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
</div>
<div className={styles.formRow}>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="lstate_name">LState Name</label>
        <input id="lstate_name" name="lstate_name" placeholder="LState Name" value={formData.lstate_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
   
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="ldistrict_name">LDistrict Name</label>
        <input id="ldistrict_name" name="ldistrict_name" placeholder="LDistrict Name" value={formData.ldistrict_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
    
    <div className={styles.fileInputContainer}>
        <label className={styles.labels} htmlFor="lcourt_no_desg_name">LCourt No/Desg Name</label>
        <input id="lcourt_no_desg_name" name="lcourt_no_desg_name" placeholder="LCourt No/Desg Name" value={formData.lcourt_no_desg_name || ''} onChange={handleManualChange}  className={styles.inputn} />
    </div>
</div> */}

{/* <div className={styles.heading}>Concerned Person</div>
            
            
            <div className={styles.row}>
            <div className={styles.column}>
            <label className={styles.label}>People:</label>
            <select name="client" className={styles.selectClient} onChange={handleManualChange} value={formData.client || ''}>
        <option value="">Select</option>
        {clientNames.map((firstName) => (
            <option key={firstName} value={firstName}>{firstName}</option>
        ))}
    </select>
              <NavLink className={styles.linkClient} to="/dashboard/peopleform">Add New People</NavLink>
            </div>
            <div className={styles.columnTeam}>
            <label className={styles.labelTeam}>Team:</label>
            <select name="team" className={styles.selectTeam} onChange={handleManualChange} value={formData.team || ''}>
        <option value="">Select an option</option>
        {teamMembers.map((fullName) => (
            <option key={fullName} value={fullName}>{fullName}</option>
        ))}
    </select>
              <NavLink className={styles.linkTeam} to="/dashboard/teammemberform">Add New Member</NavLink>
            </div>
            </div>

            <div className={styles.formGroup}>
  <div className={styles.column}>
    <label className={styles.label} htmlFor="type">Type</label>
    <select name="type" className={styles.selectCd} value={formData.type || ''} onChange={handleManualChange}>
      <option value="">Select Type</option>
      <option value="Client">Client</option>
      <option value="Lawyers">Lawyers</option>
      <option value="OpposingClient">Opposing Client</option>
      <option value="Witness">Witness</option>
    </select>
  </div>
</div>


{formData.type === "Lawyers" && (
  <div className={styles.formGroup}>
    <div className={styles.column}>
      <label className={styles.label} htmlFor="lawyerType">Lawyer Type</label>
      <select name="lawyerType" className={styles.selectCd} value={formData.lawyerType || ''} onChange={handleManualChange}>
        <option value="">Select Lawyer Type</option>
        <option value="CorporateLawyer">Corporate Lawyer</option>
        <option value="CriminalDefenseLawyer">Criminal Defense Lawyer</option>
        <option value="FamilyLawyer">Family Lawyer</option>
        <option value="TaxLawyer">Tax Lawyer</option>
        <option value="IntellectualPropertyLawyer">Intellectual Property Lawyer</option>
        <option value="EmploymentLawyer">Employment Lawyer</option>
        <option value="EnvironmentalLawyer">Environmental Lawyer</option>
        <option value="EstatePlanningLawyer">Estate Planning Lawyer</option>
        <option value="PersonalInjuryLawyer">Personal Injury Lawyer</option>
        <option value="ImmigrationLawyer">Immigration Lawyer</option>
        <option value="BankruptcyLawyer">Bankruptcy Lawyer</option>
        <option value="CivilLitigationLawyer">Civil Litigation Lawyer</option>
        <option value="RealEstateLawyer">Real Estate Lawyer</option>
        <option value="ConstitutionalLawyer">Constitutional Lawyer</option>
        <option value="EntertainmentLawyer">Entertainment Lawyer</option>
      </select>
    </div>
  </div>
)}






 <div className={styles.heading}>Opponent</div>

<div className={styles.row1}>
<div className={styles.column}>
<label className={styles.label}>Party:</label>
  <input type="text" name="opponentPartyName" value={formData.opponentPartyName || ''} onChange={handleManualChange} className={styles.inputopn} />
</div>



<div className={styles.column}>
<label className={styles.labelln}>Lawyer Name:</label>
  <input type="text" name="lawyerName" value={formData.lawyerName || ''} onChange={handleManualChange} className={styles.inputopn} />
</div>
</div>

<div className={styles.row1}>
<div className={styles.column}>
<label className={styles.label}>Mobile No:</label>
  <input type="text" name="mobileNo" value={formData.mobileNo || ''} onChange={handleManualChange}  className={styles.inputopn} />
</div>


<div className={styles.column}>
  <label className={styles.labelei}>Email Id:</label>
  <input type="text" name="emailId" value={formData.emailId || ''} onChange={handleManualChange}  className={styles.inputopn} />
</div>
</div>


                <div className={styles.fileInputContainer}>
                   <label className={styles.labels} htmlFor="note">Note</label>
                   <textarea id="note" name="note" placeholder="Note" value={formData.note || ''} onChange={handleManualChange} className={styles.TextArea} />
                </div> */}
            
            </div>
            
              <div className={styles.BtnContainer}>
              <Tooltip title="Update Case" disableInteractive>
            <button onClick={handleSubmit} className={styles.submitButton} >
                UPDATE
            </button>
            </Tooltip>
            <Tooltip title="Cancel Case" disableInteractive>
            <button  type="button" className={styles.CancelButton} onClick={handleCancel} >CANCEL</button>
            </Tooltip>
            </div>
        </div>
    </>
    );
};

export default EditUpdateCaseForm;

