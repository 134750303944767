export const testimonials = [
    
    {
        id:1,
        name:"Advocate Ravi Grover",
        info:"As a busy lawyer, time is always of the essence. Lawfaxai  has revolutionized how I handle document generation. What used to take hours of tedious work now just takes minutes. The intuitive interface and customizable templates make it a breeze to create polished, professional documents tailored to each client’s needs. I’ve been able to streamline my workflow and focus more on serving my clients effectively.",
        // job:"abcd1",
        avatar: require('../../../assets/AdvRaviGrover.jpg')
    },
    {
        id:2,
        name:"Advocate Lovin Garg",
        info:"Lawfaxai has been an indispensable tool in my legal practice.  I can effortlessly arrange for a trusted colleague to attend to my cases when I’m unable to be there in person. This innovative proxy generation feature ensures continuity in client representation and allows me to manage my workload with ease. Lawfaxai has truly transformed the way I practice law.",
        // job:"abcd2",
        avatar: require('../../../assets/AdvLovinGarg.jpg')
    },
   
]