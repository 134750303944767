import "./navbar.css";
import React, { useEffect } from "react";
import { FaBars } from "react-icons/fa";

import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { links } from "./data";
import { useState } from "react";
import { useAuth } from "../../../pages/Login-Logout/AuthContext";
import ProfileDropdown from "./Profile/ProfileDropdown";
import { data } from '../../../evn/evn'
import axios from 'axios';


const Navbar = ({ onClose, user }) => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const { isLoggedIn, logout } = useAuth();
  const ip = data.evn
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false); // Updated to false

  const [formData, setFormData] = useState({
    name: "",
    avatar_url: "",
  });

  // Signout using JWT Token
  const handleSignOut = () => {
    console.log('Token from local storage:', localStorage.getItem('token'));

    axios.post(`${ip}/logout`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
    })
    .then(response => {
      console.log('Logout response status:', response.status);
      if (response.status === 200) { // Check if the status code is 200 (OK)
        console.log('Logged out and status updated');
        // Only perform these actions if the logout was successful
        localStorage.removeItem('token');
        setIsNavShowing(false); // Update UI state to reflect logout
        if (typeof logout === 'function') {
          logout(); // Call any additional cleanup actions
        }
      }
    })
    .catch(error => {
      console.error('Error logging out:', error);
    });
};

  const linkStyle = {
    color:'white', 
    textDecoration: 'none',
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${ip}/profile`, {
          method: "GET",
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching profile data. Status: ${response.status}`);
        }
        const data = await response.json();
        // Check and prepend domain if necessary
        data.avatar_url = data.avatar_url.startsWith('http') ? data.avatar_url : `${ip}${data.avatar_url}`;
        setFormData(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
       
      }
    };
    fetchProfileData();
  }, []);
  
  
  
  return (
    <nav className={`container nav-container ${isNavShowing ? 'show-nav' : ''}`}>
      <div className="nav-logo">
      <div className="logo-and-tagline-container">
        <h1>
          <NavLink className={"logo"} to={"/"} onClick={() => setIsNavShowing(false)}>
          <h1 className="heading">LAW<span className="logo-span">FAX</span> AI</h1>
          
          
          </NavLink>
        </h1>
        <span className="taglines">CRAFTING THE FUTURE OF LAW WITH AI</span>
        </div>
      </div>

      <div className={`navbar-menu-links ${isNavShowing ? 'show-nav' : 'hide-nav'}`}>
        <ul className="navbar-lists">
          {isLoggedIn ? (
            <>
              {/* Iterating Navbar Menu Through Data file */}
              {links.map(({ name, path }, index) => (
                <li className="navbar-lists-links" key={index}>
                  <NavLink to={path} className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} style={linkStyle}>
                    {name} 
                  </NavLink>
                </li>
              ))}
              <li className="navbar-lists-links" value={formData.name} >
              <NavLink to="#" onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)} style={linkStyle}>
              <img src={formData.avatar_url || "default-avatar-url"} alt="Avatar" style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px', verticalAlign: 'middle'}} />
              {formData.name}
            </NavLink>
              </li>
              {isProfileDropdownOpen && (
                <ProfileDropdown
                  user={user}
                  onClose={() => setIsProfileDropdownOpen(false)}
                  handleSignOut={handleSignOut}
                />
              )}
              
            </>
          ) : (
            <>
            
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} to="/" style={linkStyle}>
                  Home
                </NavLink>
              </li>
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} to="/about" style={linkStyle}>
                  About
                </NavLink>
              </li>
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} to="/services" style={linkStyle}>
                Services
                </NavLink>
              </li>
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} to="/contact" style={linkStyle}>
                Contact
                </NavLink>
              </li>
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} to="/login" style={linkStyle}>
                  Login
                </NavLink>
              </li>
              <li className="navbar-lists-links">
                <NavLink className={({ isActive }) => (isActive ? 'active-nav' : "")} onClick={() => setIsNavShowing(false)} to="/register" style={linkStyle}>
                  Register
                </NavLink>
              </li>
             
            </>
            
          )}
        

        </ul>
        
      </div>

      <button className="nav-toggle-btn" onClick={() => setIsNavShowing((prev) => !prev)}>
        {isNavShowing ? <MdClose /> : <FaBars />}
      </button>

     
    </nav>
  );
};

export default Navbar;
