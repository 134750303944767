import React, { useEffect, useState } from 'react';
import styles from './MaintenancePage.module.css';
import maintenanceImage from '../../assets/maintainance-.png';
import backgroundImageUrl from '../../assets/maintenance.avif'; // Update with your image path

function MaintenancePage() {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
    
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <img src={maintenanceImage} alt="Maintenance" className={styles.image}/>
      <p className={styles.message}>
        Sorry for the inconvenience but we are performing some maintenance at the moment.
        If you need, you can always <a href="/contact" className={styles.contactLink}>contact us</a>, otherwise we'll be back online shortly!
      </p>
      <p className={styles.companyName}>
        <span className={styles.law}>LAW</span><span className={styles.fax}>FAX</span>
      </p>
    </div>
  );
}


export default MaintenancePage;
