import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './MultiFormTabs.module.css';
import { Button, Modal, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import {data} from '../../../../evn/evn'
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import axios, { Axios } from 'axios';
import * as Yup from 'yup';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Companyform from '../../Company/Companyform';
import GroupForm from '../../Group/GroupForm';
import TeamMember from './TeamMember';
import CaseTimeLine from '../CaseTimeLine/CaseTimeLine';
import NotesSection from './NotesSection';
import ConcernPerson from './ConcernPerson';
import Opponents from './Opponents';
import PeopleForm from '../../Client/People/PeopleForm';
import People from './People';
import Member from './Member';
import ConcernPersonTable from './Tables/ConcernPersonTable';
import OpponentTable from './Tables/OpponentTable';
import DocumentTable from './Tables/DocumentTable';
import TaskTable from './Tables/TaskTable';
import PeopleTable from './Tables/PeopleTable';
import MemberTable from './Tables/MemberTable';
import Task from './Task';


const MultiFormTabs = () => {
  const { caseId } = useParams();
  const [caseDetails, setCaseDetails] = useState({});
    const [activeTab, setActiveTab] = useState('Concerned Person');
    const [titleError, setTitleError] = useState('');
    const [selectedCaseTitle, setSelectedCaseTitle] = useState('');
    const [cases, setCases] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [companyNames, setCompanyNames] = useState([]); // State to store group names
    const openModalTwo = () => setIsModalOpenTwo(true);
    const [groupNames, setGroupNames] = useState([]); // State to store group names
    const openModalOne = () => setIsModalOpenOne(true);
    const [isModalOpenOne, setIsModalOpenOne] = useState(false);
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const closeModalOne = () => setIsModalOpenOne(false);
    const closeModalTwo = () => setIsModalOpenTwo(false);
    const [isFileLoaded, setIsFileLoaded] = useState(false);

    const ip = data.evn

    const location = useLocation();
    useEffect(() => {
      // Check if there's a state in the location and if it has activeTab
      if (location.state && location.state.activeTab) {
        setActiveTab(location.state.activeTab);
      }
    }, [location]);

    const switchToPeopleTab = () => {
  // Logic to switch to the People tab
  setActiveTab('People');
};
    const switchToMembers = () => {
  // Logic to switch to the People tab
  setActiveTab('Members');
};

 // Modify the handleCaseTitleChange function
 const handleCaseTitleChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedCase = cases.find((caseItem) => caseItem.id === selectedId);
  
    if (selectedCase) {
      setSelectedCaseTitle(selectedCase.title); // Update selectedCaseTitle
      formik.setFieldValue('caseType', selectedCase.type_name);
    } else {
      setSelectedCaseTitle(''); // Clear the selectedCaseTitle if no case is selected
      formik.setFieldValue('caseType', ''); // Clear the field if no case is selected
    }
  
    // Update the formik field value for caseTitle
    formik.setFieldValue('caseTitle', event.target.value);
  };

  const initialValues = {
    title: '',
    caseTitle: '',
    caseType: '', // Remove the initial value here
    startDate: '',
    completionDate: '',
    assignFrom: '',
    assignTo: '',
  };


  
const handleFileRead = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = (e.target.result);
            try {
                const parsedCases = JSON.parse(text).map(caseStr => JSON.parse(caseStr));
                setCases(parsedCases);
                setIsFileLoaded(true);
            } catch (error) {
                toast.error("Error Add Valied Case File", error);
                setIsFileLoaded(false);
            }
        };
        reader.readAsText(file);
    }
};

const handleSubmit2 = async (event) => {
  event.preventDefault(); // Prevent the form from submitting the traditional way

  const fileInput = document.querySelector('input[type="file"]');
  const formData = new FormData();
  if (fileInput.files[0]) {
      formData.append('case_file', fileInput.files[0]);
      // Use the caseId obtained from useParams at the top level
      formData.append('caseId', caseId);

      try {
          const response = await axios.post(`${ip}/uploadcasefile`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'x-auth-token': localStorage.getItem('token'), // Include your auth token if necessary
              },
          });

          toast.success('Case file submitted successfully!');
          setTimeout(() => {
            navigate(0); // Reloads the current page
          }, 2000);
      } catch (error) {
          toast.error('Error submitting case file');
      }
  } else {
      toast('Please select a file to upload');
  }
};
  const navigate = useNavigate();
  const HandleCancel=()=>{
    navigate(-1)
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    caseTitle: Yup.string().required('Case Title is required'),
    caseType: Yup.string(),
    startDate: Yup.date(),
  completionDate: Yup.date()
    .min(
      Yup.ref('startDate'),
      "Completion date can't be before the start date"
    ),
    assignFrom: Yup.string(),
    assignTo: Yup.string(),
  });
  useEffect(() => {
    // Fetch case details
    axios.get(`${ip}/edit/updatecases/${caseId}`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    })
    .then(response => {
      // Assuming response.data contains the case details including the file path
      setCaseDetails(response.data);
    })
    .catch(error => {
      toast.error('Failed to load case details.');
    });
  }, [caseId, ip]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${ip}/dashboard/alert/teammembers`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setTeamMembers(response.data.map((member) => member.name));
      } catch (error) {
        console.error('Error fetching team members:');
      }
    };

    const fetchCases = async () => {
      try {
        const response = await axios.get(`${ip}/caseform`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setCases(response.data);
      } catch (error) {
        console.error('Error fetching cases:');
      }
    };
    fetchTeamMembers();
    fetchCases();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        const response = await axios.post(`${ip}/alerts`, values, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        toast('Tasks added successfully!');
        resetForm();
        setTitleError(''); // Clear any existing title error
      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.error) {
          // Set the title error if it exists
          if (error.response.data.error.includes('title')) {
            setTitleError(error.response.data.error);
          } else {
            toast.error('Error submitting form');
          }
        } 
      }
    },
  });

  const setActiveTabToPeople = () => {
    setActiveTab('People'); // Assuming 'People' is the value for the People tab
  };

    const TabContent = () => {
        switch (activeTab) {
            case 'activity':
                return (
                    <form>
                        {/* Start Activity */}
                        {/* <CaseTimeLine /> */}
                        {/* End Activity */}
                        
                    </form>
                );
                case 'Concerned Person':
                  return (
                    <div>
                      <div className="concernPersonContainer">
                        <ConcernPerson
                          switchToPeopleTab={switchToPeopleTab}
                          switchToMembers={switchToMembers}
                        />
                      </div>
                
                      <div className="concernPersonTableContainer">
                        <ConcernPersonTable />
                      </div>
                    </div>
                  );
                
            case 'Opponent':
                return (
                    
                     <div>
                      <div className="concernPersonContainer">
                      <Opponents />
                      </div>
                
                      <div className="concernPersonTableContainer">
                      <OpponentTable />
                      </div>
                    </div>
                );
            case 'Document':
                return (
                  <>
                    <form>
                        {/* Document Start */}
                        <div className={styles.fileInputContainer}>
                        <label htmlFor="fileInput" className={styles.labels}>Upload Case File<span className={styles.required}>*</span></label>
                        <input type="file" name='case_file' className={styles.fileInput} accept=".pdf" id="fileInput" />
                        {caseDetails.case_file && (
          <div>
            <a href={`${ip}/uploads/${caseDetails.case_file}`} target="_blank" rel="noopener noreferrer">
              Download Uploaded File
            </a>
          </div>
        )}
                            <div className={styles.BtnContainer}>
                            <Tooltip title="Submit Form">

                                <button variant="outlined" color="success" onClick={handleSubmit2} className={styles.submitButton} >
                                    Submit
                                </button>
                                <ToastContainer />
                                </Tooltip>
                                    
                                <NavLink to={"/dashboard/Importcase"}>

                                <Tooltip title="Cancel Form">
                                    <button className={styles.submitButton} variant="outlined" color="error" type="submit">Cancel</button>
                                </Tooltip>
                                </NavLink>
                                </div> 
                        </div>                      
                    </form>
                    <DocumentTable />
                    </>
                );
                {/* Document End */}

            case 'Notes':
                return (
                    <form>
                        {/* Notes start */}
                        <NotesSection />
                        {/* Notes End */}
                    </form>
                );
            case 'People':
                return (
                  <div>
                      <div className="concernPersonContainer">
                      <People />
                      </div>
                
                      <div className="concernPersonTableContainer">
                      <PeopleTable />
                      </div>
                    </div>
                    
                );
            case 'Members':
                return (
                  <div>
                      <div className="concernPersonContainer">
                      <Member />
                      </div>
                
                      <div className="concernPersonTableContainer">
                      <MemberTable />
                      </div>
                    </div>
                    
                );
            case 'Task':
                return (
                  <>
                    <div>
                      <div className="concernPersonContainer">
                      <Task />
                      </div>
                
                      <div className="concernPersonTableContainer">
                      <TaskTable />
                      </div>
                    </div>
                  </>
                );
                {/* Task form  End point*/}

            // Add cases for each tab content
            default:
                return <div>Default Content</div>;
        }
    };
    
    return (
        <div>
            <div className={styles.tabTitles}>
                {/* <button onClick={() => setActiveTab('activity')} className={activeTab === 'activity' ? styles.activeTab : ''}>
                    Activity / History
                </button> */}
                <button onClick={() => setActiveTab('Concerned Person')} className={activeTab === 'Concerned Person' ? styles.activeTab : ''}>
                Concerned Person
                </button>
                <button onClick={() => setActiveTab('Opponent')} className={activeTab === 'Opponent' ? styles.activeTab : ''}>
                Opponent
                </button>
                <button onClick={() => setActiveTab('Document')} className={activeTab === 'Document' ? styles.activeTab : ''}>
                    Document
                </button>
                <button onClick={() => setActiveTab('Notes')} className={activeTab === 'Notes' ? styles.activeTab : ''}>
                    Notes
                </button>
            </div>
            <ToastContainer/>
            <div className={styles.tabContent}>
                <TabContent />
            </div>
        </div>
    );
}

export default MultiFormTabs;
