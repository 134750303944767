import React from "react";
// import {FaCrown} from 'react-icons/fa'
import style from "./benfits.module.css";

const Benifits = ({ icons, title, className }) => {
  return (
    <div className={`${style.sectionHead} ${className}`}>
      <span className={style.icon} style={{fontSize:"1rem"}}>{icons}</span>
      <h2 className={style.heading} style={{fontSize:"3.8rem"}}>{title}</h2>
    </div>
  );
};

export default Benifits;