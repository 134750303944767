import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import { useNavigate } from 'react-router-dom';
import styles from './TeamMemberManagement.module.css'; // Ensure a similar CSS module for styling


const TeamMemberManagement = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();
  const ip = data.evn;
  useEffect(() => {
    const fetchTeamMembers = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/team-members`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setTeamMembers(response.data.teamMembers); // Adjust this according to your actual response structure
        setSearchResults(response.data.teamMembers);
      } catch (error) {
        console.error("There was an error fetching the team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(teamMembers);
    } else {
      const filteredTeamMembers = teamMembers.filter(member => {
        return searchType === 'id' ? member.id.toString().includes(event.target.value) : member.user_id.toString().includes(event.target.value);
      });
      setSearchResults(filteredTeamMembers);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reset search term
    setSearchResults(teamMembers); // Show all team members
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchTeamMembers = teamMembers.filter(member => {
        return searchType === 'id' ? member.id.toString() === searchTerm : member.user_id.toString() === searchTerm;
      });
      setSearchResults(exactMatchTeamMembers);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Team ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Image</th>
              <th className={styles.tableHeader}>Full Name</th>
              <th className={styles.tableHeader}>Email</th>
              <th className={styles.tableHeader}>Designation</th>
              <th className={styles.tableHeader}>Address</th>
              <th className={styles.tableHeader}>State</th>
              <th className={styles.tableHeader}>City</th>
              <th className={styles.tableHeader}>Zip Code</th>
              <th className={styles.tableHeader}>Selected Group</th>
              
              <th className={styles.tableHeader}>Selected Company</th>
              <th className={styles.tableHeader}>Mobile No</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((member) => (
              <tr key={member.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{member.id}</td>
                <td className={styles.tableCell}>{member.user_id}</td>
                <td className={styles.tableCell}>
  {member.image ? (
    <a href={`${ip}/uploads/${member.image}`} target="_blank" rel="noopener noreferrer" className={styles.link}>
      {member.image}
    </a>
  ) : 'No image available'}
</td>

                <td className={styles.tableCell}>{member.fullName}</td>
                <td className={styles.tableCell}>{member.email}</td>
                <td className={styles.tableCell}>{member.designation}</td>
                <td className={styles.tableCell}>{member.address}</td>
                <td className={styles.tableCell}>{member.state}</td>
                <td className={styles.tableCell}>{member.city}</td>
                <td className={styles.tableCell}>{member.zipCode}</td>
                <td className={styles.tableCell}>{member.selectedGroup}</td>
                
                <td className={styles.tableCell}>{member.selectedCompany}</td>
                <td className={styles.tableCell}>{member.mobileno}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TeamMemberManagement;  