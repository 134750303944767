import React, { useEffect, useState } from "react";
import style from "./home.module.css";
import Header from "../../component/utilities/MainHeader/Header";
import Benifits from "./benifits/Benifits";
import { FaCrown } from "react-icons/fa";
import { benfit } from "./data";
import Card from "../../component/utilities/card/Card";
import Values from "../values/Values";
import Testimonials from "../../component/utilities/testimonials/Testimonials";
import Footer from "../../component/utilities/footer/footer";
import ReviewDocForm from "../../component/form/ReviewFormDoc/ReviewDocForm";
import CaseDetail from "../../component/form/CaseDetail/CaseDetail";
import CaseTitleData from "../../component/form/AddCase/CaseTitleData/CaseTitleData";
import MultiFormTabs from "../../component/form/AddCase/CaseTitleData/MultiFormTabs";
import AdminMainPanel from "../../Admin/AdminMainPanel/AdminMainPanel";
import ChatBox from "../../component/DocumentChatBot/ChatBox";
import Subscription from "../../component/form/Subscription/Subscription"
import WebhookTesting from "../../component/form/DocumentGenration/WebhookTesting";
import CaseMine from "../../component/form/Casemine/CaseMine";
// import videos from '../../assets/VIDEO.mp4'
// import demovideos from '../../assets/demovideo.MOV'
// import Chatpage from '../Chat/Chatpage'
// import ChatApp from "../../component/chat/ChatApp";
import {data} from '../../evn/evn'
// import CaseMine from '../../component/form/caseMine/CaseMine'
import BenifitsTwo from "./benifits/BenifitsTwo";
import videoImage from '../../assets/videoimage.png'
import { FaPlay } from 'react-icons/fa';
import VideoModal from "./VideoModal";

// Then use a conditional rendering to show a modal or video player


const Home = () => {
  const ip = data.evn
  const video = `${ip}/lawfaxvideo.mp4`;
  const [isActive, setIsActive] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

const handleOpenVideo = () => {
  setIsVideoPlaying(true);
};

const handleCloseVideo = () => {
  console.log('Closing video modal');  // Check if this logs when the button is clicked
  setIsVideoPlaying(false);
};


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  useEffect(() => {
    // Trigger the animation after the component mounts
    setIsActive(true);
  }, []);
 

  return (
     <div  className={style.main}>
      <Header />
      <div className={style.videoImageContainer}>
    
        <img className={style.image} src={videoImage} alt="Video Thumbnail" />

      </div>
        <button className={style.playButton} onClick={handleOpenVideo}> 
            <FaPlay size={40} color="#FFF" />
        </button>

      <div className={style.VideoContainer}>
      <VideoModal className={style.videoModal} isOpen={isVideoPlaying} onClose={handleCloseVideo} videoSrc={video} />
      </div>

      {/* <button onClick={handleOpenModal}>Download PDF</button> */}
      {/* {isModalOpen && <CaseMine onClose={handleCloseModal} />} */}
      <section className={style.benfits}>
          {/* <ChatBox /> */}
          {/*<button onClick={handleOpenModal}>Download PDF</button>
          */}
      {isModalOpen && <CaseMine onClose={handleCloseModal} />}

                {/* <WebhookTesting /> */}
          {/* Video Advertisement Section */}
          <div>
      {/* Button to open the modal */}
      {/* Modal component */}
        <div className={`${style.container}${style.benfitsContainer}`}>
    </div>
      <div className={style.mainBenifits}>
          <BenifitsTwo />
        </div>
        </div>
        
      </section>
      
      <div  className={style.mainBenifits}>
      <Values/>
      </div>
      <Subscription />
      <Testimonials/>
      <Footer/>
      
   </div>
  );
};
export default Home;
