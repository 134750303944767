import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import styles from './WalletManagement.module.css'; // Assuming you have or will create a similar CSS module

const WalletManagement = () => {
  const [wallets, setWallets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('walletId');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWallets = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/wallets`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setWallets(response.data.wallets); // Adjust according to your response structure
        setSearchResults(response.data.wallets);
      } catch (error) {
        console.error("There was an error fetching the wallets:", error);
      }
    };

    fetchWallets();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(wallets);
    } else {
      const filteredWallets = wallets.filter(wallet =>
        searchType === 'walletId'
          ? wallet.id.toString().includes(event.target.value)
          : wallet.user_id.toString().includes(event.target.value)
      );
      setSearchResults(filteredWallets);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reset search term
    setSearchResults(wallets); // Show all wallets
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchWallets = wallets.filter(wallet =>
        searchType === 'walletId'
          ? wallet.id.toString() === searchTerm
          : wallet.user_id.toString() === searchTerm
      );
      setSearchResults(exactMatchWallets);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="walletId">Search by Wallet ID</option>
            <option value="userId">Search by User ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType === 'walletId' ? 'Wallet ID' : 'User ID'}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Wallet ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((wallet) => (
              <tr key={wallet.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{wallet.id}</td>
                <td className={styles.tableCell}>{wallet.user_id}</td>
                <td className={styles.tableCell}>{wallet.balance.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WalletManagement;
