import React, { useEffect, useState } from "react";
import style from "./ConcernPersonTable.module.css";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams
import { data } from '../../../../../evn/evn';
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
// import Modal from "../Client/People/ModelPop/Modal";
// import TaskForm from "../Client/People/ModelPop/TaskForm";
// import EditCaseForm from "./EditCaseForm/EditCaseForm";
// import CaseHistory from "./CaseHistory/CaseHistory";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { GiToaster } from "react-icons/gi";



const ConcernPersonTable = () => {
  const { caseId } = useParams(); // Capture caseId from URL
  const [caseDetails, setCaseDetails] = useState([]); // State to hold case details
  const ip = data.evn;
  const openModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [casesData, setCasesData] = useState([]);
  const [editingCase, setEditingCase] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    caseCode: "",
    honorableJudge: "",
    client: "",
    opponentPartyName: "",
  });
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage, setinvoicesPerPage] = useState(5);
  const [invoicesData, setInvoicesData] = useState([]);


  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentCaseDetails = caseDetails.slice(indexOfFirstInvoice, indexOfLastInvoice);
  
  // Pagination handlers
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    fetchCaseDetails();
  }, [caseId]); // Re-fetch when caseId changes

  const fetchCaseDetails = async () => {
    try {
      const response = await axios.get(`${ip}/case-details/${caseId}`, {
       
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setCaseDetails(response.data); // Set fetched case details to state
    } catch (error) {
      toast.error("Failed to fetch case details:", error);
    }
  };
  
  const handleDeleteClick = async (detailId) => {
    
    
    try {
      await axios.delete(`${ip}/case-detail/${detailId}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      // After deletion, remove the detail from the caseDetails state
      const updatedCaseDetails = caseDetails.filter(detail => detail.id !== detailId);
      setCaseDetails(updatedCaseDetails);
      toast.alert('Detail deleted successfully!');
    } catch (error) {
      toast.error("Failed to delete case detail:", error);
      toast.alert('Failed to delete case detail.');
    }
  };


  return (
    <>
      <div className={style.container}>
      <table className={style.table}>
      <thead className={style.tableHead}>
          <tr className={style.Tr}>
            <th className={style.Sno}>S.No.</th>
            <th>People</th>
            <th>Team</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        
        <tbody className={style.tableBody}>
        {currentCaseDetails.map((detail, index) => (
            <tr key={index}>
              <td className={style.td}>{index + 1}</td>
              <td className={style.td}>{detail.client}</td>
              <td className={style.td}>{detail.team}</td>
              <td className={style.td}>{detail.type}</td>
              {/* <td>{detail.lawyerType}</td> */}
              <td>
                <button className={style.btn}  onClick={() => handleDeleteClick(detail.id)}><DeleteIcon /></button>
              </td>
              <ToastContainer/>
            </tr>
          ))}
        </tbody>
        <div className={style.paginationContainer}>
          
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
    
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
    
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(caseDetails.length / invoicesPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
      </table>
    </div>
  </>
  );
};

export default ConcernPersonTable;
