import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import style from './header.module.css';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const Header = () => {
  const backgroundImageUrl = `http://localhost:8052/lawfaxMain.png`;
  
  return (
    <header className={style.mainHeader}>
      <div className={`${style.container} ${style.mainHeaderContainers}`}>
        <div className={style.mainHeaderLeft}>
        <h1 className={style.heading}>LAW<span className={style.Span}>FAX</span> AI</h1>
        <h1 className={style.heading}><span className={style.Span2}>CRAFTING THE FUTURE OF LAW WITH AI – THE BEST LITIGATION SOFTWARE IN INDIA</span></h1>

          
        <div className={style.buttonContainer}>
          <Tooltip title="Demo Button" arrow>
          <Button>
            <NavLink to={'/DemoRequestForm'} className={style.btn}>
             <h2>Request for demo</h2> 
            </NavLink>
          </Button>
         </Tooltip>
         </div>
             
        </div>
      </div>
    </header>
  );
};

export default Header;
