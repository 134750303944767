import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { data } from '../../evn/evn'; // Adjust the path as necessary
import Sidebar from '../AdminComponents/SideBar.jsx';
import { useNavigate } from 'react-router-dom';
import styles from './style/NewCustomerLead.module.css'; // Assuming this can be reused
import { FaDownload, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';

const DemoRequestsAdmin = () => {
  const [demoRequests, setDemoRequests] = useState({});
  const navigate = useNavigate();
  const [downloadingDates, setDownloadingDates] = useState({});
  const [dateSearchTerm, setDateSearchTerm] = useState('');
  const ip = data.evn; // Ensure this is your correct environment variable
  const [reviewStatuses, setReviewStatuses] = useState({});

  useEffect(() => {
    const fetchDemoRequests = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return;
      }
      try {
        const response = await axios.get(`${ip}/admin/demo-requests`, {
          headers: { 'x-auth-token': token },
        });

        const organizedRequests = {};
        const initialReviewStatuses = {};

        response.data.demoRequests.forEach(request => {
          const date = request.submitted_date.split('T')[0];
          organizedRequests[date] = organizedRequests[date] || [];
          organizedRequests[date].push(request);

          // Initialize all dates as not reviewed (false)
          initialReviewStatuses[date] = initialReviewStatuses[date] !== false && request.admin_reviewed;
        });

        setDemoRequests(organizedRequests);
        setReviewStatuses(initialReviewStatuses);
      } catch (error) {
        console.error('Error fetching demo requests:', error);
      }
    };

    fetchDemoRequests();
  }, [navigate, ip]);

  const handleDateSearchChange = (e) => {
    setDateSearchTerm(e.target.value);
  };

  const handleDownloadPdf = async (date) => {
    setDownloadingDates(prev => ({ ...prev, [date]: true }));
    try {
      const response = await axios.get(`${ip}/dashboard/demo-requests/download-pdf/${date}`, {
        responseType: 'blob',
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `demo-requests-${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    } finally {
      setDownloadingDates(prev => ({ ...prev, [date]: false }));
    }
  };

  const handleReviewStatusChange = async (date) => {
    const currentStatus = reviewStatuses[date] ? 0 : 1;
  
    try {
      await axios.post(`${ip}/admin/review-demo-requests/${date}`, { newStatus: currentStatus }, {
        headers: { 'x-auth-token': localStorage.getItem('adminToken') },
      });
      setReviewStatuses(prev => ({ ...prev, [date]: !!currentStatus }));
    } catch (error) {
      console.error('Error updating review status:', error);
    }
  };

  const filteredDemoRequests = dateSearchTerm ? Object.keys(demoRequests).reduce((acc, date) => {
    if (date.includes(dateSearchTerm)) {
      acc[date] = demoRequests[date];
    }
    return acc;
  }, {}) : demoRequests;

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <h2 className={styles.heading}>Demo Requests Review</h2>
        <input
          type="date"
          value={dateSearchTerm}
          onChange={handleDateSearchChange}
          className={styles.searchInput}
        />
        {Object.entries(filteredDemoRequests).map(([date, requestsForDate]) => (
          <React.Fragment key={date}>
            <div className={styles.dateHeaderContainer}>
              <h3 className={styles.dateHeader}>{date}</h3>
              {downloadingDates[date] ? (
                <FaSpinner className={`${styles.downloadIcon} ${styles.spinner}`} />
              ) : (
                <FaDownload
                  className={styles.downloadIcon}
                  onClick={() => handleDownloadPdf(date)}
                />
              )}
              {reviewStatuses[date] ? (
                <FaCheckCircle
                  className={`${styles.statusIcon}`}
                  style={{ color: 'green' }}
                  onClick={() => handleReviewStatusChange(date)}
                />
              ) : (
                <FaTimesCircle
                  className={`${styles.statusIcon}`}
                  style={{ color: 'red' }}
                  onClick={() => handleReviewStatusChange(date)}
                />
              )}
            </div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHeader}>ID</th>
                  <th className={styles.tableHeader}>Name</th>
                  <th className={styles.tableHeader}>Contact</th>
                  <th className={styles.tableHeader}>Email</th>
                  <th className={styles.tableHeader}>Company</th>
                  <th className={styles.tableHeader}>Role</th>
                </tr>
              </thead>
              <tbody>
                {requestsForDate.map((request, index) => (
                  <tr key={index}>
                    <td className={styles.tableCell}>{request.id}</td>
                    <td className={styles.tableCell}>{request.name}</td>
                    <td className={styles.tableCell}>{request.contact}</td>
                    <td className={styles.tableCell}>{request.email}</td>
                    <td className={styles.tableCell}>{request.company}</td>
                    <td className={styles.tableCell}>{request.role}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default DemoRequestsAdmin;
