import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, Divider, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input } from '@chakra-ui/react';
import ChatLoading from '../chat/ChatLoading';
import styles from './Style/LeftSideNav.module.css';
import { FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {data} from '../../evn/evn'


const LeftSideNav = ({ selectedUser, onChatSelect, setSelectedDocument  }) => {
  // Define static chat data
  const ip = data.evn
  const handleDocumentSelect = (doc) => {
    setSelectedDocument(doc);
  };
  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
  };
  
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State to track the search term
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const openUploadModal = () => setUploadModalOpen(true);
  const closeUploadModal = () => setUploadModalOpen(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [displayedDocumentName, setDisplayedDocumentName] = useState('');
  const [uploadStatus, setUploadStatus] = useState('idle'); // idle, uploading, uploaded
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const handleCreateGroup = () => {
    console.log('Creating group with name:', groupName);
    onClose(); // Close the modal after group creation
  };

  // const handleChatClick = (chat) => {
  //   onChatSelect(chat); // Pass the selected chat up to the parent component
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Assuming you want to keep using selectedFile for the file object, you can just add another state for the file name.
      setUploadedFileName(file.name); // You'll need to add this state variable
    }
  };

  const filteredUsers = selectedUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm)
  );

  const handleSelectChat = (userId) => {
    // Find the user by userId
    const user = selectedUsers.find((u) => u.id === userId);
    if (user) {
      onChatSelect(user);
      setSelectedChatId(userId); 
    }
};
const handleFileUpload = async () => {
  if (!selectedFile) {
    alert("Please select a file first.");
    return;
  }

  console.log('Requesting pre-signed URL for:', selectedFile.name);

 // Adjust IP/endpoint as necessary
  const presignedUrlEndpoint = `${ip}/generate-presigned-url?fileName=${encodeURIComponent(selectedFile.name)}`;

  try {
    // Fetch the pre-signed URL
    const response = await fetch(presignedUrlEndpoint, {
      method: "GET",
      headers: {
        'x-auth-token': localStorage.getItem('token'), // Include JWT token if authentication is needed
      },
    });

    if (!response.ok) throw new Error('Failed to fetch pre-signed URL');

    const { url } = await response.json();

    // Upload the file to S3
    const uploadResponse = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/pdf", // Ensure this matches the Content-Type in the pre-signed URL
      },
      body: selectedFile,
    });

    if (!uploadResponse.ok) throw new Error('Upload to S3 failed');

    console.log('File uploaded successfully');
    alert('File uploaded successfully.');
    setUploadStatus('uploaded');
  } catch (error) {
    console.error('Error during file upload:', error);
    alert('Upload failed.');
    setUploadStatus('failed');
  } finally {
    closeUploadModal();
  }
};





  const chats = [
    {
      _id: 'chat1',
      chatName: 'Tech Support',
      isGroupChat: true,
      users: [{ _id: 'user1', name: 'Alice' }, { _id: 'user2', name: 'Bob' }],
      latestMessage: {
        sender: { _id: 'user1', name: 'Alice' },
        content: 'Hey everyone, are we on for the meeting?',
      },
    },

    {
      _id: 'chat2',
      chatName: 'Family',
      isGroupChat: true,
      users: [{ _id: 'user3', name: 'Charlie' }, { _id: 'user4', name: 'Dana' }],
      latestMessage: {
        sender: { _id: 'user3', name: 'Charlie' },
        content: 'Don’t forget dinner at 8!'
      },
    },
  ];
  const [selectedChatId, setSelectedChatId] = useState(null); // State to track the selected chat

  // Function to simulate selecting a chat
  const setSelectedChat = (chat) => {
    console.log('Selected chat:', chat);
  };

  const findChat = (chatId) => chats.find(chat => chat._id === chatId);

  useEffect(() => {
    if (selectedUser) {
      // Add the selected user to the list if not already included
      setSelectedUsers(prevSelectedUsers => {
        const userExists = prevSelectedUsers.some(user => user.id === selectedUser.id);
        return userExists ? prevSelectedUsers : [...prevSelectedUsers, selectedUser];
      });
    }
  }, [selectedUser]);

  return (
    <Box className={styles.myChatsContainer}>
      <Box className={styles.headerBox}>
        Drafts
        {/* <Button className={styles.GroupBtn} onClick={onOpen}>Create Group Chat</Button> */}
      </Box>
      <Input
        placeholder="Search Chats"
        value={searchTerm}
        onChange={handleSearchChange}
        focusBorderColor="teal.500"
        className={styles.searchBar} // Add your own styling if needed
      />

      <Button  className={styles.addBtn} onClick={openUploadModal}>
        <AddIcon /> New
      </Button>

  <Modal isOpen={isUploadModalOpen} onClose={closeUploadModal} isCentered className={styles.CustomModal}>
   <ModalOverlay className={styles.ModalOverlay} />
   <ModalContent className={styles.ModalContent}>
     <ModalHeader className={styles.ModalHeader}>Upload a Document</ModalHeader>
     <ModalCloseButton className={styles.ModalCloseButton} />
     <ModalBody className={styles.AddIconModalBody}>
       <Input
         type="file"
         onChange={handleFileChange}
         size="md"
         className={styles.FileInput}
      />
      <Input
        placeholder="Document Name"
        value={documentName}
        onChange={(e) => setDocumentName(e.target.value)}
        size="md"
        className={styles.DocumentNameInput} // Add CSS class if needed
      />
      
    </ModalBody>
    <ModalFooter className={styles.ModalFooter}>
      <Button onClick={handleFileUpload} className={styles.UploadButton}>
        Upload
      </Button>
      <Button variant="ghost" onClick={closeUploadModal} className={styles.CancelButton}>Cancel</Button>
    </ModalFooter>
  </ModalContent>
</Modal>


      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
  <ModalOverlay
  className={styles.ModalContainer} />
  <ModalContent
    sx={{
      margin: '0 auto', 
      width: 'fit-content', 
      borderRadius: 'lg',
      overflow: 'hidden', 
      alignContent: "center"
    }}
  >
    <ModalHeader className={styles.GroupHeading} >Create a new group chat</ModalHeader>
    <ModalCloseButton />
    <ModalBody pb={6}>
      <Input
        placeholder="Group Name"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        focusBorderColor="teal.500"
      />
    </ModalBody>
    <ModalFooter>
      <Button className={styles.CreateBtn} colorScheme="teal" mr={3} onClick={handleCreateGroup}>
        Create
      </Button>
      <Button className={styles.CancelBtn} variant="ghost" onClick={onClose}>Cancel</Button>
    </ModalFooter>
  </ModalContent>
</Modal>

{/* <Box className={styles.chatsBox}> */}
    {chats.length > 0 ? (
      <Stack>
  {uploadedDocuments.length > 0 ? (
    <Stack>
      {uploadedDocuments.map((doc, index) => (
        <Box
          key={index} // Since there's no doc.id, using index as key
          onClick={() => handleDocumentClick(doc)} // Adjusted to pass the doc to the handler
          cursor="pointer"
          bg={selectedChatId === doc.fileName ? '#38B2AC' : '#A5D6A7'} // This condition might need adjustment
          color="white"
          px={3}
          py={2}
          borderRadius="lg"
          className={styles.chatItem}
        >
          <Text className={styles.uploadedDocumentName}>
            {doc.documentName} ({doc.fileName})
          </Text>
          {/* Since these are not user objects with lastMessage, this part is commented out.
          {user.lastMessage && (
            <Text className={styles.lastMessage}>{user.lastMessage}</Text>
          )} */}
        </Box>
      ))}
    </Stack>
  ) : (
    <ChatLoading /> // Or any other placeholder when there are no documents
  )}
</Stack>

    ) : (
      <ChatLoading />
    )}
  {/* </Box> */}

      {/* Display selected chat information */}

    </Box>
  );
};

export default LeftSideNav;
