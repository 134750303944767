import React from 'react'
import style from './DemoRequestForm.module.css'

const TermAndCondition = () => {
  return (
    <div>
            <div className={style.TermsContainer}>
  <h1 className={style.MainHeading}>Terms of Use for LawFax</h1>
  <h2 className={style.SecondHeading}>Introduction</h2>
  <p className={style.Para}>Welcome to LawFax, a digital platform designed for lawyers. These Terms of Use govern your access to and use of our website and services. By accessing or using LawFax, you agree to be bound by these terms.</p>
  
  <h2 className={style.SecondHeading}>Registration and Account Security</h2>
  <p className={style.Para}>Lawyers must register and create an account to use LawFax services. Users are responsible for maintaining the confidentiality of their account information and password.</p>
  <p className={style.Para}>You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
  
  <h2 className={style.SecondHeading}>Use of Service</h2>
  <p className={style.Para}>LawFax provides a range of services designed for legal professionals, including access to legal documents, networking opportunities, and other resources.</p>
  <p className={style.Para}>Users are granted a non-exclusive, non-transferable, revocable license to access and use the LawFax website and services strictly in accordance with these Terms of Use.</p>
  
  <h2 className={style.SecondHeading}>User Conduct</h2>
  <p className={style.Para}>Users agree not to use the website or its services for any unlawful purpose or in any way that interrupts, damages, or impairs the service.</p>
  <p className={style.Para}>Sharing sensitive and personal data of clients without consent is strictly prohibited.</p>
  
  <h2 className={style.SecondHeading}>Intellectual Property Rights</h2>
  <p className={style.Para}>All content provided on LawFax, including text, graphics, logos, images, and software, is the property of LawFax or its content suppliers and protected by copyright and intellectual property laws.</p>
  
  <h2 className={style.SecondHeading}>Limitation of Liability</h2>
  <p className={style.Para}>LawFax will not be liable for any damages of any kind arising from the use of this site or from any information, content, materials, or services included on or otherwise made available to you through this site.</p>
  
  <h2 className={style.SecondHeading}>Changes to Terms</h2>
  <p className={style.Para}>LawFax reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes.</p>
</div>

<div className={style.PrivacyPolicyContainer}>
  <h1 className={style.MainHeading}>Privacy Policy for LawFax</h1>
  <h2 className={style.SecondHeading}>Introduction</h2>
  <p className={style.Para}>This Privacy Policy outlines how LawFax collects, uses, maintains, and discloses information collected from users (each, a "User") of the LawFax website.</p>
  
  <h2 className={style.SecondHeading}>Personal Identification Information</h2>
  <p className={style.Para}>We may collect personal identification information from Users in various ways, including, but not limited to, when Users visit our site, register, fill out a form, and in connection with other activities, services, features, or resources we make available on our site.</p>
  
  <h2 className={style.SecondHeading}>How We Use Collected Information</h2>
  <p className={style.Para}>LawFax may collect and use Users' personal information for the following purposes: to improve customer service, personalize user experience, improve our site, process payments, send periodic emails regarding updates or other information.</p>
  
  <h2 className={style.SecondHeading}>Compliance with Children's Online Privacy Protection Act</h2>
  <p className={style.Para}>Protecting the privacy of the very young is especially important. For that reason, LawFax never collects or maintains information at our site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>
  
  <h2 className={style.SecondHeading}>Changes to This Privacy Policy</h2>
  <p className={style.Para}>LawFax has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.</p>
  
  <h2 className={style.SecondHeading}>Contacting Us</h2>
  <p className={style.Para}>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us.</p>
</div>


    </div>
  )
}

export default TermAndCondition