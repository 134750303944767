import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from '../../evn/evn'; // Ensure the correct path is used
import Sidebar from '../AdminComponents/SideBar';
import styles from './CaseFileManagement.module.css'; // Update to match your CSS file structure

const CaseFileManagement = () => {
  const [caseFiles, setCaseFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();
  const ip = data.evn;

  useEffect(() => {
    const fetchCaseFiles = async () => {
      const ip = data.evn; // Ensure 'env' is correct
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }
      

      try {
        const response = await axios.get(`${ip}/admin/case-files`, {
          headers: { 'x-auth-token': token },
        });
        setCaseFiles(response.data.caseFiles);
        setSearchResults(response.data.caseFiles);
      } catch (error) {
        console.error("There was an error fetching the case files:", error);
      }
    };

    fetchCaseFiles();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(caseFiles);
    } else {
      const filteredCaseFiles = caseFiles.filter(file => {
        switch (searchType) {
          case 'id':
            return file.id.toString().includes(event.target.value);
          case 'userId':
            return file.user_id.toString().includes(event.target.value);
          case 'caseId':
            return file.case_id.toString().includes(event.target.value);
          default:
            return true;
        }
      });
      setSearchResults(filteredCaseFiles);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm('');
    setSearchResults(caseFiles);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchFiles = caseFiles.filter(file => {
        switch (searchType) {
          case 'id':
            return file.id.toString() === searchTerm;
          case 'userId':
            return file.user_id.toString() === searchTerm;
          case 'caseId':
            return file.case_id.toString() === searchTerm;
          default:
            return false;
        }
      });
      setSearchResults(exactMatchFiles);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
            <option value="caseId">Search by Case ID</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Case ID</th>
              <th className={styles.tableHeader}>File Path</th>
             
            </tr>
          </thead>
          <tbody>
            {searchResults.map((file) => (
              <tr key={file.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{file.id}</td>
                <td className={styles.tableCell}>{file.user_id}</td>
                <td className={styles.tableCell}>{file.case_id}</td>
                <td className={styles.tableCell}>
  {file.file_path ? (
    <a href={`${ip}/uploads/${file.file_path}`} target="_blank" rel="noopener noreferrer" className={styles.link}>
      {file.file_path}
    </a>
  ) : 'No file available'}
</td>

                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CaseFileManagement;
