import React, { useState, useEffect } from "react";
import Footer from "../../component/utilities/footer/footer";
import { NavLink, useNavigate } from "react-router-dom";
import SideNav from "../../component/utilities/SideNavBar/SideNav";
import axios from 'axios';
import style from "./dashboard.module.css";
import Card from "../../component/utilities/card/Card";
import { datas } from "./data";
import { AiOutlineSearch } from "react-icons/ai";
import DashboardNavbar from "../../component/utilities/DashboardNavbar/DashboardNavbar";
import { fetchAndUpdateCounts } from "./data";
import { Button, Tooltip } from '@mui/material';
import { useHook } from "./UseHook";
import { data } from "../../evn/evn";
import { MdMessage } from 'react-icons/md';
import Joyride, { STATUS } from 'react-joyride';

const ip = data.evn;


const DashBoard = () => {
  const [dashboardData, setDashboardData] = useState(datas);
  const [globalUnreadCount, setGlobalUnreadCount] = useState(0);
  const navigate = useNavigate();
  const [runs, setRun] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false); // Control whether the tour is active
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
    // Call handleResize initially to set the correct state when the component mounts
    handleResize();
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const [{run, steps}, newState] = useState({
    run:true,
    steps : [
      {
        content: <h2>Welcome! Let's take a tour of the website.</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: 'center',
        target: 'body',
        title: 'Welcome to Your Dashboard'
      },

      {
        content: <h2> Never Miss a Hearing: You have Backup/Proxy Counsel. </h2>,
        placement: 'right-start', // This places the tooltip to the right of the target, at the start (top)
        target: '#navBar',
        title: 'Proxy Council',
        tooltip: 'Use this to Generate Proxy, and reach where you cannot reach before.',
        offset: 10, // Adjusts the position 10 pixels away from the target, modify as needed
      },
      
      // {
      //   content: <h2> Stay updated with important alerts.</h2>,
      //   placement: 'bottom',
      //   target: '#bellid',
      //   title: 'Notifications',
      //   tooltip: 'Receive notifications here.'
      // },

      // {
      //   content: <h2>Safely hold your funds until your task is done, then seamlessly transfer to your account.</h2>,
      //   placement: 'bottom',
      //   target: '#walletId',
      //   title: 'Wallet',
      //   tooltip: 'See you payment overhere wallet.'
      // },

      // {
      //   content: <h2> Instant help whenever you need it.</h2>,
      //   placement: 'bottom',
      //   target: '#chatid',
      //   title: 'Chat',
      //   tooltip: 'Chat box for conversation to begain.'
      // },

      // {
      //   content: <h2>Efficiently search for judgments here.</h2>,
      //   placement: 'left',
      //   target: '#QuicklySearch',
      //   title: 'Quick Search',
      //   tooltip: 'Quickly find cases by name or number using this search feature.'
      // },
      
      // {
      //   content: <h2>Manage your cases effectively.</h2>,
      //   placement: 'top',
      //   target: '#dashboard-item-1',
      //   title: 'Case Management',
      //   tooltip: 'Add, manage, or import cases directly from this card.'
      // },
      // {
      //   content: <h2>Build and manage your team.</h2>,
      //   placement: 'top',
      //   target: '#dashboard-item-2',
      //   title: 'Team Management',
      //   tooltip: 'Here you can add new members to your team.'
      // },
      // {
      //   content: <h2>Document automation made easy.</h2>,
      //   placement: 'top',
      //   target: '#dashboard-item-3',
      //   title: 'Document Generation',
      //   tooltip: 'Generate documents from the chatbot—feature coming soon!'
      // },
      // {
      //   content: <h2>Expand your team efficiently.</h2>,
      //   placement: 'bottom',
      //   target: '#dashboard-item-4',
      //   title: 'New Team Members',
      //   tooltip: 'Add new team members and view their details. Download profiles in PDF format.'
      // },
      // {
      //   content: <h2>Keep track of your tasks.</h2>,
      //   placement: 'bottom',
      //   target: '#dashboard-item-5',
      //   title: 'Task Management',
      //   tooltip: 'Add To-Do tasks here. Tasks are automatically saved in your calendar for reminders.'
      // },
      // {
      //   content: <h2>Manage billing with ease.</h2>,
      //   placement: 'bottom',
      //   target: '#dashboard-item-6',
      //   title: 'Billing and Invoices',
      //   tooltip: 'View and manage your bills and invoices. Download copies directly from this panel.'
      // },
      // {
      //   content: <h2>Thank you for touring the dashboard!</h2>,
      //   placement: 'center',
      //   target: 'body',
      //   title: 'End of Tour',
      //   tooltip: 'You’re all set to manage your cases effectively using our tools.'
      // },
    ]
  });

  useEffect(() => {
    // Function to fetch global unread message count
    const fetchGlobalUnreadCount = async () => {
      try {
        const response = await axios.get(`${ip}/api/messages/unread/global-count`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setGlobalUnreadCount(response.data.globalUnreadCount);
      } catch (error) {
        console.error('Failed to fetch global unread count', error);
      }
    };

    fetchGlobalUnreadCount();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      await fetchAndUpdateCounts(); // Assuming this function updates 'data' array
      setDashboardData([...datas]); // Update local state to trigger re-render
    };

    fetchData();
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription');
      } else {
        navigate('/explore'); // Navigate to explore if subscribed
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };


const customStyles = {
  options: {
    zIndex: 10000, // Ensures Joyride appears above other UI elements
    arrowColor: '#855E42', // A soft bronze for arrows, elegant and understated
    backgroundColor: '#F4EFE9', // A light creamy color for tooltips, warm and inviting
    textColor: '#504945', // A dark taupe, sophisticated and easy on the eyes
    overlayColor: 'rgba(133, 94, 66, 0.4)', // Translucent bronze matching the arrow color
    primaryColor: '#855E42', // Bronze used for primary buttons and indicators, consistent with arrows
    spotlightShadow: '0 0 20px rgba(50, 50, 50, 0.5)' // A deeper shadow for a more dramatic effect
  },
  buttonNext: {
    backgroundColor: '#C0A080', // A muted gold, suggesting advancement and success
    color: '#FFF', // White text for contrast and visibility
    border: 'none' // Removing default borders for a cleaner look
  },
  buttonBack: {
    backgroundColor: 'transparent', // Keep transparent to de-emphasize
    color: '#A69B91', // A softer gray-brown, less prominent and elegant
    border: 'none' // Removing default borders for a cleaner look
  },
  buttonSkip: {
    backgroundColor: 'transparent', // Transparent background
    color: '#BA9383', // A dusty rose, indicating caution with sophistication
    fontWeight: 'bold', // Bold text for emphasis
    border: 'none' // No border for a cleaner look
  },
};

  // useEffect(() => {
  //   const fetchTourStatus = async () => {
  //     try {
  //       const response = await axios.get(`${ip}/check-tour-status`, {
  //         headers: { 'x-auth-token': localStorage.getItem('token') },
  //       });
        
  //       setRun(!response.data.has_completed_tour);
  //     } catch (error) {
  //       console.error('Failed to check tour status:', error);
  //       setRun(false); 
  //     }
  //   };

  //   fetchTourStatus();
  // }, []);
  useEffect(() => {
    async function fetchTourStatus() {
      try {
        const response = await axios.get(`${ip}/check-tour-status`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        // Set 'run' to true only if 'hasCompletedTour' is 0
        setRun(response.data.hasCompletedTour === 0);
      } catch (error) {
        console.error('Failed to check tour status:', error);
        setRun(true);  // Default to not running the tour if there's an error
      }
    }
  
    fetchTourStatus();
  }, []);
  useEffect(() => {
    console.log("Tour run state after fetching status:", run);
  }, [run]);
  
  
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      axios.post(`${ip}/complete-tour`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      })
      .then(response => {
        console.log('Tour completion status updated:', response.data);
        setRun(false);  // Stop the tour
      })
      .catch(error => {
        console.error('Failed to update tour status:', error);
      });
    }
  };
  
  
  return (
    <>
      {isMobile ? <SideNav /> : <DashboardNavbar id={'navBar'} wallet={"walletId"} Chat={"chatid"} bells={"bellid"} proxy={"proxyid"} />}
     
      <div className={style.messageIconContainer}>
      <Joyride
  run={runs}
  callback={handleJoyrideCallback}
  steps={steps}
  continuous
  scrollToFirstStep
  showProgress
  showSkipButton
  styles={customStyles}
/>


       
      </div>
      <h2 className={style.head}>
        <NavLink className={style.heading} onClick={checkSubscriptionStatus}> 
          <button className={style.btn} id="QuicklySearch">
            <AiOutlineSearch className={style.SearchLogo} />
            Quickly search cases by name or number
          </button>
          
        </NavLink>
      </h2>
      <div className={style.benfitsWrapper}>
        {/* function for render data from data.jsx */}
        {dashboardData.map(
          ({
            id,
            icon,
            title,
            info,
            caseNo,
            plusIcon,
            pathAdd,
            pathView,
            plusIcon2,
            addBill,
            addInvoice,
            details,
          }) => {
            return (
              <Card
                id={`dashboard-item-${id}`} 
                className={style.programsProgram}
                key={id}
                details={details}
              >
                <span>{icon}</span>
                <h4>{caseNo}</h4>
                <NavLink to={pathAdd}>
                  <h4 className={style.btnAdd1}>
                    {addBill}
                    {plusIcon}{" "}
                  </h4>
                </NavLink>
                <NavLink to={pathAdd}>
                  <h4 className={style.btnAdd1}>
                    {addInvoice}
                    {plusIcon2}{" "}
                  </h4>
                </NavLink>

             
                <div className={style.addButtonsContainer}></div>
                <h4>{title}</h4>
                <NavLink to={pathView}>
                  <button className={style.btn}>{info}</button>
                </NavLink>
              </Card>
            );
          }
        )}
       
      </div>
      

      <Footer />
    </>
  );
};

export default DashBoard;
