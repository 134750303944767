import React, { useEffect, useState } from "react";
import style from "./ConcernPersonTable.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { data } from '../../../../../evn/evn';
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';



const OpponentTable = () => {
  const { caseId } = useParams(); // Use caseId from URL
  const [opponentData, setOpponentData] = useState([]); // State to hold opponent details
  const ip = data.evn; // Make sure your environment variable is correct
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5)
  
  useEffect(() => {
    fetchOpponentData();
  }, [caseId]);
  
  const fetchOpponentData = async () => {
    try {
      const response = await axios.get(`${ip}/opponent-details/${caseId}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setOpponentData(response.data); // Update state with fetched data
    } catch (error) {
      // toast.error("Failed to fetch opponent details:", error);
    }
  };

  const handleDeleteClick = async (detailId) => {
    if (window.confirm('Are you sure you want to delete this opponent detail?')) {
      try {
        await axios.delete(`${ip}/opponent-detail/${detailId}`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        // Remove the deleted detail from the state to update the UI accordingly
        const newOpponentData = opponentData.filter(detail => detail.id !== detailId);
        setOpponentData(newOpponentData);
        toast('Opponent detail deleted successfully');
      } catch (error) {
        toast.error("Failed to delete opponent detail:", error);
      }
    }
  };
  
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = opponentData.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleNextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => prev > 1 ? prev - 1 : 1);
  };
  

  return (
    <>
      <div className={style.container}>
      <table className={style.table}>
      <thead className={style.tableHead}>
          <tr>
              <th className={style.Sno}>S.no.</th>
              <th className={style.th}>Party</th>
              <th className={style.th}>Lawyer Name</th>
              <th className={style.th}>Mobile No.</th>
              <th className={style.th}>Email ID</th>
              <th className={style.th}>Actions</th>
          </tr>
        </thead>
        
        <tbody className={style.tableBody}>

        {currentRecords.map((detail, index) => (
              <tr key={index}>
                <td className={style.td}>{index + 1}</td>
                <td className={style.td}>{detail.opponentPartyName}</td>
                <td className={style.td}>{detail.lawyerName}</td>
                <td className={style.td}>{detail.mobileNo}</td>
                <td className={style.td}>{detail.emailId}</td>
                <td className={style.td}>
                <button className={style.btn}  onClick={() => handleDeleteClick(detail.id)}><DeleteIcon /></button>
              </td>
              <ToastContainer/>
            </tr>
          ))}
         
        </tbody>
         {/* Pagination Controls */}
         <div className={style.paginationContainer}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} className={style.previousButton}>
            <KeyboardDoubleArrowLeftIcon style={{color:'var(--color-fifth)'}}/>
            <span className={style.prev}>{currentPage === 1 ? "Prev 0" : `Prev ${currentPage - 1}`}</span>
          </Button>
          <span className={style.currentPageIndicator}>
            {currentPage}
          </span>
          <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(opponentData.length / recordsPerPage)} className={style.nextButton}>
            <span className={style.next}>Next {currentPage + 1}</span>
            <KeyboardDoubleArrowRightIcon style={{color:'var(--color-fifth)'}}/>
          </Button>
        </div>
          
        
      </table>

      
    </div>
  </>
  );
};

export default OpponentTable;
