import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from '../../evn/evn'; // Ensure the correct path
import Sidebar from '../AdminComponents/SideBar';
import styles from './InvoiceManagement.module.css'; // Assuming a similar CSS module for styling

const InvoiceManagement = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('id');
  const navigate = useNavigate();
  const ip = data.evn;

  useEffect(() => {
    const fetchInvoices = async () => {
      const ip = data.evn;
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/.admin');
        return false;
    }

      try {
        const response = await axios.get(`${ip}/admin/invoices`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setInvoices(response.data.invoices); // Adjust this according to your actual response structure
        setSearchResults(response.data.invoices);
      } catch (error) {
        console.error("There was an error fetching the invoices:", error);
      }
    };

    fetchInvoices();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(invoices);
    } else {
      const filteredInvoices = invoices.filter(invoice => {
        if (searchType === 'id') return invoice.id.toString().includes(event.target.value);
        else if (searchType === 'userId') return invoice.user_id.toString().includes(event.target.value);
        else return invoice.invoiceNumber.includes(event.target.value);
      });
      setSearchResults(filteredInvoices);
    }
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reset search term
    setSearchResults(invoices); // Show all invoices
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      const exactMatchInvoices = invoices.filter(invoice => {
        if (searchType === 'id') return invoice.id.toString() === searchTerm;
        else if (searchType === 'userId') return invoice.user_id.toString() === searchTerm;
        else return invoice.invoiceNumber === searchTerm;
      });
      setSearchResults(exactMatchInvoices);
    }
  };

  return (
    <>
      <Sidebar />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <select
            className={styles.searchTypeSelect}
            value={searchType}
            onChange={handleSearchTypeChange}
          >
            <option value="id">Search by ID</option>
            <option value="userId">Search by User ID</option>
            <option value="invoiceNumber">Search by Invoice Number</option>
          </select>
          <input
            type="text"
            placeholder={`Search by ${searchType}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && searchTerm) {
                const exactMatchInvoices = invoices.filter(invoice => {
                  if (searchType === 'id') return invoice.id.toString() === searchTerm;
                  else if (searchType === 'userId') return invoice.user_id.toString() === searchTerm;
                  else return invoice.invoiceNumber === searchTerm;
                });
                setSearchResults(exactMatchInvoices);
              }
            }}
            className={styles.searchInput}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeader}>Invoice ID</th>
              <th className={styles.tableHeader}>User ID</th>
              <th className={styles.tableHeader}>Invoice Number</th>
              <th className={styles.tableHeader}>Title</th>
              <th className={styles.tableHeader}>People</th>
              <th className={styles.tableHeader}>Case Title</th>
              <th className={styles.tableHeader}>Date</th>
              <th className={styles.tableHeader}>Amount</th>
              <th className={styles.tableHeader}>Tax Type</th>
              <th className={styles.tableHeader}>Tax Percentage</th>
              <th className={styles.tableHeader}>Cumulative Amount</th>
              <th className={styles.tableHeader}>Full Address</th>
              <th className={styles.tableHeader}>Date From</th>
              <th className={styles.tableHeader}>Date To</th>
              <th className={styles.tableHeader}>Expenses Amount</th>
              <th className={styles.tableHeader}>Expenses Tax Type</th>
              <th className={styles.tableHeader}>Expenses Tax Percentage</th>
              <th className={styles.tableHeader}>Expenses Cumulative Amount</th>
              <th className={styles.tableHeader}>Total Amount</th>
              <th className={styles.tableHeader}>Invoice File</th>
              
            </tr>
          </thead>
          <tbody>
            {searchResults.map((invoice) => (
              <tr key={invoice.id} className={styles.tableRow}>
                <td className={styles.tableCell}>{invoice.id}</td>
                <td className={styles.tableCell}>{invoice.user_id}</td>
                <td className={styles.tableCell}>INV-{invoice.invoiceNumber}</td>
                <td className={styles.tableCell}>{invoice.title}</td>
                <td className={styles.tableCell}>{invoice.client}</td>
                <td className={styles.tableCell}>{invoice.caseType}</td>
                <td className={styles.tableCell}>{invoice.date}</td>
                <td className={styles.tableCell}>{invoice.amount}</td>
                <td className={styles.tableCell}>{invoice.taxType}</td>
                <td className={styles.tableCell}>{invoice.taxPercentage}</td>
                <td className={styles.tableCell}>{invoice.CumulativeAmount}</td>
                <td className={styles.tableCell}>{invoice.fullAddress}</td>
                <td className={styles.tableCell}>{invoice.dateFrom}</td>
                <td className={styles.tableCell}>{invoice.dateTo}</td>
                <td className={styles.tableCell}>{invoice.expensesAmount}</td>
                <td className={styles.tableCell}>{invoice.expensesTaxType}</td>
                <td className={styles.tableCell}>{invoice.expensesTaxPercentage}</td>
                <td className={styles.tableCell}>{invoice.expensesCumulativeAmount}</td>
                <td className={styles.tableCell}>{invoice.totalAmount}</td>
                <td className={styles.tableCell}>
  {invoice.addDoc ? (
    <a className={styles.link} href={`${ip}/uploads/${invoice.addDoc}`} target="_blank" rel="noopener noreferrer">
      {invoice.addDoc}
    </a>
  ) : 'No file'}
</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
  
};
export default InvoiceManagement;