import { FaCrown } from "react-icons/fa";

export const value = [
  {
    id: 1,
    icon: <FaCrown />,
    title: "Values 1",
    info1: "Driving Innovation and Technological Advancement: We harness cutting-edge AI technology to redefine the efficiency and accessibility of legal services",
     },
  {
    id: 2,
    icon: <FaCrown />,
    title: "Values 2",
    info1: "Merging Expertise with Precision: Our services blend human legal expertise with the precision of AI, ensuring unparalleled accuracy and insight.",
     },
  {
    id: 3,
    icon: <FaCrown />,
    title: "Values 3",
    info1: "Maximizing Efficiency: Through AI, we dramatically streamline the creation and management of legal documents, saving our clients valuable time.",
     },
  {
    id: 4,
    icon: <FaCrown />,
    title: "Values 4",
    info1: "Enhancing Accessibility: We leverage online platforms and AI enhancements to make our legal services accessible to everyone, everywhere.",
     },
  {
    id: 5,
    icon: <FaCrown />,
    title: "Values 5",
    info1: "Delivering Personalized Services: Our use of AI allows us to customize legal documents and consultations to the unique needs of each client.",
     },
  {
    id: 6,
    icon: <FaCrown />,
    title: "Values 6",
    info1: "Proactively Mitigating Risks: With AI tools, we identify potential legal issues before they become problems, protecting our clients proactively.",
     },
  {
    id: 7,
    icon: <FaCrown />,
    title: "Values 7",
    info1: "Ensuring Cost-Effectiveness: Our AI-driven efficiencies allow us to offer competitive and transparent pricing.",
     },
  {
    id: 8,
    icon: <FaCrown />,
    title: "Values 8",
    info1: "Safeguarding Data Security: We prioritize the confidentiality and protection of client data through secure AI processes.",
     },
  {
    id: 9,
    icon: <FaCrown />,
    title: "Values 9",
    info1: "Providing Scalable Solutions: Our services are designed to grow with our clients' needs, thanks to the flexibility of our AI systems.",
     },
  {
    id: 10,
    icon: <FaCrown />,
    title: "Values 10",
    info1: "Committing to Continuous Improvement: Our AI systems evolve with ongoing learning, ensuring that our services continually improve.",
     },
  {
    id: 11,
    icon: <FaCrown />,
    title: "Values 11",
    info1: "Offering Global Reach: Digital platforms and AI enable us to provide services across borders, making legal assistance universally available.",
     },
  {
    id: 12,
    icon: <FaCrown />,
    title: "Values 12",
    info1: "Building Transparency and Trust: We foster trust with our clients through transparent processes and AI-driven insights.",
     },
];
