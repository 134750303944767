import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Center, HStack, Input, Button, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react';
import ScrollableChat from './ScrollableChat';
import style from './Style/SingleChat.module.css';
import axios from 'axios'; // Ensure axios is imported
import {data} from '../../evn/evn'
import { AttachmentIcon} from '@chakra-ui/icons';
import { FaRegSmile } from 'react-icons/fa';
import Picker from 'emoji-picker-react'; 
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AiOutlineClose } from 'react-icons/ai';



const SingleChat = ({selectedChat, onChatOpened, onBack  }) => {
  const initialMessages = [
    { _id: 'm1', sender: { _id: 'u1', name: 'Alice' }, content: 'Hi there!', createdAt: '2020-01-01T00:00:00Z' },
    { _id: 'm2', sender: { _id: 'u2', name: 'Bob' }, content: 'Hello!', createdAt: '2020-01-01T01:00:00Z' },
    { _id: 'm3', sender: { _id: 'u1', name: 'Alice' }, content: 'How are you?', createdAt: '2020-01-01T02:00:00Z' }
  ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const [messages, setMessages] = useState(initialMessages);
  const { isOpen: isEmojiPickerOpen, onToggle: toggleEmojiPicker } = useDisclosure();
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [fileName, setFileName] = useState('');

  const scrollableChatRef = useRef(null);
  const ip = data.evn

  // const selectedChat = { _id: 'chat1', chatName: 'Tech Support', isGroupChat: true };
  const markMessagesAsRead = async () => {
    try {
      await axios.patch(`${ip}/api/messages/read`, { chatId: selectedChat.chatId }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      onChatOpened(selectedChat.chatId);
      // Optionally, you can refresh the chat list or perform other actions after marking messages as read
    } catch (error) {
      console.error('Failed to mark messages as read:', error);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from localStorage
      const response = await fetch(`${ip}/api/messages/${selectedChat.chatId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token // Include the token in the request headers
        },
      });
      const data = await response.json();
      if (data.messages) {
        // Update the messages state with the fetched data, sorted by createdAt
        setMessages(data.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
        markMessagesAsRead();
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const handleSend = async () => {
    // If both message and file are empty, do nothing
    if (message.trim() === '' && !selectedFile) {
      return;
    }
  
    const formData = new FormData();
    formData.append('chatId', selectedChat.chatId);
    formData.append('content', message);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${ip}/api/messages`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      });
  
      const data = response.data;
      if (data.message === 'Message sent successfully') {
        setMessage('');
        setFileName('');
        setSelectedFile(null); // Clear the selected file
        await fetchMessages();
        scrollToBottom();
        setTimeout(scrollToBottom, 100);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    fetchMessages();
    // Optionally, set an interval to fetch messages periodically
    const interval = setInterval(fetchMessages, 500); // Fetch messages every 5 seconds
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [selectedChat]);



  const scrollToBottom = () => {
    if (scrollableChatRef.current) {
      const scroll = scrollableChatRef.current;
      scroll.scrollTo({ top: scroll.scrollHeight, behavior: "smooth" });
    }
  };
  
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name); // Update the filename state with the selected file's name
    } else {
      // This else block might not be necessary if you're triggering file selection through a click event
      setSelectedFile(null);
      setFileName(''); // Reset the filename if no file is selected
    }
  };
  const unselectFile = () => {
    setSelectedFile(null);
    setFileName('');
    resetFileInput(); // If you wish to also reset the input, allowing the same file to be reselected
  };
  
// Resetting the input and the filename state to allow selecting the same file again if needed
const resetFileInput = () => {
    setFileInputKey(Date.now());
    setFileName(''); // Also reset the filename state
};
  
  
  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };
  
  // Resetting the input to allow selecting the same file again if needed
  
  const onEmojiClick = (emojiData, event) => {
    console.log('Emoji selected:', emojiData); // This should now log the correct emoji object
    setMessage(prevMessage => prevMessage + emojiData.emoji);
  };
  
  return (
    <Box className={style.chatContainer}>
      {selectedChat ? (
    <div className={style.container}>
         <Text className={style.chatHeader}>
    <ArrowBackIosIcon className={style.BackIcon} onClick={onBack}  /> 

        {selectedChat ? (selectedChat.chatName || selectedChat.otherUser?.name) : "Loading..."}
      </Text>
      {messages.length > 0 ? (
        <ScrollableChat messages={messages} ref={scrollableChatRef} />
      ) : (
        <Center className={style.placeholder1}>
          <Text className={style.placeholderText1}>
            No messages yet, start the conversation!
          </Text>
        </Center>
      )}
    </div>
  ) : (
    <Center className={style.placeholder}>
      <Text className={style.placeholderText}>
        Search/Select user to start chatting
      </Text>
    </Center>
  )}
 {selectedChat && (
        <HStack className={style.MessageInputContainer} >
          <InputGroup>
            <InputLeftElement
              height="100%" // Ensure the container spans the full height of the input
            >
              <Button size="sm" variant="ghost" onClick={triggerFileInput} p={0} height="100%" alignItems="center" justifyContent="center">
                <AttachmentIcon color={selectedFile ? "green" : "black.1200"} transform="rotate(-45deg)" fontWeight="Bolder"/>
              </Button>
              <Button onClick={() => toggleEmojiPicker(!isEmojiPickerOpen)} marginLeft='1rem'>
              <FaRegSmile />
            </Button>
            {isEmojiPickerOpen && (
              <Box className={style.Emoji} position="relative" bottom="16rem" right="3.2rem">
                <Picker onEmojiClick={onEmojiClick} />
              </Box>
            )}
              
            </InputLeftElement>
            <Input
              pl="2.8rem" 
              className={style.MessageInput}
              value={message}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your message here..."
              variant="filled"
            />
            {fileName && (
              <HStack>
                <Text ml={2}>{fileName}</Text>
                <AiOutlineClose color="red" cursor="pointer" onClick={unselectFile} />
              </HStack>
            )}
            <input
              id="fileInput"
              type="file"
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              key={fileInputKey}
            />
          </InputGroup>
          <Button className={style.SendButton} onClick={handleSend}>Send</Button>
        </HStack>
      )}
    </Box>
  );
};

export default SingleChat;