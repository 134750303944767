import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from './Proxy.module.css';
import axios from 'axios';
import DashboardNavbar from '../../utilities/DashboardNavbar/DashboardNavbar' 
import { NavLink , useNavigate } from 'react-router-dom';
import SideNav from '../../utilities/SideNavBar/SideNav';
// import { Button, Tooltip } from '@mui/material';
import {data} from '../../../evn/evn';

const Proxy = () => {
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [cases, setCases] = useState([]);
  const ip = data.evn
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [showOtherLawyerType, setShowOtherLawyerType] = useState(false);
  useEffect(() => {
    checkSubscriptionStatus();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
    };
   
    handleResize();
    
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(`${ip}/caseform`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setCases(response.data);
        
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };
    fetchCases();
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${ip}/api/subscription-status`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      if (response.data.isAccessAllowed === false) {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const handleCaseChange = (event) => {
    const selectedCaseTitle = event.target.value;
    const selectedCase = cases.find(caseItem => caseItem.title === selectedCaseTitle);
    console.log("Selected Case Title:", selectedCaseTitle);
    console.log("Selected Case:", selectedCase);
    // Ensure that a case was actually found
    if (selectedCase) {
      console.log("Selected Case ID:", selectedCase.id);
      // Update the form values based on the selected case
      formik.setFieldValue('case', selectedCase.title); // Set the title for display purposes or other reasons
      formik.setFieldValue('caseId', selectedCase.id); // Set the caseId to be used in the backend
      formik.setFieldValue('type', selectedCase.court_type); // Assuming these are additional details you want to auto-fill
      formik.setFieldValue('zipStateProvince', selectedCase.state_name);
      formik.setFieldValue('city', selectedCase.district_name);
      formik.setFieldValue('dateOfHearing', selectedCase.date_next_list);
    } else {
      // Reset these fields if no case is selected or found
      formik.setFieldValue('case', '');
      formik.setFieldValue('caseId', '');
      formik.setFieldValue('type', '');
      formik.setFieldValue('zipStateProvince', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('dateOfHearing', '');
    }
  };
  


  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('caseId', values.caseId); 
  
      // Set lawyerType to either the selected value or the input from the "otherLawyerType" field
      const lawyerType = values.lawyerType === 'Others' ? values.otherLawyerType : values.lawyerType;
      
      // Append all text fields to formData
      Object.keys(values).forEach(key => {
        if (key !== 'caseFile') { // Exclude the file field
          // For lawyerType, use the adjusted value
          if (key === 'lawyerType') {
            formData.append(key, lawyerType);
          } else {
            formData.append(key, values[key]);
          }
        }
      });
  
      // Append the file if it exists
      if (values.caseFile) {
        formData.append('caseFile', values.caseFile);
      }
      
      // Make the request with the formData
      const response = await axios.post(`${ip}/proxy`, formData, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data', // Important for file upload
        },
      });
  
      alert('Proxy Added successfully!');
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };
  

  const formik = useFormik({
    initialValues: {
      lawyerType: '',
      experience: '',
      age: '',
      streetAddress: '',
      city: '',
      zipStateProvince: '',
      zipPostalCode: '',
      date: '',
      case: '',
      caseFile: null,
      causeTitle: '',
      honorableJudge: '',
      courtNumber: '',
      type: '',
      timeOfHearing: '',
      dateOfHearing: '',
      comments: '',
      otherLawyerType: '',
      caseId: '',
    },
    validationSchema: Yup.object({
      lawyerType: Yup.string().required('Lawyer Type is required'),
      experience: Yup.string()
      .required('Experience is required'),
    age: Yup.number()
      .integer('Case Fee must be an integer')
      .min(500, 'Case Fee must be more than Rs 500')
      .required('Case Fee is required'),


      // streetAddress: Yup.string().required('Street Address is required'),
      // city: Yup.string().required('City is required'),
      // zipStateProvince: Yup.string().required('State/Province is required'),
      // zipPostalCode: Yup.string().required('Zip/Postal Code is required'),
      // date: Yup.date().required('Date is required'),
      case: Yup.string().required('Case is required'),
      caseFile: Yup.mixed()
      .nullable(true) // Explicitly allow null values
      .notRequired(), // This field is not required
      
    
      // causeTitle: Yup.string().required('Cause Title is required'),
      // honorableJudge: Yup.string().required('Honorable Judge is required'),
      courtNumber: Yup.string().required('Enrollment Year is required'),
      // type: Yup.string().required('Type is required'),
      // timeOfHearing: Yup.string().required('Time of Hearing is required'),
      dateOfHearing: Yup.date()
        .required('Date of Hearing is required')
        .test(
          'is-greater-than-current-date',
          'Date of Hearing must be greater than the current date',
          value => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return value && new Date(value) > today;
          }
        ),
      comments: Yup.string(),
      otherLawyerType: Yup.string(),
    }),
    onSubmit: handleSubmit, 
  }); 
  
  const toggleOtherLawyerType = (value) => {
    setShowOtherLawyerType(value);
    formik.setFieldValue('otherLawyerType', ''); 
  };
  
  const closeSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
  };

  return (
    <>
      {isMobile ? ( <SideNav /> ) : ( <DashboardNavbar />)}
      
      <form className={style.formContainer} onSubmit={formik.handleSubmit}>
      <h2 className={style.heading}>PROXY COUNCIL</h2>
        <div className={style.row}>
          <div className={style.field}>
          <label className={style.label} htmlFor="lawyerType">Lawyer Type<span className={style.required}>*</span></label>
          <select
            className={style.select}
            id="lawyerType"
            name="lawyerType"
            onChange={(event) => {
              formik.handleChange(event);
              toggleOtherLawyerType(event.target.value === 'Others'); 
            }}
            onBlur={formik.handleBlur}
            value={formik.values.lawyerType}
          >
            <option value="" label="Select Lawyer Type" />
            <option value="Criminal lawyers">Criminal lawyers</option>
            <option value="Environmental lawyers">Environmental lawyers</option>
            <option value="Family lawyers">Family lawyers</option>
            <option value="Corporate lawyers">Corporate lawyers</option>
            <option value="Civil lawyers">Civil lawyers</option>
            <option value="Intellectual property lawyers">Intellectual property lawyers</option>
            <option value="Tax lawyer">Tax lawyer</option>
            <option value="Cyber lawyers">Cyber lawyers</option>
            <option value="Estate planning lawyers">Estate planning lawyers</option>
            <option value="Worker's compensation lawyer">Worker's compensation lawyer</option>
            <option value="Public interest lawyers">Public interest lawyers</option>
            <option value="Medical malpractice lawyers">Medical malpractice lawyers</option>
            <option value="Merger and acquisition lawyer">Merger and acquisition lawyer</option>
            <option value="Labour lawyers">Labour lawyers</option>
            <option value="Bankruptcy lawyers">Bankruptcy lawyers</option>
            <option value="Securities lawyer">Securities lawyer</option>
            <option value="Military lawyers">Military lawyers</option>
            <option value="Contract lawyers">Contract lawyers</option>
            <option value="Government lawyers">Government lawyers</option>
            <option value="Immigration lawyers">Immigration lawyers</option>
            <option value="Others">Others</option>
            
          </select>
          {formik.touched.lawyerType && formik.errors.lawyerType ? (
            <div className={style.error}>{formik.errors.lawyerType}</div>
          ) : null}
          </div>
           
           {showOtherLawyerType && (
            <div className={style.field}>
              <label className={style.label} htmlFor="otherLawyerType">Specify Lawyer Type<span className={style.required}>*</span></label>
              <input
                className={style.text}
                type="text"
                id="otherLawyerType"
                name="otherLawyerType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.otherLawyerType}
              />
              {formik.touched.otherLawyerType && formik.errors.otherLawyerType ? (
                <div className={style.error}>{formik.errors.otherLawyerType}</div>
              ) : null}
            </div>
          )}

          <div className={style.field}>
  <label className={style.label} htmlFor="experience">Experience<span className={style.required}>*</span></label>
  <select
    className={style.select}
    id="experience"
    name="experience"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.experience}
  >
    <option value="" disabled selected>Select Experience</option>
    <option value="0-1 years">0 to 1 year</option>
    <option value="1-3 years">1 to 3 years</option>
    <option value="3-5 years">3 to 5 years</option>
    <option value="5-10 years">5 to 10 years</option>
    <option value="10+ years">Greater than 10 years</option>
  </select>
  {formik.touched.experience && formik.errors.experience ? (
    <div className={style.error}>{formik.errors.experience}</div>
  ) : null}
</div>
        </div>
        <div className={style.row}>
        <div className={style.field}>
  <label className={style.label} htmlFor="case">Case<span className={style.required}>*</span></label>
  <select
    className={style.select}
    id="case"
    name="case"
    onChange={handleCaseChange}
    onBlur={formik.handleBlur}
    value={formik.values.case}
  >
    <option value="" label="Select Case Title" />
    {cases.map((caseItem) => (
    <option key={caseItem.id} value={caseItem.title}>
      {caseItem.title}
    </option>
  ))}
  </select>
  {formik.touched.case && formik.errors.case ? (
    <div className={style.error}>{formik.errors.case}</div>
  ) : null}
</div>
           <div className={style.field}>
            <label className={style.label} htmlFor="age">Case Fee<span className={style.required}>*</span></label>
            <input
              className={style.select}
              type="number"
              id="age"
              name="age"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.age}
            />
            {formik.touched.age && formik.errors.age ? (
              <div className={style.error}>{formik.errors.age}</div>
            ) : null}
          </div>
          
        </div>

        <div className={style.row}>
          <div className={style.field}>
            <label className={style.label} htmlFor="zipStateProvince">State/High Court</label>
            <input
              className={style.select}
              type="text"
              id="zipStateProvince"
              name="zipStateProvince"
              onChange={handleCaseChange}
              onBlur={formik.handleBlur}
              value={formik.values.zipStateProvince}
              readOnly={true}
            />
            {formik.touched.zipStateProvince && formik.errors.zipStateProvince ? (
              <div className={style.error}>{formik.errors.zipStateProvince}</div>
            ) : null}
          </div>

          <div className={style.field}>
            <label className={style.label} htmlFor="type">Court Type</label>
            <select
            className={style.select}
              id="type"
              name="type"
              onChange={handleCaseChange}
              onBlur={formik.handleBlur}
              value={formik.values.type}
              readOnly={true}
            >
              <option value="" label="Select Court Type" />
              <option value="High Court">High Court</option>
              <option value="Supreme Court">Supreme Court</option>
              <option value="District Court">District Court</option>            
              <option value="Other">Other</option>
            </select>
            {formik.touched.type && formik.errors.type ? (
              <div className={style.error}>{formik.errors.type}</div>
            ) : null}
          </div>

          <div className={style.field}>
            <label className={style.label} htmlFor="city">District/Bench</label>
            <input
              className={style.select}
              type="text"
              id="city"
              name="city"
              onChange={handleCaseChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              readOnly={true}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className={style.error}>{formik.errors.city}</div>
            ) : null}
          </div>
        </div>
        <div className={style.row}>
        <div className={style.field}>
            <label className={style.label} htmlFor="dateOfHearing">Date of Hearing<span className={style.required}>*</span></label>
            <input
              className={style.selectDateOfHearing}
              type="date"
              id="dateOfHearing"
              name="dateOfHearing"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfHearing}
            />
            {formik.touched.dateOfHearing && formik.errors.dateOfHearing ? (
              <div className={style.error}>{formik.errors.dateOfHearing}</div>
            ) : null}
          </div>

          <div className={style.field}>
            <label className={style.label} htmlFor="courtNumber">Enrollment Year<span className={style.required}>*</span></label>
            <input
              className={style.select}
              type="text"
              id="courtNumber"
              name="courtNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courtNumber}
            />
            {formik.touched.courtNumber && formik.errors.courtNumber ? (
              <div className={style.error}>{formik.errors.courtNumber}</div>
            ) : null}
          </div>
          
        </div>

        <div className={style.field}>
    <label className={style.label} htmlFor="timeOfHearing">Time of Hearing</label>
    <input
    className={style.selectTimeOfHearing}
      type="time"
      id="timeOfHearing"
      name="timeOfHearing"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.timeOfHearing}
    />
    {formik.touched.timeOfHearing && formik.errors.timeOfHearing ? (
      <div className={style.error}>{formik.errors.timeOfHearing}</div>
    ) : null}
  </div>

        <div className={style.row}>
          <div className={style.field}>
            <label className={style.label} htmlFor="honorableJudge">Honorable Judge</label>
            <input
              className={style.select}
              type="text"
              id="honorableJudge"
              name="honorableJudge"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.honorableJudge}
            />
            {formik.touched.honorableJudge && formik.errors.honorableJudge ? (
              <div className={style.error}>{formik.errors.honorableJudge}</div>
            ) : null}
          </div>
          
         

          <div className={style.field}>
            <label className={style.label} htmlFor="caseFile">Case File (PDF only)</label>
            <input
              className={style.select}
              type="file"
              id="caseFile"
              name="caseFile"
              accept=".pdf"
              onChange={(event) => {
                formik.setFieldValue('caseFile', event.currentTarget.files[0]);
              }}
            />
            {formik.touched.caseFile && formik.errors.caseFile ? (
              <div className={style.error}>{formik.errors.caseFile}</div>
            ) : null}
          </div>
        </div>
        <div className={style.row}>
          
          
        </div>
        <div className={style.row}>
          
        
        
          
        </div>
        <div className={style.row}>
          <div className={style.field}>
            <label className={style.label} htmlFor="comments">Comments</label>
            <textarea
              className={style.select}
              id="comments"
              name="comments"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comments}
              style={{ width: '100%', height:'50%' }}
            />
            {formik.touched.comments && formik.errors.comments ? (
              <div className={style.error}>{formik.errors.comments}</div>
            ) : null}
          </div>
        </div>
          <div className={style.BtnContainer}>
          {/* <Tooltip title="Submit Form"> */}
            <button className={style.submitButton} type="submit">Submit</button>
              {/* </Tooltip> */}
            <NavLink to={"/dashboard"}>
            {/* <Tooltip title="Cancel Form"> */}
            <button className={style.buttonCancel} type="submit">Cancel</button>
            {/* </Tooltip> */}
            </NavLink>
          </div>
        
          
      </form>
    
</>
  );
};
export default Proxy;